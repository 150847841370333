import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as AiIcons from "react-icons/ai";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { springBaseUrl } from "../../CalendarPage/constants/AmplitudeConstants";
import FolioActionItem from "./FolioActionItem";

export default function PrintInvoiceLoader({
  api,
  bookingId,
  reservationId,
  billToPref,
  currencyFrom,
  currencyTo,
  defaultRate,
  currencySymbol,
  showRoomId,
  newFolio,
  otaMaskedRate,
}) {
  console.log(
    "otaMaskedRates",
    otaMaskedRate,
    "showRoomId",
    showRoomId,
    "newFolio",
    newFolio
  );
  const [printLoading, setPrintLoading] = useState(false);
  const [printSuccess, setPrintSuccess] = useState(false);
  const pdfTemplate = {
    "get-adv-invoice": "Advanced_Folio",
    "get-invoice": "Folio",
    "get-detailed-invoice": "Detailed_Folio",
    "get-room-invoice": "Room_Folio",
  };
  const handlePrintInvoice = (bookingId, api, showRoomId, otaMaskedRate) => {
    setPrintLoading(true);
    setPrintSuccess(false);
    axios
      .get(
        `${springBaseUrl}core/api/v1/folio/${api}?bookingId=${bookingId}&billToPref=${billToPref}&reservationId=${reservationId}&currencyFrom=${currencyFrom}&currencyTo=${currencyTo}&defaultRate=${defaultRate}&currencySymbol=${currencySymbol}&showRoomId=${
          showRoomId ? true : false
        }&otaPriceMasking=${otaMaskedRate === "YES" ? "YES" : "NO"}`,
        {
          headers: {
            Accept: "application/pdf",
            Authorization: localStorage.getItem("token"),
          },
          responseType: "blob",
        }
      )
      .then(async (response) => {
        setPrintLoading(false);
        setPrintSuccess(true);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // const fileName = `${bookingId}_${pdfTemplate?.[api] || api}.pdf`;
        // link.href = url;
        // link.download = fileName;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.open(url, "_blank");
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      })
      .catch(() => {
        setPrintLoading(false);
      });
  };

  // Mark folio as prepaid | New Folio
  if (newFolio && otaMaskedRate === "YES") {
    return (
      <FolioActionItem
        text={"Mark folio as prepaid"}
        loading={printLoading}
        action={() => handlePrintInvoice(bookingId, api, false, "YES")}
      />
    );
  }
  if (showRoomId === false && !newFolio) {
    return printLoading ? (
      <div className="d-flex">
        <div className="item">Download Booking Voucher</div>
        <CircularProgress color="inherit" />
      </div>
    ) : (
      <div
        className="item"
        onClick={() => handlePrintInvoice(bookingId, api, false, "NO")}
      >
        Download Booking Voucher
      </div>
    );
  }
  if (otaMaskedRate === "YES" && !newFolio) {
    return printLoading ? (
      <div className="d-flex">
        <div className="item"> Mark folio as prepaid</div>
        <CircularProgress color="inherit" />
      </div>
    ) : (
      <div
        className="item"
        onClick={() => handlePrintInvoice(bookingId, api, false, "YES")}
      >
        Mark folio as prepaid
      </div>
    );
  }
  return (
    <Box sx={{ position: "relative" }}>
      <div className="d-flex">
        <Tooltip title={"Print & download invoice"}>
          <IconButton
            onClick={() => handlePrintInvoice(bookingId, api, true)}
            disabled={printLoading}
          >
            {printSuccess ? (
              <AiIcons.AiOutlineCheckCircle size="27.5px" color="black" />
            ) : (
              <AiIcons.AiFillPrinter size="27.5px" color="black" />
            )}
          </IconButton>
        </Tooltip>
      </div>
      {printLoading && (
        <CircularProgress
          size={42}
          sx={{
            color: "gray",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
}
