import axios from "axios";
import {
  BASE_ENDPOINT,
  BASE_SPRING_ENDPOINT,
  CM_BOOKING_SPRING_ENDPOINT,
} from "./config";

// console.log(BASE_ENDPOINT)
// console.log("local djagno end point", BASE_ENDPOINT);
// console.log("spring end point : ", BASE_SPRING_ENDPOINT);

// local django api calls --- without authorization
export const djangoHandleDataRequests = async (
  resourceUrl,
  isProtected = false
) => {
  const token = localStorage.getItem("token");
  if (isProtected && !token) {
    Promise.reject("Token not present");
  }
  try {
    const response = await axios({
      method: "GET",
      url: `${BASE_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return Promise.resolve(response.data);
  } catch (err) {
    if (
      err?.response?.data?.error !== undefined &&
      err?.response?.data?.error !== null &&
      err?.response?.data?.error !== ""
    ) {
      return Promise.reject(err?.response?.data?.error);
    }
    return Promise.reject("Something has gone wrong!");
  }
};

export const djangoHandleDataMutationRequest = async (
  method,
  resourceUrl,
  reqData,
  isProtected = true
) => {
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    return Promise.reject(new Error("Token is not present"));
  }

  try {
    const response = await axios({
      method: `${method}`,
      url: `${BASE_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...(reqData && { data: reqData }),
    });

    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err.response.data.message);
  }
};

// django api
export const handleDataRequest = async (resourceUrl, isProtected = true) => {
  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    Promise.reject(new Error("Token not present"));
  }

  try {
    const response = await axios({
      method: "GET",
      url: `${BASE_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return Promise.resolve(response.data);
  } catch (err) {
    // return Promise.resolve(err)
    return console.log("error : ", err);
  }
};

// data mutation  (django api)

export const handleDataMutationRequest = async (
  method,
  resourceUrl,
  reqData,
  isProtected = true
) => {
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    return Promise.reject(new Error("Token is not present"));
  }

  try {
    const response = await axios({
      method: `${method}`,
      url: `/${BASE_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...(reqData && { data: reqData }),
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// get data (spring api)
export const handleSpringDataRequest = async (
  resourceUrl,
  isProtected = true
) => {
  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    Promise.reject(new Error("Token not present"));
  }

  try {
    const response = await axios({
      method: "GET",
      url: `${BASE_SPRING_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// data mutation (sping boot api)
export const handleSpringDataMutationRequest = async (
  method,
  resourceUrl,
  reqData,
  isProtected = true
) => {
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    return Promise.reject(new Error("Token is not present"));
  }

  try {
    const response = await axios({
      method: `${method}`,
      url: `${BASE_SPRING_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...(reqData && { data: reqData }),
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// data mutation (sping boot api for CM Booking Only)
export const handleSpringDataMutationRequestCmBooking = async (
  method,
  resourceUrl,
  reqData,
  isProtected = true
) => {
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    return Promise.reject(new Error("Token is not present"));
  }

  try {
    const response = await axios({
      method: `${method}`,
      url: `${CM_BOOKING_SPRING_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...(reqData && { data: reqData }),
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const sfApiClient = axios.create({
  baseURL: BASE_SPRING_ENDPOINT,
});

sfApiClient.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      if (token.includes("||master||master")) {
        config.headers.Authorization = token.substring(0, token.length - 8); // Remove last 8 characters if '||master' is present
      } else {
        config.headers.Authorization = token; // Send the token as is if '||master' is not found
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

sfApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error.response || error.message);
    return Promise.reject(error);
  }
);

// Generic API helper functions
export const get = async (url, config = {}) => {
  return sfApiClient.get(url, config);
};

export const post = async (url, data = {}, config = {}) => {
  return sfApiClient.post(url, data, config);
};

export const put = async (url, data = {}, config = {}) => {
  return sfApiClient.put(url, data, config);
};

export const del = async (url, config = {}) => {
  return sfApiClient.delete(url, config);
};
