import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
  Button,
  Stack,
  Checkbox,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { dateFormat, displaySlots } from "../constants/Calendar-constants";
import { blockMultipleRooms } from "../../InventoryCalendar/api/InventoryApi";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import PrintResCard from "../../Folios/PrintResCard";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import PrintEmptyVehicleResCard from "../../Folios/PrintEmptyVehicleResCard";
import PrintEmptyResCard from "../../Folios/PrintEmptyResCard";
import { getCurrencySymbol } from "../../../Utils/Utils";
import PrintVehicleResCard from "../../Folios/PrintVehicleResCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const PrintRegCard = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const {
    open,
    closeFunc,
    modalClose,
    bookingData,
    invoiceData,
    selectedLanguage,
    hotelType,
    defaultCtadropdownm,
    allCompanyDetails,
    summaryData,
    hotelLogo,
    currency,
    customFolioConfig,
    handleCloseResCard,
    manualResCard,
    handleChangeManualResCard,
    defaultCtadropdown,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className={classes.root}>
      <CommonSlider
        onBackdropClick={closeFunc}
        onClose={modalClose}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">{t("Print Registration card")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <div className="folioConfirmationWrapper">
              <div
                className="folioContent"
                style={{
                  borderBottom: "1px solid #DDDDDD",
                  paddingBottom: "15px",
                  marginBottom: "15px",
                }}
              >
                <Stack
                  direction="row"
                  spacing={3}
                  style={{ textAlign: "center" }}
                >
                  <Checkbox
                    checked={manualResCard}
                    onChange={handleChangeManualResCard}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <div style={{ marginTop: "7px" }}>
                    {t(
                      "I will fill up the details manually into the Registration card!"
                    )}
                  </div>
                </Stack>
              </div>
              <Stack direction="row">
                <div className="updateBtn">
                  {manualResCard === true ? (
                    <div className="item" style={{ paddingTop: "10px" }}>
                      <PDFDownloadLink
                        document={
                          <Document title={bookingData.bookingId}>
                            <Page
                              size="A4"
                              style={[
                                styles.page,
                                {
                                  paddingTop:
                                    customFolioConfig?.reservation_card_version ===
                                      "rescardV2" && 10,
                                  paddingBottom:
                                    customFolioConfig?.reservation_card_version ===
                                      "rescardV2" && 10,
                                },
                              ]}
                              wrap
                            >
                              {customFolioConfig?.reservation_card_version ===
                              "rescardV2" ? (
                                <PrintEmptyVehicleResCard
                                  invoice={invoiceData?.invoice}
                                  symbol={getCurrencySymbol(currency)}
                                  hotelData={invoiceData?.hotel}
                                  customDetails={customFolioConfig}
                                  hotelLogo={hotelLogo}
                                  summaryData={summaryData}
                                  hotelType={hotelType}
                                />
                              ) : (
                                <PrintEmptyResCard
                                  invoice={invoiceData?.invoice}
                                  symbol={getCurrencySymbol(currency)}
                                  hotelData={invoiceData?.hotel}
                                  customDetails={customFolioConfig}
                                  hotelLogo={hotelLogo}
                                  summaryData={summaryData}
                                  hotelType={hotelType}
                                />
                              )}
                            </Page>
                          </Document>
                        }
                        fileName={`${bookingData.bookingId}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <p style={{ fontSize: "0.8rem" }}>
                              {t("Loading document...")}
                            </p>
                          ) : (
                            <a
                              href={url}
                              style={{ fontSize: "0.8rem" }}
                              rel={"noreferrer"}
                              target="_blank"
                            >
                              {window.screen.width > 416 && (
                                <Button
                                  variant="custom-button"
                                  onClick={() =>
                                    setTimeout(() => {
                                      handleCloseResCard();
                                    }, 1500)
                                  }
                                  style={{ float: "right" }}
                                >
                                  {t("Print Registration card")}
                                </Button>
                              )}
                            </a>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                  ) : (
                    <div className="item" style={{ paddingTop: "10px" }}>
                      <PDFDownloadLink
                        document={
                          <Document title={bookingData.bookingId}>
                            <Page size="A4" style={styles.page} wrap>
                              {customFolioConfig?.reservation_card_version ===
                              "rescardV2" ? (
                                <PrintVehicleResCard
                                  allCompanyDetails={allCompanyDetails}
                                  invoice={invoiceData?.invoice}
                                  symbol={currency}
                                  hotelData={invoiceData?.hotel}
                                  customDetails={customFolioConfig}
                                  hotelLogo={hotelLogo}
                                  summaryData={summaryData}
                                  hotelType={hotelType}
                                  ctaDetails={
                                    defaultCtadropdown?.filter(
                                      (cta) =>
                                        cta?.cta_name ===
                                        invoiceData?.invoice?.booking_source
                                    )[0]
                                  }
                                  selectedLanguage={props.selectedLanguage}
                                />
                              ) : customFolioConfig?.reservation_card_version ===
                                "rescardV1" ? (
                                <PrintResCard
                                  allCompanyDetails={allCompanyDetails}
                                  invoice={invoiceData?.invoice}
                                  symbol={currency}
                                  hotelData={invoiceData?.hotel}
                                  customDetails={customFolioConfig}
                                  hotelLogo={hotelLogo}
                                  summaryData={summaryData}
                                  hotelType={hotelType}
                                  ctaDetails={
                                    defaultCtadropdown?.filter(
                                      (cta) =>
                                        cta?.cta_name ===
                                        invoiceData?.invoice?.booking_source
                                    )[0]
                                  }
                                  selectedLanguage={props.selectedLanguage}
                                />
                              ) : null}
                            </Page>
                          </Document>
                        }
                        fileName={`${bookingData.bookingId}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <p style={{ fontSize: "0.8rem" }}>
                              {t("Loading document...")}
                            </p>
                          ) : (
                            <a
                              href={url}
                              style={{ fontSize: "0.8rem" }}
                              rel={"noreferrer"}
                              target="_blank"
                            >
                              {window.screen.width > 416 && (
                                <Button
                                  variant="custom-button"
                                  onClick={() =>
                                    setTimeout(() => {
                                      handleCloseResCard();
                                    }, 1500)
                                  }
                                  style={{ float: "right" }}
                                >
                                  {t("Print Registration card")}
                                </Button>
                              )}
                            </a>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                  )}
                </div>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default PrintRegCard;

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
