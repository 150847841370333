// ** React Imports
import {
  Add,
  CheckCircle,
  Close,
  Error,
  ErrorOutline,
  ExpandMore,
  HorizontalRule,
  Remove,
} from "@mui/icons-material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import * as AiIcons from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { cloneDeep } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { useFormik } from "formik";
import { isArray, isNull, isUndefined } from "lodash";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import CardSwipe from "react-card-swipe";
import {
  default as ImageUploading,
  default as ReactImageUploading,
} from "react-images-uploading";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";
import { cardNumberCVVFormat, getCardType } from "../../../Utils/cardType";
import {
  getUserId,
  setBookingUserIdImages,
  setIshaGuestDocument,
  setIshaOtherGuestDocuments,
  setOtherGuestImages,
} from "../../BookingDashboard/apis/BrandingModalApi";
import {
  getAllCustomers,
  getAllUnassignedReservations,
} from "../../FlexibleSlot/api/FlexibleSlotApi";
import {
  checkRoomAvailability,
  getCtaNamesNew,
} from "../../InventoryCalendar/api/InventoryApi";
import {
  getcustomPGinfo,
  saveCardInfo,
} from "../../RevenueManagement/Promotions/apis/PromotionApis";
import {
  getCheckinCheckoutSlots,
  getQuickBookPricePayload,
  getTaxNamesForRoom,
  perfomAdminBooking,
  getGeoCodeDetails,
  addSegmentAndSubSegment,
  handleGetDoorLockDetails,
  getCheckinCheckoutSlotsHourly,
  getQuickBookPricePayloadNoon,
} from "../api/ReservationApi";
import { ENV } from "../constants/AmplitudeConstants";
import {
  checkIshaAccess,
  checkIshaUSAccess,
  customErrorMessage,
  dateFnsFormat,
  dateFormat,
  displaySlots,
  displayTimes,
  getApprovedByOverseas,
  getCountriesAndStates,
  getGenderList,
  getIshaGenderList,
  getIshaProgramsAttended,
  getIshaPurposeOfVisit,
  getIshaRelationWithPrimary,
  getNationalityList,
  maxCompressMBSize,
  segmentMap,
  subSegmentMap,
  validateEmail,
  validatePhoneNumber,
} from "../constants/Calendar-constants";
import { NewReservationModel } from "../models/NewReservationModel";
import { PriceModel } from "../models/PricesModel";
import BlockView from "./BlockView";
import CommonSlider from "./CommonSlider";
import DimmingDotsLoader from "./LoaderCustom";
import amplitude from "amplitude-js";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import ReactDOM from "react-dom";
import Webcam from "react-webcam";
import {
  getAllPromocodes,
  getAllPromocodesPriceList,
  checkPromoCode,
  checkPromoCodeQuickBook,
} from "../../Promocodes/apis/PromocodeApi";
import { getHotelAddons } from "../../HotelAddons/HotelAddonsAPI";
import NewGuestView from "./NewGuestView";
import NewChildView from "./NewChildView";
import imageCompression from "browser-image-compression";
import DayLevelPricing from "./DayLevelPricing";
import "./Searchable.css";
import SearchableDropdown from "./SearchableDropdown";
import SearchModal from "./SearchModal";
import ModalCommonSlider from "./ModalCommonSlider";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import IDguru from "../../IDguru/IDguru";
import axios from "axios";
import * as MdIcons from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  summaryInner: {
    margin: theme.spacing(1),
  },
  column: {
    flexBasis: "33.33%",
    whiteSpace: "nowrap",
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
}));

const AddEventSidebar = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  const validationSchema = yup.object({
    customer_name: yup
      .string(`${t("Enter your Name")}`)
      .required(`${t("Customer Name is required")}`),
    // customer_email: yup
    //   .string("Enter your email")
    //   .email("Enter a valid email")
    //   .required("Email is required"),
    // customer_phone: yup
    //   .string()
    //   .matches(
    //     phoneRegExp,
    //     `${t("Phone number is not valid and only numbers are allowed")}`
    //   ),
    booking_start: yup.date(),
    booking_end: yup.date(),
    guests: yup.object().shape({
      Adults: yup.number(),
      Children: yup.number(),
    }),
    perNightPrice: yup
      .number()
      .required(`${t("Please enter a valid amount")}`)
      .test(
        "Is positive?",
        `${t("The value cannot be negative")}`,
        (value) => value > -1
      ),
    priceBeforeTax: yup
      .number()
      .required(`${t("Please enter a valid amount")}`),
    priceAfterTax: yup.number().required(`${t("Please enter a valid amount")}`),
    taxValue: yup.number().required(`${t("Please enter a valid amount")}`),
    numNights: yup.number().nullable(),
    govt_verification: yup.object().shape({
      govt_id: yup.string(),
      govt_id_num: yup.string(),
      govt_id_photo: yup.mixed().nullable(),
    }),
    rate_plan: yup.string(),
    state: yup.string().nullable(),
    city: yup.string().nullable(),
    country: yup.string().nullable(),
    address1: yup.string(),
    address2: yup.string(),
    zipcode: yup.string(),
    taxId: yup.string(),
    selectedTaxes: yup.array().nullable(),
  });

  const ishaProgramsAttended = getIshaProgramsAttended();
  const ishaApprovedByOverseas = getApprovedByOverseas();
  const ishaRelationWithPrimary = getIshaRelationWithPrimary();
  const genderList = getGenderList();

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const [hotelemail, sethotelemail] = useState(
    hotel?.accessControl?.hotel?.hotel_email
  );
  //Amplitude check on Create Booking page
  const amplitudeEventProperties = {
    hotel_id: hotel?.hotelId,
    environment: ENV,
    email: hotel?.accessControl?.email,
    role: hotel?.accessControl?.ADMINRIGHTS,
  };

  const hotelCountry = hotel?.accessControl?.hotel.hotel_country;

  const paymentChargeAccess =
    JSON.parse(hotel?.accessControl?.user_feature_map?.PaymentChargeAccess)
      .read === "YES";
  const hotelId = hotel.hotelId;

  //ID  Guru
  const [idGuruModal, setidGuruModal] = useState(false);
  const [selectedUserIdGuruData, setselectedUserIdGuruData] = useState({});
  const handleOpenIdGuruModal = () => {
    setidGuruModal(true);
  };
  const handleCloseIdGuruModal = () => {
    console.log("selectedUserIdGuruData", selectedUserIdGuruData);
    setidGuruModal(false);
  };

  useEffect(() => {
    if (
      Object.entries(selectedUserIdGuruData).length > 0 &&
      idGuruModal === false
    ) {
      scannedIdGuruData();
    }
  }, [selectedUserIdGuruData, idGuruModal]);

  //hotel Currency
  const currency = hotel?.accessControl?.hotel?.hotel_currency;
  const ishaPurposeOfVisit = getIshaPurposeOfVisit(hotelId);
  const ishaGenderList = getIshaGenderList(hotelId);
  const countries = getCountriesAndStates();
  const nationalities = getNationalityList();
  let [pricingRequestModel, setPricingRequestModel] = useState(
    new PriceModel({})
  );
  let [loadingData, setLoadingData] = useState(true);
  let [load, setLoad] = useState(false);
  const [apiLoader, setapiLoader] = useState(false);
  const [checkinSlots, setCheckinSlots] = useState([]);
  const [checkoutSlots, setCheckoutSlots] = useState([]);
  const [selectedCheckinSlot, setSelectedCheckinSlot] = useState(0);
  const [selectedCheckoutSlot, setSelectedCheckoutSlot] = useState(0);
  const [showError, setShowError] = useState(false);
  const [taxNames, setTaxNames] = useState(null);
  const [showExpiresin, setshowExpiresin] = useState(false);
  const [selfCheckin, setselfCheckin] = useState(false);
  const [disablePrices, setdisablePrices] = useState(true);
  const [discountApplied, setdiscountApplied] = useState("");
  const [selectedPromoCodeData, setselectedPromoCodeData] = useState({});
  const [searchableValue, setsearchableValue] = useState("");
  const [showBlockRoomError, setshowBlockRoomError] = useState(false);
  const [showTaxExempt, setshowTaxExempt] = useState(false);
  const [showCoupons, setshowCoupons] = useState(false);
  const [paymentMode, setpaymentMode] = useState(
    hotelCountry === enableAddCard ? "SWIPE CARD" : "CASH"
  );

  const [enableAddCard, setenableAddCard] = useState(false);

  useEffect(() => {
    setdisablePrices(hotelCountry === "United States" ? false : true);
  }, [hotelCountry]);

  const [expirationTime, setexpirationTime] = useState("HOURS"); //----------
  const [ExpirationSource, setExpirationSource] = useState("AFTER");
  const [EnquiryExpiryTime, setEnquiryExpiryTime] = useState(""); //-----------
  const [openBlock, setOpenBlock] = useState(false);
  const [ctas, setCtas] = useState([]);

  const [paymentAmount, setpaymentAmount] = useState(0);
  const [remark, setremark] = useState("");
  const [showPromoButton, setshowPromoButton] = useState(true);
  const [showDiscountButton, setshowDiscountButton] = useState(true);

  //Segment states
  const [userDefinedSources, setUserDefinedSources] = useState(null);
  const [mapsss, setMapsss] = useState(null);
  const [mostMajorSource, setMostMajorSource] = useState(null);
  const [mostSecondarySource, setMostSecondarySource] = useState(null);

  const [majorSources, setMajorSources] = useState(null);
  const [segment, setSegment] = useState(null);
  const [subSegment, setSubSegment] = useState("");
  const [subSegmentArr, setSubSegmentArr] = useState(null);

  const [checkinEpoch, setcheckinEpoch] = useState();
  const [checkoutEpoch, setcheckoutEpoch] = useState();
  const [currentEpoch, setcurrentEpoch] = useState(0);
  const [dicountType, setdicountType] = useState("percent");
  const [discountAmount, setdiscountAmount] = useState("");
  const [discountedPrice, setdiscountedPrice] = useState(0);
  const [allCompanyDetails, setallCompanyDetails] = useState([]);
  const [showCompanyModal, setshowCompanyModal] = useState(false);
  const [showOptionalFields, setshowOptionalFields] = useState(false);
  const [checkedChargeCard, setcheckedChargeCard] = useState(false);

  useEffect(() => {
    handleSpringDataRequest("web-api/get-today-hotel-epoch")
      .then((res) => setcurrentEpoch(res))
      .catch((err) => console.log(err));
  }, []);

  const [priceAfterTaxes, setpriceAfterTaxes] = useState(
    formik?.values?.priceAfterTax
  );
  const [selectedCta, setSelectedCta] = useState({
    cta_name: "walk-in",
    cta_email: "STAYFLEXI_HD",
    cta_id: null,
  });

  const [dirtyStatus, setdirtyStatus] = useState("CLEAN");
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/hk/get-housekeeping-room-dirty-status?roomId=${[
        eventData.resource.id,
      ]}`
    )
      .then((res) => {
        setdirtyStatus(res);
      })
      .catch((err) => console.error(err));
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const toggleShowExpiresin = (e) => {
    if (e.target.checked === true) {
      setshowExpiresin(true);
    } else {
      setshowExpiresin(false);
    }
  };

  const toggleShowPrices = (e) => {
    if (e.target.checked === true) {
      setdisablePrices(false);
    } else {
      setdisablePrices(true);
    }
  };
  const [showCollectpayment, setshowCollectpayment] = useState(false);
  const [discountvalue, setdiscountvalue] = useState(0);

  const [cameraUpload, setcameraUpload] = useState(false);

  const toggleCollectpayment = (e) => {
    if (e.target.checked === true) {
      setshowCollectpayment(true);
    } else {
      setshowCollectpayment(false);
    }
  };

  const toggleSelfCheckin = (e) => {
    if (e.target.checked === true) {
      setselfCheckin(true);
    } else {
      setselfCheckin(false);
    }
  };

  const classes = useStyles();
  const {
    viewType,
    eventData,
    handleAddEventSidebar,
    open,
    reload,
    reloadagain,
    addEvent,
    roomInformation,
    openTheBookingModal,
    isGroup,
  } = props;

  useEffect(() => {
    fetchRoomTypeData();
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };
  const gettingDifferenceOfCheckinAndInquiry = (checkInDate) => {
    const checkInTime = checkInDate?.getTime();
    const currentTime = new Date().getTime();
    let inquiryTime = 0;
    if (expirationTime === "DAYS") {
      inquiryTime = EnquiryExpiryTime * 24 * 60 * 60 * 1000;
    } else if (expirationTime === "HOURS") {
      inquiryTime = EnquiryExpiryTime * 60 * 60 * 1000;
    } else {
      inquiryTime = EnquiryExpiryTime * 60 * 1000;
    }
    const diff = (checkInTime - inquiryTime - currentTime) / 60000;
    return diff;
  };
  function extractValidChars(str) {
    // Regular expression to allow only numbers, letters, hyphen (-), period (.), and @
    return str.replace(/[^a-zA-Z0-9@.-]/g, "");
  }

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      booking_start: eventData.start,
      date_of_birth: "",
      booking_end:
        hotel.slotType === "NOON"
          ? moment(eventData.end).format("hh:mm:ss") === "12:00:00"
            ? moment(eventData.end).subtract("days", 1).format("DD-MM-YYYY")
            : moment(eventData.end, "DD-MM-YYYY").add("days", 1)
          : eventData.end,
      guests: { Adults: 0, Children: 0 },
      rate_plan: "",
      perNightPrice: 0,
      priceBeforeTax: 0,
      priceAfterTax: 0,
      taxValue: 0,
      govt_verification: { govt_id: "", govt_id_num: "", govt_id_photo: null },
      state: null,
      country: hotelCountry,
      city: "",
      address1: "",
      address2: "",
      zipcode: "",
      taxId: "",
      selectedTaxes: [],
      govtIdValue: "",
      govtIdType: "",
      special_request: "",
      selfCheckin: false,
      appliedPromocode: "",
      promoAmount: 0,
      gender: "-",
      nationality: "",
      occupation: "",
      companyName: "",
      purposeOfVisit: "-",
      programsAttended: "-",
      emergencyContact: "",
      emergencyContactName: "",
      ocoCheckinComplete: "No",
      approvedByOverseas: "New",
      otherGuestDetails: [],
      requestorName: "",
      requestorContact: "",
      requestorDept: "",
      requestorApprover: "",
      requestorRemarks: "", //will be added in case needed for future
      eventType: "", //Isha US addition
    },
    validationSchema: validationSchema,
    validate: () => {
      let errors = {};
      if (formik.errors.booking_start)
        errors.booking_start = formik.errors.booking_start;
      if (formik.errors.booking_end)
        errors.booking_end = formik.errors.booking_end;

      return errors;
    },

    onSubmit: async (values) => {
      if (showCollectpayment && paymentMode === "SWIPE CARD") {
        if (addCardFirstName.trim().length === 0) {
          return toast.error(`${t("Please enter card first name")}`);
        } else if (addCardLastName.trim().length === 0) {
          return toast.error(`${t("Please enter card last name")}`);
        } else if (addCardNumber.trim().length === 0) {
          return toast.error(`${t("Please enter card number")}`);
        } else if (addCardCVV.trim().length === 0) {
          return toast.error(`${t("Please enter card cvv")}`);
        } else if (addCardExpiry.trim().length === 0) {
          return toast.error(`${t("Please enter card expiry")}`);
        }
      }

      if (checkIshaUSAccess(hotel?.emailId) && values.gender == "-") {
        toast.error(
          `${t("Please select primary customer gender for the booking")}`
        );
        return;
      }
      if (checkIshaUSAccess(hotel?.emailId) && values.purposeOfVisit == "-") {
        toast.error(`${t("Please enter a purpose of visit for the booking")}`);
        return;
      }
      if (values.customer_name.trim().length <= 0) {
        return toast.error(`${t("Please enter characters in customer name")}`);
      }
      if (
        subUser.toLowerCase() === "subuser" &&
        JSON.parse(hotel?.accessControl?.user_feature_map?.MandatoryEmail)
          .read === "YES" &&
        values.customer_email === ""
      ) {
        return toast.error(`${t("Please enter email")}`);
      }
      if (
        subUser.toLowerCase() === "subuser" &&
        JSON.parse(
          hotel?.accessControl?.user_feature_map?.MandatoryContactNumber
        ).read === "YES" &&
        values.customer_phone === ""
      ) {
        return toast.error(`${t("Please enter contact number")}`);
      }
      if (
        subUser.toLowerCase() === "subuser" &&
        JSON.parse(hotel?.accessControl?.user_feature_map?.MandatoryAddress)
          .read === "YES" &&
        values.address1 === ""
      ) {
        return toast.error(`${t("Please enter address")}`);
      }
      if (
        subUser.toLowerCase() === "subuser" &&
        JSON.parse(hotel?.accessControl?.user_feature_map?.MandatoryZipcode)
          .read === "YES" &&
        values.zipcode === ""
      ) {
        return toast.error(`${t("Please enter zipcode")}`);
      }
      let finalGuestData = [...otherGuestDetails];
      childDataDetails.map((childDetail, index) => {
        finalGuestData.push(childDetail);
      });
      let email =
        values.customer_email === ""
          ? values.customer_name.replace(/\s+/g, ".").toLowerCase() +
            hotelId +
            Math.round(Date.now() / 1000) +
            "@example.com"
          : values.customer_email;
      email = extractValidChars(email.trim());
      let compRequest = "false";
      if (
        values.requestorName !== "" &&
        values.requestorContact !== "" &&
        values.requestorDept !== "" &&
        values.requestorApprover !== ""
      ) {
        compRequest = "true";
      }
      let tempFrontDataImage =
        images[0] !== undefined ? images[0]?.file : undefined;
      let tempBackDataImage =
        faviconImages[0] !== undefined ? faviconImages[0]?.file : undefined;
      let tempPassportImage =
        passportImages[0] !== undefined ? passportImages[0]?.file : undefined;
      const options = {
        maxSizeMB: maxCompressMBSize,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      let compressedFrontData = null;
      let compressedBackData = null;
      let compressedPassportData = null;

      if (tempBackDataImage !== undefined) {
        // const compressedFile = await imageCompression(
        //   tempBackDataImage,
        //   options
        // );
        // await getBase64(compressedFile)
        //   .then((result) => {
        //     compressedFile["base64"] = result;
        //     compressedBackData = result;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      }
      if (tempPassportImage !== undefined) {
        // const compressedFile = await imageCompression(
        //   tempPassportImage,
        //   options
        // );
        // await getBase64(compressedFile)
        //   .then((result) => {
        //     compressedFile["base64"] = result;
        //     compressedPassportData = result;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      }
      // if (code !== "" && code !== undefined && code !== null) {
      //   addonsProductArray.push({
      //     id: addonsProductArray?.length + 1,
      //     name: code.toUpperCase(),
      //     quantity: 1,
      //     priceWithoutTax:
      //       code === "discount"
      //         ? Number(discountedPrice) * -1
      //         : Number(discountvalue) * -1,
      //     taxPercentage: 0,
      //     priceWithTax:
      //       code === "discount"
      //         ? Number(discountedPrice) * -1
      //         : Number(discountvalue) * -1,
      //     description: code.toUpperCase(),
      //   });
      // }
      let subSource;
      // = subSegment === "WALK_IN" ? "STAYFLEXI_HD" : subSegment;
      if (subSegment === "WALK-IN") {
        subSource = "STAYFLEXI_HD";
      } else {
        subSource = subSegment;
      }
      let temporary = {
        resourceId: eventData.resource.id,
        start: getCustomDate().start,
        end: getCustomDate().end,
        title: values.customer_name?.trim(),
        phoneNumber:
          values.customer_phone === ""
            ? "0000000000"
            : values.customer_phone?.trim(),
        email: email?.trim(),
        status: "CONFIRMED",
        locked_status: "UNLOCKED",
        guests: {
          Adults: values.guests.Adults,
          Children: values.guests.Children,
        },
        roomTypeId: selectedRoomTypeId,
        roomNumber: eventData.resource.id,
        bookingAmount: values.priceAfterTax,
        ratePlanId: values.rate_plan,
        actual_start: getCustomDate().start,
        actual_end: getCustomDate().end,
        zipcode: values.zipcode,
        address1: values.address1,
        address2: values.address2,
        state: values.state,
        country: values.country,
        city: values.city,
        taxId: values.taxId.trim(),
        taxNames:
          !values.taxId.trim() && values.taxId !== 0
            ? []
            : values.selectedTaxes,
        durationType: ExpirationSource,
        isEnquiry: showExpiresin,
        expiryType: expirationTime,
        expiryTime: EnquiryExpiryTime,
        govtIdType: values.govtIdType,
        govtIdValue: values.govtIdValue,
        frontData: images[0] ? images[0].data_url?.split(",")[1] : null,
        frontType: images[0] ? images[0].file.type : null,
        backData: faviconImages[0]
          ? faviconImages[0].data_url?.split(",")[1]
          : null,
        backType: faviconImages[0] ? faviconImages[0].file.type : null,
        bookingSource: subSource,
        specialRequests: values.special_request,
        addons: addonsProductArray,
        selfCheckin: selfCheckin,
        appliedPromocode:
          discountApplied === ""
            ? PromoValidation?.validPromo === true
              ? `${promoValue}|${
                  selectedPromoCodeData[0].promoCodeType === "PROMOCODE"
                    ? "OFFER"
                    : "COUPON CODE"
                }`
              : ""
            : "DISCOUNT|DISCOUNT",
        promoAmount:
          discountApplied === ""
            ? PromoValidation?.validPromo === true
              ? discountvalue
              : 0
            : discountvalue,
        dateOfBirth: getCustomDate().date_of_birth,
        gender: values.gender,
        nationality: values.nationality,
        occupation: values.occupation,
        companyName: values.companyName,
        purposeOfVisit: values.purposeOfVisit,
        programsAttended: values.programsAttended,
        eventType: values.eventType,
        otherGuestDetails: finalGuestData,
        segment: segment,
        emergency_contact: values.emergencyContact,
        emergency_contact_name: values.emergencyContactName,
        customer_eta: ETA,
        customer_etd: ETD,
        ocoCheckinComplete: values.ocoCheckinComplete,
        approvedByOverseas: values.approvedByOverseas,
        requestorName: values.requestorName,
        requestorContact: values.requestorContact,
        requestorDept: values.requestorDept,
        requestorApprover: values.requestorApprover,
        requestorRemarks: values.requestorRemarks, //will be added in case needed for future
        isComplimentary: compRequest,
        prpList: isDaylevelPricingSet ? [copyDayLevelPricingData] : null,
        bookingReferenceId: "",
        companyGstNumber: searchableValue,
        is_hourly_booking:
          hotel.slotType === "PURE_HOURLY"
            ? sessionStorage.getItem("types") === "day"
              ? "true"
              : "false"
            : "false",
        sameDay: sameDay,
        ratePlanName: roomInformation?.ratePlanList?.filter(
          (item) => item.ratePlanId === values.rate_plan
        )[0]?.ratePlanName,
        sendEmail: checkedChargeCard ? false : true,
      };

      if (
        JSON.parse(
          hotel?.accessControl?.user_feature_map?.PastDateCreateBooking
        ).read !== "YES" &&
        checkinEpoch < currentEpoch
      ) {
        toast.error(`${t("Past date create booking is not allowed")}`);
      } else {
        if (
          showExpiresin === true &&
          ((expirationTime === "MINUTES" && Number(EnquiryExpiryTime) <= 15) ||
            (expirationTime === "HOURS" && Number(EnquiryExpiryTime) <= 0) ||
            (expirationTime === "DAYS" && Number(EnquiryExpiryTime) <= 0) ||
            (ExpirationSource === "BEFORE" &&
              gettingDifferenceOfCheckinAndInquiry(temporary?.start) < 15))
        ) {
          toast.error(`${t("Please enter enquiry time greater than 15 min")}`);
        } else {
          if (showExpiresin === true) {
            if (EnquiryExpiryTime === "") {
              toast.error(`${t("Enter the expiry timing")}`);
            } else {
              createBooking(temporary);
            }
          } else {
            createBooking(temporary);
          }
        }
      }

      const userIdPayload = {
        emailId: email,
        frontData:
          image !== undefined
            ? image.split(",")[1]
            : images[0]
            ? images[0].data_url?.split(",")[1]
            : null,
        frontType:
          image !== undefined
            ? "jpeg"
            : images[0]
            ? images[0].file?.type
            : null,
        backData:
          backImage !== undefined
            ? backImage.split(",")[1]
            : faviconImages[0]
            ? faviconImages[0].data_url?.split(",")[1]
            : null,
        backType:
          backImage !== undefined
            ? "jpeg"
            : faviconImages[0]
            ? faviconImages[0]?.file?.type
            : null,
        passportImageData:
          passportImage != undefined
            ? passportImage.split(",")[1]
            : passportImages[0]
            ? passportImages[0].data_url?.split(",")[1]
            : null,
        passportImageType:
          passportImage != undefined
            ? "jpeg"
            : passportImages[0]
            ? passportImages[0]?.file?.type
            : null,
        hotelId: hotelId,
      };

      if (userIdPayload?.frontData !== null) {
        await setBookingUserIdImages(userIdPayload);
      }

      if (otherGuestIdProofs != null) {
        let otherGuestIdData = [];
        for (let i = 0; i < otherGuestIdProofs?.length; i++) {
          let otherTemp = otherGuestIdProofs[i];
          let temp = {
            emailId: otherTemp?.emailId,
            frontData: otherTemp?.frontData,
            frontType: otherTemp?.frontType,
            backData: otherTemp?.backData,
            backType: otherTemp?.backType,
            passportImageData: otherTemp?.passportImageData,
            passportImageType: otherTemp?.passportImageType,
            hotelId: hotelId,
          };
          otherGuestIdData.push(temp);
        }
        let requestPayload = {
          hotelId: hotelId,
          otherGuestIdProofs: otherGuestIdData,
        };

        if (otherGuestIdData?.length > 0) {
          await setOtherGuestImages(requestPayload);
        }
      }
      let document_payload = {
        emailId: email,
        hotelId: hotelId,
      };
      if (document1?.data) {
        document_payload["attachmentOne"] = {
          base64Data: document1?.data,
          dataType: document1?.type,
        };
      }
      if (document2?.data) {
        document_payload["attachmentTwo"] = {
          base64Data: document2?.data,
          dataType: document2?.type,
        };
      }
      if (document_payload?.attachmentOne || document_payload?.attachmentTwo) {
        if (
          JSON.parse(
            hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
          ).read === "YES" &&
          (checkIshaAccess(hotel?.emailId) || checkIshaUSAccess(hotel?.emailId))
        ) {
          await setIshaGuestDocument(document_payload);
        }
      }
      if (otherGuestDocuments?.length > 0) {
        let document_payload_arr = [];
        for (let i = 0; i < otherGuestDocuments?.length; i++) {
          let document_payload = {
            emailId: otherGuestDocuments[i]?.emailId,
            hotelId: hotelId,
          };
          if (otherGuestDocuments[i]?.doc1?.data) {
            document_payload["attachmentOne"] = {
              base64Data: otherGuestDocuments[i]?.doc1?.data,
              dataType: otherGuestDocuments[i]?.doc1?.type,
            };
          }
          if (otherGuestDocuments[i]?.doc2?.data) {
            document_payload["attachmentTwo"] = {
              base64Data: otherGuestDocuments[i]?.doc2?.data,
              dataType: otherGuestDocuments[i]?.doc2?.data,
            };
          }
          if (
            document_payload?.attachmentOne ||
            document_payload?.attachmentTwo
          ) {
            document_payload_arr.push(document_payload);
          }
        }
        if (document_payload_arr?.length > 0) {
          if (
            JSON.parse(
              hotel?.accessControl?.user_feature_map?.IshaExtraGuestDetails
            ).read === "YES" &&
            (checkIshaAccess(hotel?.emailId) ||
              checkIshaUSAccess(hotel?.emailId))
          ) {
            await setIshaOtherGuestDocuments(document_payload_arr);
          }
        }
      }
      //
      // if (
      //   addCardCVV?.length > 4 ||
      //   addCardCVV?.length <= 2 ||
      //   addCardCVV === undefined
      // ) {
      //   error["cvv"] = true;
      // }

      // if (addCardFirstName?.length === 0) {
      //   error["firstName"] = true;
      // }

      // if (addCardLastName?.length === 0) {
      //   error["lastName"] = true;
      // }

      // if (!checkLuhn() || addCardNumber.length < 12) {
      //   error["number"] = true;
      // }

      // if (!checkExpiry()) {
      //   error["expiry"] = true;
      // }

      // setError({ ...error });
      if (scanIdState === "true") {
        scanIdpayload.emailId = email;
        const data = await setBookingUserIdImages(scanIdpayload);

        if (data === "Failed to upload new image") {
          toast.error(`${t("Something went wrong")}`);
        }

        await getUserId(values.customer_email);
      }
      setscanIdState("false");
    },
  });

  //custome pg info
  useEffect(() => {
    getAllData();
  }, []);

  const [customPgInfo, setcustomPgInfo] = useState("");
  const [scanIdState, setscanIdState] = useState("false");
  const [scanIdpayload, setscanIdpayload] = useState({});
  const getAllData = async () => {
    try {
      getcustomPGinfo().then((data) => {
        setcustomPgInfo(data?.active_pg_name);
        setenableAddCard(
          ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(data?.active_pg_name)
        );
        setpaymentMode(
          ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(data?.active_pg_name)
            ? "SWIPE CARD"
            : "CASH"
        );
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setETA(new Date(formik.values.booking_start));
    setETD(new Date(formik.values.booking_end));
  }, [formik.values.booking_start, formik.values.booking_end]);
  const getCustomDate = () => {
    const date1 = new Date(formik.values.booking_start);
    const date2 = new Date(formik.values.booking_end);
    let dob = new Date();
    if (
      formik.values.date_of_birth != "" &&
      formik.values.date_of_birth != "NA"
    ) {
      dob = new Date(formik.values.date_of_birth);
    }

    if (viewType === "resourceTimelineWeek") {
      if (checkinSlots.length === 1) {
        date1.setHours(selectedCheckinSlot);
        date2.setHours(selectedCheckoutSlot);
        date1.setMinutes(0);
        date2.setMinutes(0);
        date1.setSeconds(0);
        date2.setSeconds(0);
      } else if (checkinSlots.length === 3) {
        date1.setHours(selectedCheckinSlot);
        date2.setHours(selectedCheckoutSlot);
        date1.setMinutes(0);
        date2.setMinutes(0);
        date1.setSeconds(0);
        date2.setSeconds(0);
      }
    }
    if (viewType === "resourceTimelineMonthCustom") {
      if (checkinSlots.length === 1) {
        date1.setHours(selectedCheckinSlot);
        date2.setHours(selectedCheckoutSlot);
        date1.setMinutes(0);
        date2.setMinutes(0);
        date1.setSeconds(0);
        date2.setSeconds(0);
      }
    }
    if (formik.values.date_of_birth == "") {
      return {
        start: date1,
        end: date2,
        date_of_birth: "",
      };
    } else {
      return {
        start: date1,
        end: date2,
        date_of_birth: moment(dob).format("YYYY-MM-DD"),
      };
    }
  };
  const setCustomCheckin = (slots, checkoutSlotsData) => {
    const temp_date = new Date(eventData.start);
    const temp_date_1 = new Date(eventData.end);
    if (slots.length === 1) {
      if (
        temp_date.getDate() === temp_date_1.getDate() &&
        moment(temp_date).format("hh:mm:ss") !== "12:00:00"
      ) {
        temp_date_1.setDate(temp_date_1.getDate() + 1);
      }

      if (
        moment(temp_date).format("hh:mm:ss") === "12:00:00" &&
        sessionStorage.getItem("types") !== "month"
      ) {
        if (hotelCountry !== "United States") {
          temp_date.setDate(temp_date.getDate() - 1);
        }
        if (hotelCountry === "United States") {
          temp_date_1.setDate(temp_date_1.getDate() + 1);
        }
      }

      temp_date.setHours(slots[0]);
      temp_date_1.setHours(checkoutSlotsData[0]);
      formik.setFieldValue("booking_start", temp_date);
      formik.setFieldValue("booking_end", temp_date_1);
      setSelectedCheckinSlot(slots[0]);
      setSelectedCheckoutSlot(checkoutSlotsData[0]);
    } else if (slots.length === 3) {
      if (viewType === "resourceTimelineWeek") {
        const slot_number_start = displaySlots[eventData.start.getHours()];
        const slot_number_end = displaySlots[eventData.end.getHours()];
        temp_date.setHours(slots[slot_number_start]);
        temp_date_1.setHours(checkoutSlotsData[slot_number_end]);
        formik.setFieldValue("booking_start", temp_date);
        formik.setFieldValue("booking_end", temp_date_1);
        setSelectedCheckinSlot(slots[slot_number_start]);
        setSelectedCheckoutSlot(checkoutSlotsData[slot_number_end]);
      } else {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          temp_date.setHours(slots[0]);
          temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 1]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[0]);
          setSelectedCheckoutSlot(
            checkoutSlotsData[checkoutSlotsData.length - 1]
          );
        } else {
          temp_date.setHours(slots[0]);
          temp_date_1.setHours(checkoutSlotsData[0]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[0]);
          setSelectedCheckoutSlot(checkoutSlotsData[0]);
        }
      }
    } else {
      if (viewType !== "resourceTimelineDay") {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          // temp_date.setHours(slots[slots.length / 2 - 1]);
          // temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 2]);
          temp_date.setHours(
            temp_date.getHours() === 0 ? 12 : temp_date.getHours()
          );
          temp_date_1.setHours(
            temp_date_1.getHours() === 0 ? 12 : temp_date_1.getHours()
          );
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          // setSelectedCheckinSlot(slots[slots.length / 2 - 1]);
          // setSelectedCheckoutSlot(
          //   checkoutSlotsData[checkoutSlotsData.length - 2]
          // );
          setSelectedCheckinSlot(
            temp_date.getHours() === 0 ? 12 : temp_date.getHours()
          );
          setSelectedCheckoutSlot(
            temp_date_1.getHours() === 0 ? 12 : temp_date_1.getHours()
          );
        } else {
          temp_date.setHours(
            temp_date.getHours() === 0 ? 12 : temp_date.getHours()
          );
          temp_date_1.setHours(
            temp_date_1.getHours() === 0 ? 12 : temp_date_1.getHours()
          );
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          // setSelectedCheckinSlot(slots[0]);
          // setSelectedCheckoutSlot(checkoutSlotsData[0]);
          setSelectedCheckinSlot(
            temp_date.getHours() === 0 ? 12 : temp_date.getHours()
          );
          setSelectedCheckoutSlot(
            temp_date_1.getHours() === 0 ? 12 : temp_date_1.getHours()
          );
        }
      } else {
        setSelectedCheckinSlot(temp_date.getHours());
        setSelectedCheckoutSlot(temp_date_1.getHours());
      }
    }
    return { start: temp_date, end: temp_date_1 };
  };

  const fetchRoomInfo = async (checkin, checkout) => {
    try {
      formik.setFieldValue(
        "rate_plan",
        roomInformation.ratePlanList[0].ratePlanId
      );
      formik.setFieldValue("guests.Adults", roomInformation.defaultOccupancy);
      const pricesModel = new PriceModel({
        hotelId: hotelId,
        checkin: checkin,
        checkout: checkout,
        roomTypeId: selectedRoomTypeId,
        ratePlanId: roomInformation.ratePlanList[0].ratePlanId,
        numAdults: roomInformation.defaultOccupancy,
        numChildren: 0,
        newRate: null,
      });
      setPricingRequestModel(pricesModel);
      setisDaylevelPricingSet(false);
      const resp =
        hotel.slotType === "PURE_HOURLY"
          ? sessionStorage.getItem("types") === "day"
            ? await getQuickBookPricePayload(
                pricesModel.getQuickBookingparams()
              )
            : await getQuickBookPricePayloadNoon(
                pricesModel.getQuickBookingparams()
              )
          : await getQuickBookPricePayloadNoon(
              pricesModel.getQuickBookingparams()
            );

      setPricesValues(resp);
      fetchTaxNames();
      fetchCtas();
    } catch (e) {
      setLoadingData(false);
      setShowError(true);
    }
  };

  const [selectedRoomTypeId, setselectedRoomTypeId] = useState(
    eventData.resource.extendedProps.roomTypeId.split(",")[0]
  );
  const getPricesForRoomType = async (id) => {
    setselectedRoomTypeId(id);
    const payload = {
      hotelId: hotelId,
      checkin: moment(formik.values.booking_start).format(dateFormat),
      checkout: moment(formik.values.booking_end).format(dateFormat),
      roomTypeId: id,
      ratePlanId: roomInformation.ratePlanList[0].ratePlanId,
      numAdults: roomInformation.defaultOccupancy,
      numChildren: 0,
      newRate: null,
      newRateType: "TOTAL_PRETAX",
      ctaId: null,
    };
    const resp =
      hotel.slotType === "PURE_HOURLY"
        ? sessionStorage.getItem("types") === "day"
          ? await getQuickBookPricePayload(payload)
          : await getQuickBookPricePayloadNoon(payload)
        : await getQuickBookPricePayloadNoon(payload);
    setisDaylevelPricingSet(false);
    setPricesValues(resp);
  };
  const [roomTypesMap, setroomTypesMap] = useState([]);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/room/get-room-id-and-name?physicalRoomId=${eventData.resource.id}`
    )
      .then((res) => setroomTypesMap(Object.entries(res)))
      .catch((err) =>
        toast.error(`${t("Error in getting rooms! Please try again later.")}`)
      );
  }, []);

  const fetchRoomTypeData = async () => {
    setLoadingData(true);
    try {
      const slots =
        hotel.slotType === "PURE_HOURLY"
          ? viewType === "resourceTimelineDay"
            ? await getCheckinCheckoutSlotsHourly({
                checkin: moment(eventData.start).format("DD-MM-YYYY HH:00:00"),
                checkout: moment(eventData.end).format("DD-MM-YYYY HH:00:00"),
              })
            : await getCheckinCheckoutSlots({
                checkin: moment(eventData.start).format("DD-MM-YYYY HH:00:00"),
                checkout: moment(eventData.end).format("DD-MM-YYYY HH:00:00"),
              })
          : await getCheckinCheckoutSlots({
              checkin: moment(eventData.start).format("DD-MM-YYYY HH:00:00"),
              checkout: moment(eventData.end).format("DD-MM-YYYY HH:00:00"),
            });

      const changed = setCustomCheckin(
        slots.checkinSlots.sort((a, b) => a - b),
        slots.checkoutSlots.sort((a, b) => a - b)
      );
      setCheckinSlots(slots.checkinSlots.sort((a, b) => a - b));
      setCheckoutSlots(slots.checkoutSlots.sort((a, b) => a - b));
      await checkDateSelection(
        moment(changed.start).format("DD-MM-YYYY HH:00:00"),
        moment(changed.end).format("DD-MM-YYYY HH:00:00")
      );
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      setShowError(true);
    }
  };

  const setPricesValues = (resp) => {
    formik.setFieldValue("perNightPrice", Number(resp.perNightPrice));

    formik.setFieldValue("priceBeforeTax", Number(resp.priceBeforeTax));

    formik.setFieldValue("priceAfterTax", Number(resp.priceAfterTax));
    formik.setFieldValue("taxValue", Number(resp.taxValue));
  };

  const fetchUpdatedBookingPricing = async (changedType) => {
    try {
      setapiLoader(true);
      const data = pricingRequestModel.getQuickBookingparams();
      data.roomTypeId = selectedRoomTypeId;
      if (data?.ratePlanId === "") {
        data.ratePlanId = roomInformation.ratePlanList[0].ratePlanId;
        data.hotelId = hotelId;
        formik.setFieldValue(
          "rate_plan",
          roomInformation.ratePlanList[0].ratePlanId
        );
      }
      if (data.numAdults === 0) {
        data.numAdults = roomInformation.defaultOccupancy;
        formik.setFieldValue("guests.Adults", roomInformation.defaultOccupancy);
      }
      const resp =
        hotel.slotType === "PURE_HOURLY"
          ? sessionStorage.getItem("types") === "day"
            ? await getQuickBookPricePayload(data)
            : await getQuickBookPricePayloadNoon(data)
          : await getQuickBookPricePayloadNoon(data);
      setisDaylevelPricingSet(false);
      setPricesValues(resp, changedType);
      setapiLoader(false);
      setshowButtonOfPricing("");
    } catch (e) {
      console.log(e);
    }
  };

  const checkDateSelection = async (checkinDate, checkoutDate) => {
    try {
      const data = await checkRoomAvailability({
        roomTypeId: selectedRoomTypeId,
        physicalRoomId: eventData.resource.id,
        startDate: checkinDate,
        endDate: checkoutDate,
      });

      await fetchRoomInfo(checkinDate, checkoutDate);
    } catch (e) {
      console.log(e);
      formik.setFieldError(
        "booking_start",
        `${t("Room selection not available")}`
      );
      formik.setFieldError(
        "booking_end",
        `${t("Room selection not available")}`
      );
      setshowBlockRoomError(true);
    }
  };

  const changeSlots = async (date, type) => {
    const slots =
      hotel.slotType === "PURE_HOURLY"
        ? viewType === "resourceTimelineDay"
          ? await getCheckinCheckoutSlotsHourly({
              checkin:
                type === "booking_start"
                  ? moment(date).format(dateFormat)
                  : moment(formik.values.booking_start).format(dateFormat),
              checkout:
                type === "booking_start"
                  ? moment(formik.values.booking_end).format(dateFormat)
                  : moment(date).format(dateFormat),
            })
          : await getCheckinCheckoutSlots({
              checkin:
                type === "booking_start"
                  ? moment(date).format(dateFormat)
                  : moment(formik.values.booking_start).format(dateFormat),
              checkout:
                type === "booking_start"
                  ? moment(formik.values.booking_end).format(dateFormat)
                  : moment(date).format(dateFormat),
            })
        : await getCheckinCheckoutSlots({
            checkin:
              type === "booking_start"
                ? moment(date).format(dateFormat)
                : moment(formik.values.booking_start).format(dateFormat),
            checkout:
              type === "booking_start"
                ? moment(formik.values.booking_end).format(dateFormat)
                : moment(date).format(dateFormat),
          });
    if (type === "booking_start") {
      let index_1 = checkinSlots.indexOf(selectedCheckinSlot);
      let arr_1 = slots.checkinSlots.sort((a, b) => a - b);
      const temp_date = date;
      temp_date.setHours(arr_1[index_1]);
      formik.setFieldValue("booking_start", temp_date);
      setCheckinSlots(arr_1);
      setSelectedCheckinSlot(arr_1[index_1]);
    } else {
      let index = checkoutSlots.indexOf(selectedCheckoutSlot);
      let arr = slots.checkoutSlots.sort((a, b) => a - b);
      const temp_date_1 = date;
      temp_date_1.setHours(arr[index]);
      formik.setFieldValue("booking_end", temp_date_1);
      setCheckoutSlots(arr);
      setSelectedCheckoutSlot(arr[index]);
    }
  };

  const checkDateSelectionCheckin = async (checkinDate, isTime, setDate) => {
    try {
      if (isTime) formik.setFieldValue("booking_start", checkinDate);
      else await changeSlots(checkinDate, "booking_start");

      let new_end_date = new Date(new Date(checkinDate).getTime() + 86400000);

      if (setDate === "setdate") {
        new_end_date = new Date(new Date(formik.values.booking_end));
      }

      formik.setFieldValue("booking_end", new_end_date);
      const data = await checkRoomAvailability({
        roomTypeId: selectedRoomTypeId,
        physicalRoomId: eventData.resource.id,
        startDate: moment(checkinDate).format(`DD-MM-YYYY HH:00:00`),
        endDate: moment(new_end_date).format(`DD-MM-YYYY HH:00:00`),
      });

      pricingRequestModel.checkin = moment(checkinDate).format(dateFormat);
      pricingRequestModel.checkout = moment(new_end_date).format(dateFormat);
      pricingRequestModel.resetParams();
      delete formik.errors.booking_start;
      delete formik.errors.booking_end;
      setshowBlockRoomError(false);
      fetchUpdatedBookingPricing();
    } catch (e) {
      console.log(e);
      formik.setFieldError(
        "booking_start",
        `${t("Room selection not available")}`
      );
      formik.setFieldError(
        "booking_end",
        `${t("Room selection not available")}`
      );
      setshowBlockRoomError(false);
      setshowBlockRoomError(true);
    }
  };

  const checkDateSelectionCheckout = async (checkoutDate, isTime) => {
    try {
      if (isTime) formik.setFieldValue("booking_end", checkoutDate);
      else await changeSlots(checkoutDate, "booking_end");
      setapiLoader(true);
      const data = await checkRoomAvailability({
        roomTypeId: selectedRoomTypeId,
        physicalRoomId: eventData.resource.id,
        startDate: moment(formik.values.booking_start).format(
          "DD-MM-YYYY HH:00:00"
        ),
        endDate: moment(checkoutDate).format("DD-MM-YYYY HH:00:00"),
      });

      pricingRequestModel.checkout = moment(checkoutDate).format(dateFormat);
      pricingRequestModel.resetParams();
      setapiLoader(false);
      delete formik.errors.booking_start;
      delete formik.errors.booking_end;
      setshowBlockRoomError(false);
      fetchUpdatedBookingPricing();
    } catch (e) {
      console.log(e);
      formik.setFieldError(
        "booking_end",
        `${t("Room selection not available")}`
      );
      formik.setFieldError(
        "booking_start",
        `${t("Room selection not available")}`
      );
      setshowBlockRoomError(true);
    }
  };
  const [bookingId, setbookingId] = useState("");
  const createBooking = async (data) => {
    setLoad(true);
    data.hotelId = hotelId;
    data.roomTypeId = selectedRoomTypeId;
    const apidata = new NewReservationModel(data);
    try {
      const resp = await perfomAdminBooking(apidata.getNewBookingParams());
      /* amplitude
        .getInstance()
        .logEvent(
          "USER CREATED BOOKING THROUGH BOOKING GRID",
          amplitudeEventProperties
        ); */
      if (resp?.bookingId) {
        toast.success(
          `${t("Booking successfully created with booking ID: ")} ${
            resp?.bookingId
          }`
        );
      }
      data.id = resp.bookingId;
      data.bookingId = resp.bookingId;
      setbookingId(resp.bookingId);
      data.bookingSource = "Walk-In Booking";
      data.reservationId = resp.reservationId;

      if (
        selfCheckin &&
        JSON.parse(hotel?.accessControl?.user_feature_map?.PastDateCheckin)
          .read !== "YES" &&
        checkinEpoch < currentEpoch
      ) {
        toast.error(`${t("Past date checkin is not allowed")}`);
      } else {
        if (dirtyStatus === "DIRTY" && selfCheckin) {
          toast.error(
            `${t(
              "Dirty room can not be check-in. Clean room to give check-in"
            )}`
          );
        } else {
          if (selfCheckin) {
            try {
              const res = await axios.post(
                `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/admin-confirmed-resids` +
                  "?hotelId=" +
                  hotelId +
                  "&hotel_id=" +
                  hotelId,
                { resIds: [resp.reservationId], bookingId: resp.bookingId }
              );
              await handleGetDoorLockDetails({
                bookingId: resp.bookingId,
                reservationId: resp.reservationId,
                actionRequired: "make",
              });
              setselfCheckin(false);
            } catch (err) {
              setselfCheckin(false);
            }
            // handleSpringDataMutationRequest(
            //   "POST",
            //   `core/api/v1/reservation/admin-confirmed-resids`,
            //   { resIds: [resp.reservationId], bookingId: resp.bookingId }
            // )
            //   .then((res) => setselfCheckin(false))
            //   .catch((err) => setselfCheckin(false));
          }
        }
      }
      // if (
      //   selfCheckin &&
      //   JSON.parse(hotel?.accessControl?.user_feature_map?.PastDateCheckin)
      //     .read !== "YES" &&
      //   checkinEpoch < currentEpoch
      // ) {
      //   toast.error("Past date checkin is not allowed");
      // } else {
      //   if (dirtyStatus === "DIRTY" && selfCheckin) {
      //     toast.error(
      //       "Dirty room can not be check-in. Clean room to give check-in"
      //     );
      //   } else {
      //     if (selfCheckin) {
      //       try {
      //         const res = await handleSpringDataMutationRequest(
      //           "POST",
      //           `core/api/v1/reservation/admin-confirmed-resids` +
      //             "?hotelId=" +
      //             hotelId +
      //             "&hotel_id=" +
      //             hotelId,
      //           { resIds: [resp.reservationId], bookingId: resp.bookingId }
      //         );
      //         await handleGetDoorLockDetails({
      //           bookingId: resp.bookingId,
      //           reservationId: resp.reservationId,
      //           actionRequired: "make",
      //         });
      //         setselfCheckin(false);
      //       } catch (err) {
      //         setselfCheckin(false);
      //       }
      //       // handleSpringDataMutationRequest(
      //       //   "POST",
      //       //   `core/api/v1/reservation/admin-confirmed-resids`,
      //       //   { resIds: [resp.reservationId], bookingId: resp.bookingId }
      //       // )
      //       //   .then((res) => setselfCheckin(false))
      //       //   .catch((err) => setselfCheckin(false));
      //     }
      //   }
      // }

      const paymentPayload = {
        card_first_name: addCardFirstName,
        card_last_name: addCardLastName,
        card_number: addCardNumber?.replace(/\s/g, "")?.slice(0, 16),
        card_exp_date: addCardExpiry,
        card_cvv: addCardCVV,
        email: extractValidChars(data.email),
        hotelId: hotelId,
        booking_id: resp?.bookingId,
      };
      if (
        addCardFirstName?.length > 0 &&
        !/^[0-9]*$/.test(addCardFirstName) &&
        addCardLastName?.length > 0 &&
        !/^[0-9]*$/.test(addCardLastName) &&
        checkExpiry() &&
        checkLuhn() &&
        addCardNumber.length > 12 &&
        addCardCVV?.length <= 4
      ) {
        if (paymentChargeAccess) {
          if (
            customPgInfo === "FORTIS" ||
            customPgInfo === "TSYS" ||
            customPgInfo === "SHIFT4"
          ) {
            const res = await saveCardInfo(paymentPayload);

            if (checkedChargeCard) {
              djangoHandleDataMutationRequest(
                "POST",
                "invoice/chargeCustomerCardOwn/",
                {
                  hotelId: hotelId,
                  trans_type: "sale",
                  bookingID: resp?.bookingId,
                  cust_email: extractValidChars(data.email),
                  name: addCardFirstName + addCardLastName,
                  is_emv: "NO",
                  card_id: res.message.toString(),
                  amount: Number(paymentAmount),
                  logged_user_email: loggedUserEmail,
                  reservation_id: resp.reservationId,
                  charged_at_booking_creation: "true",
                }
              )
                .then((res) =>
                  sendBookingEmail(
                    resp?.bookingId,
                    extractValidChars(data.email)
                  )
                )
                .catch((error) => {
                  toast.error(error, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                });
            }
          }
        }
      }

      // if (addonsProductArray?.length > 0) {
      //   for (let k = 0; k < addonsProductArray?.length; k++) {
      //     djangoHandleDataMutationRequest(
      //       "POST",
      //       "invoice/addServiceToInvoice/",
      //       {
      //         hotelId: hotelId,
      //         booking_id: resp.bookingId,
      //         service_item: {
      //           service_date: moment(formik.values.booking_start).format(
      //             "MMM DD YYYY"
      //           ),
      //           service_desc:
      //             addonsProductArray[k].description +
      //             " Quantity: " +
      //             addonsProductArray[k].quantity,
      //           service_amount:
      //             parseFloat(addonsProductArray[k].quantity) *
      //             parseFloat(addonsProductArray[k].priceWithoutTax).toFixed(2),
      //           service_amount_type: "DEBIT",
      //           service_tax:
      //             parseFloat(addonsProductArray[k].priceWithTax).toFixed(2) -
      //             parseFloat(addonsProductArray[k].priceWithoutTax).toFixed(2) *
      //               parseFloat(addonsProductArray[k].quantity),
      //         },
      //         logged_user_email: localStorage.getItem("email"),
      //       }
      //     )
      //       .then((res) => console.log(res))
      //       .catch((e) => console.log(e));
      //   }
      // }
      if (showCollectpayment) {
        if (paymentMode === "CASH") {
          handleCashPayment(resp.bookingId);
        } else if (paymentMode === "CARD") {
          handleOfflineCardPayment(resp.bookingId);
        } else if (paymentMode === "UPI") {
          handleUpiPayment(resp.bookingId);
        } else if (paymentMode === "OTHER") {
          handleOtherModePayment(resp.bookingId);
        } else if (paymentMode === "BANK TRANSFER") {
          handleBankTransferPayment(resp.bookingId);
        }
      }
      setdisablePrices(true);

      if (
        hotel?.accessControl?.hotel?.enable_id_scanner === "IDGURU" &&
        Object.entries(selectedUserIdGuruData).length > 0
      ) {
        handleSpringDataMutationRequest(
          `PUT`,
          `core/api/v1/idguru/mark-id-used?id=${selectedUserIdGuruData.id}`
        )
          .then((res) => console.log("res", res))
          .catch((err) => console.error(err.response.data));
      }

      setLoad(false);
      addEvent(data, checkinSlots.length > 3);
      reload();
      if (isGroup) {
        reloadagain();
      }

      openTheBookingModal(resp);
      // if (
      //   resp.bookingId !== null &&
      //   resp.bookingId !== undefined &&
      //   resp.bookingId !== ""
      // ) {
      //   const response = await addSegmentAndSubSegment(resp.bookingId, segment);
      // }
      return resp.bookingId;
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      handleAddEventSidebar();
    }
  };

  const sendBookingEmail = (bookingId, email) => {
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/sendBookingConfirmationCust",
      {
        booking_id: bookingId,
        email_id: email,
      }
    )
      .then((res) => console.log(""))

      .catch((err) => console.error(err));
  };

  const fetchCtas = () => {
    try {
      getCtaNamesNew().then((resp) => {
        const newData = isArray(resp) ? resp : [];
        setCtas(ctas.concat(newData));
      });
    } catch (e) {
      console.log(e);
    }
  };
  const fetchTaxNames = () => {
    try {
      getTaxNamesForRoom(selectedRoomTypeId).then((resp) => {
        setTaxNames(resp);
        formik.setFieldValue("selectedTaxes", resp);
      });
    } catch (e) {
      console.log(e);
    }
  };
  const taxIdChanged = (e) => {
    formik.setFieldValue("taxId", e.target.value);
  };

  //Scan Images
  const [Scannerloader, setScannerloader] = useState(false);
  const [imageFront, setImageFront] = useState();
  const [imageBack, setImageBack] = useState();
  const [ScannerData, setScannerData] = useState();

  const getScannerUrl = () => {
    if (
      `${process.env.REACT_APP_ENV}` === "BETA" ||
      `${process.env.REACT_APP_ENV}` === "LOCAL"
    ) {
      return "https://text.motelpro.com/ScanDocDemo?images=front+back";
    } else if (`${process.env.REACT_APP_ENV}` === "PROD") {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
    } else {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
      // return "https://text.motelpro.com/ScanDocDemo?images=front+back";
    }
  };

  const getIdValue = (type) => {
    if (type === "DrivingLicense") {
      return "Drivers License";
    } else if (type === "Passport") {
      return "Passport";
    } else {
      return "Others";
    }
  };

  const scannedIdGuruData = () => {
    setimageUpload("Device");
    let customer_name =
      selectedUserIdGuruData.data.firstName +
      " " +
      selectedUserIdGuruData.data.middleName +
      " " +
      selectedUserIdGuruData.data.lastName;
    let dummy_email =
      customer_name.replace(/\s+/g, ".").toLowerCase() +
      hotelId +
      Math.round(Date.now() / 1000) +
      "@example.com";
    dummy_email = extractValidChars(dummy_email);
    formik.setFieldValue("customer_name", customer_name);
    formik.setFieldValue("state", selectedUserIdGuruData?.data?.state);
    formik.setFieldValue("zipcode", selectedUserIdGuruData?.data?.zipCode);
    formik.setFieldValue("city", selectedUserIdGuruData?.data?.city);
    formik.setFieldValue(
      "customer_phone",
      selectedUserIdGuruData?.data?.phoneCountryCode +
        selectedUserIdGuruData?.data?.phoneAreaCode +
        selectedUserIdGuruData?.data?.phoneNumber
    );
    formik.setFieldValue(
      "address1",
      selectedUserIdGuruData?.data?.streetAddress
    );
    // formik.setFieldValue("address2", ApiData?.apiresponse?.docinfo?.addr2);
    formik.setFieldValue("govtIdValue", selectedUserIdGuruData?.data?.idNumber);
    formik.setFieldValue("country", "United States");
    formik.setFieldValue(
      "govtIdType",
      getIdValue(selectedUserIdGuruData?.data?.idType)
    );
    formik.setFieldValue(
      "customer_email",
      selectedUserIdGuruData?.data?.emailAddress
    );

    let customer_email =
      formik.values.customer_email === ""
        ? dummy_email
        : formik.values.customer_email;

    const userIdPayload = {
      emailId: "",
      frontData: selectedUserIdGuruData?.data?.idFrontImage_Base64,
      frontType: "png",
      backData: selectedUserIdGuruData?.data?.idBackImage_Base64,
      backType: "png",
      hotelId: hotelId,
    };
    setscanIdpayload(userIdPayload);
    setscanIdState("true");
    setImages(
      selectedUserIdGuruData?.data?.idFrontImage_Base64
        ? [
            {
              data_url: `data:image/png;base64,${selectedUserIdGuruData?.data?.idFrontImage_Base64}`,
              file: {
                type: "png",
              },
            },
          ]
        : []
    );
    setFaviconImages(
      selectedUserIdGuruData?.data?.idBackImage_Base64
        ? [
            {
              data_url: `data:image/png;base64,${selectedUserIdGuruData?.data?.idBackImage_Base64}`,
              file: {
                type: "png",
              },
            },
          ]
        : []
    );
  };

  const ScannedData = () => {
    setimageUpload("Device");
    const URL = getScannerUrl();
    // console.log("scanned url : ", URL);
    setScannerloader(true);
    const ssData = fetch(URL)
      .then((res) => res.json())
      .then((data) => {
        setScannerloader(false);

        const ApiData = data;
        setScannerData(ApiData?.apiresponse);
        // setImageFront(ApiData?.apiresponse?.imgfront);
        // setImageBack(ApiData?.apiresponse?.imgback);

        if (ApiData?.apiresponse?.errormsg?.cd === 0) {
          let customer_name = "";
          if (ApiData?.apiresponse?.docinfo?.fullname) {
            customer_name = ApiData?.apiresponse?.docinfo?.fullname;
          } else {
            customer_name =
              ApiData?.apiresponse?.docinfo?.fname +
              ApiData?.apiresponse?.docinfo?.lname;
          }
          let dummy_email =
            customer_name.replace(/\s+/g, ".").toLowerCase() +
            hotelId +
            Math.round(Date.now() / 1000) +
            "@example.com";
          formik.setFieldValue("customer_name", customer_name);
          formik.setFieldValue("state", ApiData?.apiresponse?.docinfo?.state);
          formik.setFieldValue(
            "zipcode",
            ApiData?.apiresponse?.docinfo?.zipcode
          );
          formik.setFieldValue("city", ApiData?.apiresponse?.docinfo?.city);
          formik.setFieldValue(
            "address1",
            ApiData?.apiresponse?.docinfo?.addr1
          );
          formik.setFieldValue(
            "address2",
            ApiData?.apiresponse?.docinfo?.addr2
          );
          formik.setFieldValue(
            "govtIdValue",
            ApiData?.apiresponse?.docinfo?.idno
          );
          formik.setFieldValue("country", "United States");
          formik.setFieldValue("govtIdType", "Drivers License");
          formik.setFieldValue("customer_email", "");

          let customer_email =
            formik.values.customer_email === ""
              ? dummy_email
              : formik.values.customer_email;

          const userIdPayload = {
            emailId: "",
            frontData: ApiData?.apiresponse?.imgfront,
            frontType: "png",
            backData: ApiData?.apiresponse?.imgback,
            backType: "png",
            hotelId: hotelId,
          };
          setscanIdpayload(userIdPayload);
          setscanIdState("true");
          setImages(
            ApiData?.apiresponse?.imgfront
              ? [
                  {
                    data_url: `data:image/png;base64,${ApiData?.apiresponse?.imgfront}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
          setFaviconImages(
            ApiData?.apiresponse?.imgback
              ? [
                  {
                    data_url: `data:image/png;base64,${ApiData?.apiresponse?.imgback}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
          // setImagesData({
          //   frontUrl: `data:image/png;base64,${ApiData?.apiresponse?.imgfront}`,
          //   backUrl: `data:image/png;base64,${ApiData?.apiresponse?.imgback}`,
          // });
        } else {
          toast.error(
            ApiData?.apiresponse?.errormsg?.txt === ""
              ? `${t(
                  "No data has been populated from scanner, kindly enter the customer information. Images has been populated"
                )}`
              : ApiData?.apiresponse?.errormsg?.txt
          );
          setImages(
            ApiData?.apiresponse?.imgfront
              ? [
                  {
                    data_url: `data:image/png;base64,${ApiData?.apiresponse?.imgfront}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
          setFaviconImages(
            ApiData?.apiresponse?.imgback
              ? [
                  {
                    data_url: `data:image/png;base64,${ApiData?.apiresponse?.imgback}`,
                    file: {
                      type: "png",
                    },
                  },
                ]
              : []
          );
        }
      })
      .catch((err) => {
        setScannerloader(false);
      });
  };

  //images

  const [images, setImages] = useState([]);
  // const maxNumber = 69;

  // const onChange = (imageList, addUpdateIndex) => {
  //   setImages(imageList);
  // };

  const [faviconImages, setFaviconImages] = useState([]);
  // const [loading, setloading] = useState(false);

  // const onChangeFavicon = (faviconImageList, addUpdateIndex) => {
  //   setFaviconImages(faviconImageList);
  // };

  // var logoImage = images[images.length - 1]?.data_url.split(",");
  // var faviconImage = faviconImages[faviconImages.length - 1]?.data_url.split(",");
  // var faivconformat = faviconImages[faviconImages.length - 1]?.data_url.split(",");
  // var faivconimgFormat = faivconformat && faivconformat[0]?.split("/");
  // var faivconImgType = faivconimgFormat && faivconimgFormat[1]?.split(";");

  // var logoformat = images[images.length - 1]?.data_url.split(",");
  // var logoimgFormat = logoformat && logoformat[0]?.split("/");
  // var logoImgType = logoimgFormat && logoimgFormat[1]?.split(";");

  const checkExpiry = () => {
    const currentYear = new Date()?.getFullYear();
    const currentMonth = new Date()?.getMonth();
    const minExpiry =
      (currentMonth > 8 ? `${currentMonth + 1}` : `0${currentMonth + 1}`) +
      `${currentYear}`.slice(2, 4);
    const expiryValidity =
      addCardExpiry?.length === 4 &&
      (addCardExpiry?.slice(2, 4) > minExpiry?.slice(2, 4)
        ? true
        : addCardExpiry?.slice(2, 4) === minExpiry?.slice(2, 4)
        ? addCardExpiry?.slice(0, 2) >= minExpiry?.slice(0, 2)
        : false);
    return expiryValidity;
  };

  const [dayLevelPricindData, setdayLevelPricindData] = useState({});
  const [copyDayLevelPricingData, setcopyDayLevelPricingData] = useState({});

  const dayLevelDataa = () => {
    if (hotel.slotType === "PURE_HOURLY") {
      handleSpringDataMutationRequest(
        "POST",
        "core/api/v1/reservation/getQuickBookBreakdownNoon",
        {
          hotelId: hotelId,
          checkin: moment(formik?.values?.booking_start).format(
            `DD-MM-YYYY ${selectedCheckinSlot}:00:00`
          ),
          checkout: moment(formik?.values?.booking_end).format(
            `DD-MM-YYYY ${selectedCheckoutSlot}:00:00`
          ),
          roomTypeId: selectedRoomTypeId,
          ratePlanId: formik?.values?.rate_plan,
          numAdults: formik?.values?.guests?.Adults,
          numChildren: formik?.values?.guests?.Children,
          newRate: formik?.values?.priceBeforeTax,
          newRateType: "TOTAL_PRETAX",
          ctaId: null,
        }
      )
        .then((res) => {
          setdayLevelPricindData(res);
          setcopyDayLevelPricingData(cloneDeep(res));
          toggleDayLevelPricingDrawer("right", true)();
        })
        .catch((Err) => console.log(Err));
    } else {
      if (selectedCheckinSlot > 0 && selectedCheckoutSlot > 0) {
        handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/reservation/getQuickBookBreakdown",
          {
            hotelId: hotelId,
            checkin: moment(formik?.values?.booking_start).format(
              `DD-MM-YYYY ${selectedCheckinSlot}:00:00`
            ),
            checkout: moment(formik?.values?.booking_end).format(
              `DD-MM-YYYY ${selectedCheckoutSlot}:00:00`
            ),
            roomTypeId: selectedRoomTypeId,
            ratePlanId: formik?.values?.rate_plan,
            numAdults: formik?.values?.guests?.Adults,
            numChildren: formik?.values?.guests?.Children,
            newRate: formik?.values?.priceBeforeTax,
            newRateType: "TOTAL_PRETAX",
            ctaId: null,
          }
        )
          .then((res) => {
            setdayLevelPricindData(res);
            setcopyDayLevelPricingData(cloneDeep(res));
            toggleDayLevelPricingDrawer("right", true)();
          })
          .catch((Err) => console.log(Err));
      }
    }
  };

  useEffect(() => {
    if (isDaylevelPricingSet === false) {
      // dayLevelDataa();
    }
  }, [
    formik.values.rate_plan,
    selectedRoomTypeId,
    formik.values.booking_start,
    formik.values.booking_end,
    formik?.values?.guests?.Adults,
    formik?.values?.guests?.Children,
    formik?.values?.priceBeforeTax,
  ]);

  useEffect(() => {
    setError({
      firstName: false,
      lastName: false,
      number: false,
      cvv: false,
      expiry: false,
    });
  }, [
    addCardNumber,
    addCardExpiry,
    addCardFirstName,
    addCardLastName,
    addCardCVV,
  ]);

  useEffect(() => {
    // console.log(error);
  }, [error]);

  const checkLuhn = () => {
    let whitelist = [
      "4111111111111111",
      "4012888888881881",
      "4005562231212149",
      "5454545454545454",
      "5405222222222226",
      "6011000995500000",
      "371449635398431",
    ];
    if (whitelist.includes(addCardNumber)) {
      return true;
    }
    let s = 0;
    let doubleDigit = false;
    for (let i = addCardNumber?.length - 1; i >= 0; i--) {
      let digit = +addCardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    let flag = s % 10 === 0;
    if (flag) {
      // console.log("Luhn check passed");
    }
    return flag;
  };

  const [addCardFirstName, setAddCardFirstName] = useState("");
  const [addCardLastName, setAddCardLastName] = useState("");
  const [addCardNumber, setAddCardNumber] = useState("");
  const [addCardType, setAddCardType] = useState();
  const [addCardCVV, setAddCardCVV] = useState("");
  const [addCardExpiry, setAddCardExpiry] = useState("");
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    number: false,
    cvv: false,
    expiry: false,
  });

  useEffect(() => {
    setAddCardNumber(addCardNumber?.replace(/\s/g, ""));
    if (addCardNumber?.length > 1) {
      const type = getCardType(addCardNumber?.slice(0, 16))?.type;
      setAddCardType(type);
    }
  }, [addCardNumber]);

  const [showFrontImagePopup, setshowFrontImagePopup] = useState(false);
  const [showPopUpImages, setshowPopUpImages] = useState("");

  const handleshowFrontImagePopupClose = () => setshowFrontImagePopup(false);
  const handleFrontImagePopupShow = (data) => {
    setshowPopUpImages(data);
    setshowFrontImagePopup(true);
  };

  const [ImagesData, setImagesData] = useState();
  const govIdUS = [
    `${t("Passport")}`,
    `${t("Drivers License")}`,
    `${t("Others")}`,
  ];

  // Government IDs --- Indian properties
  const govIdIndia = [
    `${t("Passport")}`,
    `${t("Drivers License")}`,
    `${t("Aadhar")}`,
    `${t("Voter ID")}`,
    `${t("Others")}`,
  ];

  //card swipe

  const [swipeData, setswipeData] = useState({});

  const handleSwipeSuccess = (swipeData) => {
    setswipeData(swipeData);
    // const data = JSON.stringify(swipeData);
    const expMonth = swipeData?.expMonth;
    const expYear = swipeData?.expYear;
    setAddCardFirstName(swipeData?.firstName);
    setAddCardLastName(swipeData?.lastName);
    setAddCardNumber(swipeData?.account);
    setAddCardExpiry(expMonth + expYear);
  };

  useEffect(() => {
    CardSwipe.init({
      success: handleSwipeSuccess,
      debug: false,
    });
  }, []);

  const [customerNameData, setcustomerNameData] = useState([]);
  const [customerPhoneData, setcustomerPhoneData] = useState([]);
  const [customerEmailData, setcustomerEmailData] = useState([]);
  const [showData, setshowData] = useState(true);

  useEffect(async () => {
    if (formik.values.customer_name !== "") {
      const data = await getAllCustomers(formik.values.customer_name, "name");
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerNameData(sortedArr);
    }
    if (formik.values.customer_name.length === 1) {
      setcustomerNameData([]);
    }
    if (formik.values.customer_name === "") {
      setshowData(true);
    }
  }, [formik.values.customer_name]);

  useEffect(async () => {
    if (formik.values.customer_email !== "") {
      const data = await getAllCustomers(formik.values.customer_email, "email");
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerEmailData(sortedArr);
    }
    if (formik.values.customer_email.length === 1) {
      setcustomerEmailData([]);
    }
    if (formik.values.customer_email === "") {
      setshowData(true);
    }
  }, [formik.values.customer_email]);

  useEffect(async () => {
    if (formik.values.customer_phone !== "") {
      const data = await getAllCustomers(
        formik.values.customer_phone,
        "contact"
      );

      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerPhoneData(sortedArr);
    }
    if (formik.values.customer_phone.length === 1) {
      setcustomerPhoneData([]);
    }
    if (formik.values.customer_phone === "") {
      setshowData(true);
    }
  }, [formik.values.customer_phone]);

  const populateCustomerFields = (item) => {
    formik.setFieldValue("customer_name", item.name);
    formik.setFieldValue("customer_phone", item.contact);
    formik.setFieldValue("customer_email", item.email);
    //Gender
    if (item?.gender != null && item?.gender != "NA") {
      formik.setFieldValue("gender", item?.gender);
    }
    //DOB
    if (item?.dateOfBirth && item?.dateOfBirth != "NA") {
      formik.setFieldValue("date_of_birth", item?.dateOfBirth);
    }
    //govtIdType
    if (item?.govtIdType) {
      formik.setFieldValue("govtIdType".item?.govtIdType);
    }
    //govtIdValue
    if (item?.govtIdValue) {
      formik.setFieldValue("govtIdValue", item?.govtIdValue);
    }
    //addressLine1
    if (item?.addressLine1) {
      formik.setFieldValue("address1", item?.addressLine1);
    }
    //addressLine2
    if (item?.addressLine2) {
      formik.setFieldValue("address2", item?.addressLine2);
    }
    //city
    if (item?.city && item?.city != "NA") {
      formik.setFieldValue("city", item?.city);
    }
    //state
    if (item?.state && item?.state != "NA") {
      formik.setFieldValue("state", item?.state);
    }
    //Country
    if (item?.country && item?.country != "NA") {
      formik.setFieldValue("country", item?.country);
    }
    //Zipcode
    if (item?.zipcode && item?.zipcode != "NA") {
      formik.setFieldValue("zipcode", item?.zipcode);
    }
    //nationality
    if (item?.nationality) {
      formik.setFieldValue("nationality", item?.nationality);
    }
    //occupation
    if (item?.occupation) {
      formik.setFieldValue("occupation", item?.occupation);
    }
    //company name
    if (item?.companyName) {
      formik.setFieldValue("companyName", item?.companyName);
    }
    if (item?.emergencyContact) {
      formik.setFieldValue("emergencyContact", item?.emergencyContact);
    }
    if (item?.emergencyContactName) {
      formik.setFieldValue("emergencyContactName", item?.emergencyContactName);
    }
    setcustomerEmailData([]);
    setcustomerNameData([]);
    setcustomerPhoneData([]);
    setshowData(false);
  };

  // const inputbox = document.querySelector("#customer_name");
  // const data = inputbox?.addEventListener("focusout", deFocus);

  const emptyValues = () => {
    setcustomerNameData([]);
    setcustomerEmailData([]);
    setcustomerPhoneData([]);
  };

  // const [unassignedReservations, setUnassignedReservations] = useState([]);
  // const [createbooking, setcreatebooking] = useState(false);
  // useEffect(async () => {
  //   const startDate = moment(formik.values.booking_start).format("DD-MM-YYYY");
  //   const endDate = moment(formik.values.booking_end).format("DD-MM-YYYY");
  //   // console.log("checkin : ", formik.values.booking_start, "checkout : ", formik.values.booking_end);
  //   // console.log("checkin : ", startDate, "checkout : ", endDate);
  //   const data = await getAllUnassignedReservations(startDate, endDate);
  //   // setUnassignedReservations(data);
  //   setUnassignedReservations([1, 2, 3, 4]);
  //   console.log("unassigned : ", unassignedReservations);
  // }, []);

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => {
  //   setShow(true);
  // };

  const webcamRef = React.useRef(null);

  const [image, setImage] = useState(undefined);
  const [backImage, setBackImage] = useState(undefined);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const backcapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setBackImage(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    width: 400,
    height: 300,
    facingMode: "user",
  };

  const [imageUpload, setimageUpload] = useState("Device");

  //Passport image - isha requirement
  const [passportImageUpload, setPassportImageUpload] = useState("Device");
  const [showPassportModal, setShowPassportModal] = useState(false);
  const handleClosePassportModal = () => setShowPassportModal(false);
  const handleOpenPassportModal = () => setShowPassportModal(true);
  const [passportImage, setPassportImage] = useState(undefined);
  const passportCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPassportImage(imageSrc);
  }, [webcamRef]);
  const [passportImages, setPassportImages] = useState([]);

  const [showFrontIdModal, setshowFrontIdModal] = useState(false);

  const handleCloseFrontIdModal = () => setshowFrontIdModal(false);
  const handleOpenFrontIdModal = () => {
    setshowFrontIdModal(true);
  };

  //toggle Day level pricing

  const [dayLevelPricingState, setdayLevelPricingState] = useState({
    right: false,
  });

  //TOGGLE add guest details drawer
  const toggleDayLevelPricingDrawer =
    (anchor, open, index) => async (event) => {
      if (
        event?.type === "keydown" &&
        (event?.key === "Tab" || event?.key === "Shift")
      ) {
        return;
      }

      setdayLevelPricingState({ ...dayLevelPricingState, [anchor]: open });
    };

  const dayLevelPricingComponent = (anchor) => (
    <Box
      sx={{ width: 650 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {
            <>
              <div className="folioInformation">
                <div className="fId">{t("Day Level Pricing")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleDayLevelPricingDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          }

          {/* <Divider /> */}
        </div>
        <Divider />
        {/* <div className="folioTabs">
        <TabPanel value={value} index={0} className="tabsContent"> */}
        <DayLevelPricing
          onCancelClick={toggleDayLevelPricingDrawer("right", false)}
          dayLevelPricindData={copyDayLevelPricingData}
          setdayLevelTotalTaxAmount={setdayLevelTotalTaxAmount}
          setdayLevelPreTaxAmount={setdayLevelPreTaxAmount}
          setdayLevelTotalTax={setdayLevelTotalTax}
          setdayLevelPerNightAmount={setdayLevelPerNightAmount}
          setisDaylevelPricingSet={setisDaylevelPricingSet}
          currency={hotel?.accessControl?.hotel?.hotel_currency}
          selectedLanguage={props.selectedLanguage}
        />
        {/* </TabPanel>
      </div> */}
      </div>
    </Box>
  );

  // useEffect(() => {
  //   handleSpringDataRequest(
  //     `web-api/update-elastic-search-index?bookingId=SFBOOKING_22353_15951`
  //   ).then((res) => console.log("Succeess"));
  // }, []);

  // Add guest details right drawer
  const [selectedPrograms, setSelectedPrograms] = useState(["-"]);
  const handleProgramChange = (event) => {
    const {
      target: { value },
    } = event;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;

    setSelectedPrograms(attendedPrograms);
    formik.setFieldValue("programsAttended", attendedPrograms.toString());
  };
  const [addGuestState, setAddGuestState] = useState([]);
  //TOGGLE add guest details drawer
  const toggleAddGuestDrawer = (anchor, open, index) => async (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    // Updating add guest state
    const updatedArr = addGuestState.map((anchorList, ind) => {
      if (index == ind) {
        const updatedState = { ...anchorList, [anchor]: open };
        return updatedState;
      } else {
        return anchorList;
      }
    });
    setAddGuestState(updatedArr);
  };

  const [addChildState, setAddChildState] = useState([]);
  //TOGGLE add guest details drawer
  const toggleAddChildDrawer = (anchor, open, index) => async (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }
    // Updating add guest state
    const updatedArr = addChildState.map((anchorList, ind) => {
      if (index == ind) {
        const updatedState = { ...anchorList, [anchor]: open };
        return updatedState;
      } else {
        return anchorList;
      }
    });
    setAddChildState(updatedArr);
  };
  // STORING CAMERA/DEVICE UPLOAD OF OTHER GUESTS
  const [otherGuestIdProofs, setOtherGuestIdProofs] = useState([]);
  const addGuestIdProof = (idProof) => {
    let tempArr = [...otherGuestIdProofs];
    tempArr.push(idProof);
    setOtherGuestIdProofs(tempArr);
  };
  const updateGuestIdProof = (idProof, ind) => {
    const updatedArr = otherGuestIdProofs.map((idData, index) => {
      if (index == ind) {
        const updatedState = idProof;
        return updatedState;
      } else {
        return idData;
      }
    });
    setOtherGuestIdProofs(updatedArr);
  };

  const [otherGuestDocuments, setOtherGuestDocuments] = useState([]);
  const addOtherGuestDocuments = (documents) => {
    let tempArr = [...otherGuestDocuments];
    tempArr.push(documents);
    setOtherGuestDocuments(tempArr);
  };
  const updateOtherGuestDocuments = (documents, ind) => {
    const updatedArr = otherGuestDocuments.map((docData, index) => {
      if (index == ind) {
        const updatedState = documents;
        return updatedState;
      } else {
        return docData;
      }
    });
    setOtherGuestDocuments(updatedArr);
  };

  //ETA, ETD, OCO Checkin
  const [ETA, setETA] = useState(new Date());
  const [ETD, setETD] = useState(new Date());

  const handleETAChange = (e) => {
    setETA(e);
  };

  const handleETDChange = (e) => {
    setETD(e);
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  // Add guest detail
  const addGuestDetail = (anchor, index) => {
    const defaultAddressDetail = {
      address1: formik.values.address1,
      address2: formik.values.address2,
      zipcode: formik.values.zipcode,
      city: formik.values.city,
      state: formik.values.state,
      country: formik.values.country,
    };

    return (
      <Box
        sx={{ width: "100%" }}
        // role="presentation"
      >
        <div className="folioUpdateWrapper">
          <div className="folioInformationActionWrapper">
            <div className="folioInformation">
              <div className="fId">{`${
                otherGuestDetails?.length > index
                  ? `${t("Edit guest")}`
                  : `${t("Add guest")}`
              } ${index + 2} details`}</div>
            </div>
            <div
              className="closeToggleDrawer"
              onClick={toggleAddGuestDrawer("right", false, index)}
            >
              <AiIcons.AiOutlineClose size="20px" color="black" />
            </div>
          </div>
          <Divider />

          {otherGuestDetails?.length > index ? (
            <NewGuestView
              onClose={toggleAddGuestDrawer(anchor, false, index)}
              addGuestFunc={addGuestData}
              updateGuestFunc={updateGuestData}
              existingData={otherGuestDetails}
              dataIndex={index}
              primaryAddress={defaultAddressDetail}
              existingIdProofData={otherGuestIdProofs}
              addIdProof={addGuestIdProof}
              updateIdProof={updateGuestIdProof}
              checkin={formik.values.booking_start}
              checkout={formik.values.booking_end}
              existingDocumentsData={otherGuestDocuments}
              addDocumentData={addOtherGuestDocuments}
              updateDocumentData={updateOtherGuestDocuments}
              selectedLanguage={props.selectedLanguage}
            />
          ) : (
            <NewGuestView
              onClose={toggleAddGuestDrawer(anchor, false, index)}
              addGuestFunc={addGuestData}
              updateGuestFunc={updateGuestData}
              existingData={otherGuestDetails}
              dataIndex={index}
              primaryAddress={defaultAddressDetail}
              existingIdProofData={otherGuestIdProofs}
              addIdProof={addGuestIdProof}
              updateIdProof={updateGuestIdProof}
              checkin={formik.values.booking_start}
              checkout={formik.values.booking_end}
              existingDocumentsData={otherGuestDocuments}
              addDocumentData={addOtherGuestDocuments}
              updateDocumentData={updateOtherGuestDocuments}
              selectedLanguage={props.selectedLanguage}
            />
          )}
        </div>
      </Box>
    );
  };

  //Setting the date of birth selection in formik(
  const setDateOfBirthSelection = (dob, isTime) => {
    try {
      formik.setFieldValue("date_of_birth", dob);
    } catch (e) {
      console.log(e);
    }
  };

  const [otherGuestDetails, setOtherGuestDetails] = useState([]);

  //Passing the drawer New Guest Information to formik
  const addGuestData = (data) => {
    let tempArr = [...otherGuestDetails];
    tempArr.push(data);
    setOtherGuestDetails(tempArr);
  };

  //Updation already existing guestData
  const updateGuestData = (data, index) => {
    // Updating  guest state
    const updatedArr = otherGuestDetails.map((guestData, ind) => {
      if (index == ind) {
        const updatedState = data;
        return updatedState;
      } else {
        return guestData;
      }
    });
    setOtherGuestDetails(updatedArr);
  };

  useEffect(() => {
    // console.log("updated guest details");
    // console.log(otherGuestDetails);
  }, [otherGuestDetails]);

  // Updation for the drawer open for each guest detail modal
  useEffect(() => {
    let tempArr = [];
    if (formik?.values?.guests?.Adults > 1) {
      let totalInsertion = formik.values.guests.Adults - 1;
      while (totalInsertion > 0) {
        tempArr.push({ right: false });
        totalInsertion = totalInsertion - 1;
      }
      setAddGuestState(tempArr);
    }
  }, [formik?.values?.guests?.Adults]);

  useEffect(() => {
    let tempArr = [];
    if (formik?.values?.guests?.Children > 0) {
      let totalInsertion = formik.values.guests.Children;
      while (totalInsertion > 0) {
        tempArr.push({ right: false });
        totalInsertion = totalInsertion - 1;
      }
    }
    setAddChildState(tempArr);
  }, [formik?.values?.guests?.Children]);
  // useEffect(() => {
  //   console.log("updated guest state");
  //   console.log(addGuestState);
  // }, [addGuestState]);

  const [showBackIdModal, setshowBackIdModal] = useState(false);

  const handleCloseBackIdModal = () => setshowBackIdModal(false);
  const handleOpenBackIdModal = () => {
    setshowBackIdModal(true);
  };

  const getGeoCodeValues = (zipcode) => {
    try {
      getGeoCodeDetails(zipcode).then((resp) => {
        const re = /^[0-9\b]+$/;
        //Cases where the city is not mentioned in response
        if (!re.test(resp.city)) {
          formik.setFieldValue("city", resp.city);
        } else {
          formik.setFieldValue("city", "");
        }
        formik.setFieldValue("state", resp.state);
        formik.setFieldValue("country", resp.country);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [code, setcode] = useState("secretcode");

  const [PromocodeData, setPromocodeData] = useState();
  const [promoValue, setpromoValue] = useState("");
  const [promoPricingList, setpromoPricingList] = useState("");
  const [PromoValidation, setPromoValidation] = useState("");
  const [secretCoupon, setsecretCoupon] = useState("");

  useEffect(() => {
    getPromoAllData();
  }, []);

  useEffect(() => {
    formik.values.rate_plan && getPricingLists();
  }, [formik.values.rate_plan]);

  const getPricingLists = async () => {
    const data = await getAllPromocodesPriceList(
      moment(formik.values.booking_start).format("DD-MM-YYYY HH:00:00"),
      moment(formik.values.booking_end).format("DD-MM-YYYY HH:00:00"),
      selectedRoomTypeId,
      formik.values.rate_plan
    );
    setpromoPricingList(data);
  };

  const [counters, setcounters] = useState(0);
  const [counterss, setcounterss] = useState(0);

  const [priceBeforeTaxesdata, setpriceBeforeTaxesdata] = useState(
    formik?.values?.priceBeforeTax
  );

  const [dayLevelTotalTaxAmount, setdayLevelTotalTaxAmount] = useState("");
  const [dayLevelPreTaxAmount, setdayLevelPreTaxAmount] = useState("");
  const [dayLevelTotalTax, setdayLevelTotalTax] = useState("");
  const [dayLevelPerNightAmount, setdayLevelPerNightAmount] = useState("");
  const [isDaylevelPricingSet, setisDaylevelPricingSet] = useState(false);

  useEffect(() => {
    if (isDaylevelPricingSet) {
      formik.setFieldValue(
        "perNightPrice",
        parseFloat(dayLevelPerNightAmount).toFixed(2)
      );
      formik.setFieldValue(
        "priceAfterTax",
        parseFloat(dayLevelTotalTaxAmount).toFixed(2)
      );
      formik.setFieldValue("taxValue", parseFloat(dayLevelTotalTax).toFixed(2));
      formik.setFieldValue(
        "priceBeforeTax",
        parseFloat(dayLevelPreTaxAmount).toFixed(2)
      );
    }
  }, [
    isDaylevelPricingSet,
    dayLevelTotalTaxAmount,
    dayLevelPreTaxAmount,
    dayLevelTotalTax,
    dayLevelPerNightAmount,
  ]);

  // CHILD Details integration
  const [childDataDetails, setChildDataDetails] = useState([]);
  //Passing the drawer New Guest Information to formik
  const addChildData = (data) => {
    let tempArr = [...childDataDetails];
    tempArr.push(data);
    setChildDataDetails(tempArr);
  };

  //Updation already existing guestData
  const updateChildData = (data, index) => {
    // Updating  guest state
    const updatedArr = childDataDetails.map((childData, ind) => {
      if (index == ind) {
        const updatedState = data;
        return updatedState;
      } else {
        return childData;
      }
    });
    setChildDataDetails(updatedArr);
  };

  const addChildDetail = (anchor, index) => {
    return (
      <Box
        sx={{ width: "100%" }}
        // role="presentation"
      >
        <div className="folioUpdateWrapper">
          <div className="folioInformationActionWrapper">
            <div className="folioInformation">
              <div className="fId">{`${t("Add child")} ${index + 1} ${t(
                "details"
              )}`}</div>
            </div>
            <div
              className="closeToggleDrawer"
              onClick={toggleAddChildDrawer("right", false, index)}
            >
              <AiIcons.AiOutlineClose size="20px" color="black" />
            </div>
          </div>
          <Divider />

          {childDataDetails?.length > index ? (
            <NewChildView
              onClose={toggleAddChildDrawer(anchor, false, index)}
              existingChildDetails={childDataDetails}
              addChildDetail={addChildData}
              updateChildDetail={updateChildData}
              dataIndex={index}
              selectedLanguage={props.selectedLanguage}
            />
          ) : (
            <NewChildView
              onClose={toggleAddChildDrawer(anchor, false, index)}
              existingChildDetails={childDataDetails}
              addChildDetail={addChildData}
              updateChildDetail={updateChildData}
              dataIndex={index}
              selectedLanguage={props.selectedLanguage}
            />
          )}
        </div>
      </Box>
    );
  };
  useEffect(() => {
    if (formik?.values?.priceAfterTax && counterss === 0) {
      setpriceAfterTaxes(formik?.values?.priceAfterTax);
      setcounterss(1);
    }
  }, [formik?.values?.priceAfterTax]);

  useEffect(() => {
    if (formik?.values?.priceBeforeTax && counters === 0) {
      setpriceBeforeTaxesdata(formik?.values?.priceBeforeTax);
      setcounters(1);
    }
  }, [formik?.values?.priceBeforeTax]);

  const applyingPromo = async () => {
    setPromoValidation("");
    const selectedData = copyPromocodeData?.filter(
      (e) => e.promoCode === promoValue
    );
    setselectedPromoCodeData(selectedData);
    const payload = {
      hotelId: hotelId,
      promoCode: promoValue,
      checkin: moment(formik.values.booking_start).format(
        "DD-MM-YYYY HH:00:00"
      ),
      checkout: moment(formik.values.booking_end).format("DD-MM-YYYY HH:00:00"),
      numRooms: 1,
      amountBeforeTax: formik?.values?.priceBeforeTax,
      taxAmount: formik.values.taxValue,
      percentage: selectedData[0]?.discount,
      roomsRequested: [
        {
          roomTypeId: selectedRoomTypeId,
          ratePlanId: formik.values.rate_plan,
          adultChildList: [
            {
              numAdults: formik.values.guests.Adults,
              numChildren: formik.values.guests.Children,
            },
          ],
          dayLevelPriceList: promoPricingList,
        },
      ],
    };
    setapiLoader(true);
    const data = await checkPromoCodeQuickBook(payload);
    setPromoValidation(data);

    if (data.validPromo === true) {
      setshowPromoButton(false);
      // let perNightPrice = data?.amountAfterDiscount;
      // formik.setFieldValue("priceBeforeTax", perNightPrice);

      setdiscountvalue(data.discountValue);
      // setTimeout(() => {
      //   if (perNightPrice !== "" && perNightPrice !== null) {
      //     pricingRequestModel.newRate = perNightPrice;
      //     pricingRequestModel.newRateType = "TOTAL_PRETAX";
      //     fetchUpdatedBookingPricing("priceBeforeTax");
      //   }
      // }, 2000);
    }
    setapiLoader(false);
  };

  const removePromo = () => {
    formik.setFieldValue("priceAfterTax", Number(priceAfterTaxes).toFixed(2));
    // setTimeout(() => {
    //   if (priceAfterTaxes !== "" && priceAfterTaxes !== null) {
    //     pricingRequestModel.newRate = priceAfterTaxes;
    //     pricingRequestModel.newRateType = "TOTAL_WITHTAX";
    //     fetchUpdatedBookingPricing("priceAfterTax");
    //   }
    // }, 2000);
    setshowPromoButton(true);
    setPromoValidation("");
    setpromoValue("");
  };
  const [copyPromocodeData, setcopyPromocodeData] = useState([]);
  const getPromoAllData = async () => {
    try {
      const data = await getAllPromocodes();
      const mydata = JSON.parse(data?.message);
      setcopyPromocodeData(mydata?.filter((e) => e.activeStatus === "ACTIVE"));
      const filterData = mydata?.filter(
        (e) => e.activeStatus === "ACTIVE" && e.promoCodeType === "PROMOCODE"
      );
      setPromocodeData(filterData);
    } catch (e) {
      console.error(e);
    }
  };

  const [hotelAddons, sethotelAddons] = useState([]);
  const [selectedAddons, setselectedAddons] = useState([]);

  useEffect(async () => {
    const data = await getHotelAddons();
    const selectedAddons = data.filter(function (el) {
      return el.room_type_id.includes(selectedRoomTypeId);
    });
    var todaysDate = moment(new Date()).unix();

    const arr = [];
    const datsasa = selectedAddons?.map((e, index) => {
      var startDateUnix = moment(
        selectedAddons[index].start_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      var endDateUnix = moment(
        selectedAddons[index].end_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      if (todaysDate >= startDateUnix && todaysDate <= endDateUnix) {
        arr.push(e);
      }
    });

    sethotelAddons(arr);
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setselectedAddons(typeof value === "string" ? value.split(",") : value);
  };

  const [selectedAddonsArray, setselectedAddonsArray] = useState([]);
  const [addonsProductArray, setaddonsProductArray] = useState([]);

  useEffect(() => {
    const arr = [];
    const selectedAddonDastas = [];
    if (selectedAddons.length > 0) {
      for (let i = 0; i < selectedAddons.length; i++) {
        const data = hotelAddons.filter((e) => e.id === selectedAddons[i]);
        arr.push(data[0]);
        const obj = {
          id: data[0].id,
          name: data[0]?.name,
          quantity: 1,
          priceWithoutTax: data[0].addon_value,
          taxPercentage: data[0].tax_percentage,
          priceWithTax:
            data[0].addon_value +
            data[0].addon_value * (data[0].tax_percentage / 100),
          description: data[0].description,
        };
        selectedAddonDastas.push(obj);
      }
    }

    setselectedAddonsArray(arr);
    setaddonsProductArray(selectedAddonDastas);
  }, [selectedAddons]);

  const columns = [
    {
      dataField: "name",
      text: `${t("Name")}`,
      editable: false,
    },
    {
      dataField: "quantity",
      text: `${t("Quantity")}`,
    },
    {
      dataField: "priceWithoutTax",
      text: `${t("Price without tax")}`,
    },
    {
      dataField: "taxPercentage",
      text: `${t("Tax percentage")}`,
      editable: false,
    },
    {
      dataField: "priceWithTax",
      text: `${t("Price with tax")}`,
      editable: false,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: `${t("Price should be numeric")}`,
          };
        }
        return true;
      },
    },
  ];

  const getUserDefinedSources = () => {
    handleSpringDataRequest(`core/api/v1/user-defined-source/get-all-sources`)
      .then((res) => {
        setUserDefinedSources(res);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    getUserDefinedSources();
  }, []);

  useEffect(() => {
    const mp = {
      DIRECT: [
        `${t("WALK-IN")}`,
        `${t("PHONE CALL")}`,
        `${t("EMAIL")}`,
        `${t("WHATSAPP")}`,
        `${t("SOCIAL MEDIA")}`,
      ],
      METASEARCH: [
        `${t("GOOGLE")}`,
        `${t("TRIPADVISOR")}`,
        `${t("TRIVAGO")}`,
        `${t("KAYAK")}`,
      ],
      DIRECT_BILLING: ctas,
    };
    setMapsss(mp);
  }, [ctas, formik.values.booking_start, formik.values.booking_end]);

  useEffect(() => {
    if (mapsss && majorSources && userDefinedSources) {
      let tempser = majorSources;
      let temparr = [];
      const data = Object.keys(mapsss).map((res) => {
        temparr.push(res);
      });

      const datacs = tempser.map((res) => {
        temparr.push(res);
      });

      if (
        JSON.parse(hotel?.accessControl?.user_feature_map?.ShowOtaSegment)
          .read === "YES"
      ) {
        setMostMajorSource(temparr);
      } else {
        setMostMajorSource(temparr.filter((item) => item !== "OTA"));
      }

      setSegment(temparr[0]);
      let tempusersrs = userDefinedSources;
      let newarr = [];
      const dattsa = Object.entries(mapsss).map(([key, val]) => {
        newarr[key] = val;
      });
      const dattsass = Object.entries(tempusersrs).map(([key, val]) => {
        newarr[key] = val;
      });
      setMostSecondarySource(newarr);
    }
  }, [mapsss, hotel?.accessControl?.user_feature_map?.HideOtaSegment]);

  useEffect(() => {
    if (userDefinedSources !== null) {
      let srs = Object.keys(userDefinedSources);
      setMajorSources(srs);
      setSegment(srs[0]);
    }
  }, [userDefinedSources]);

  const handleSegmentChange = (e) => {
    setSegment(e.target.value);
  };

  useEffect(() => {
    if (segment !== null && mostSecondarySource !== null) {
      const arr = mostSecondarySource[segment];
      setSubSegmentArr(arr);
      if (typeof arr[0] === "object") setSubSegment(arr[0].cta_name);
      else setSubSegment(arr[0]);
    }
  }, [segment, mostSecondarySource]);

  const handleSubSegmentChange = (e) => {
    setSubSegment(e.target.value);
  };

  useEffect(() => {
    if (ctas !== null) {
      let val = null;
      const data = ctas.map((res) => {
        if (subSegment === res.cta_name) val = res;
      });
      if (val !== null) {
        setSelectedCta(val);
        pricingRequestModel.ctaId = val.cta_id;
        pricingRequestModel.resetParams();
        fetchUpdatedBookingPricing();
      }
    }
  }, [subSegment]);

  //payments
  const loggedUserEmail = localStorage.getItem("logedUserEmail");
  const handleCashPayment = (bookingIds) => {
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordCashPayment/`,
        {
          hotelId: hotelId,
          bookingID: bookingIds,
          amount: parseFloat(paymentAmount),
          offline_card_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          service_amount_desc:
            remark !== "" ? remark : "CASH PAYMENT FROM QUICK BOOKING MODAL",
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleOfflineCardPayment = (bookingIds) => {
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCardPayment/`,
        {
          hotelId: hotelId,
          bookingID: bookingIds,
          amount: parseFloat(paymentAmount),
          offline_card_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          service_amount_desc:
            remark !== ""
              ? remark
              : "OFFLINE CARD PAYMENT FROM QUICK BOOKING MODAL",
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleUpiPayment = (bookingIds) => {
    axios
      .post(`${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`, {
        hotelId: hotelId,
        bookingID: bookingIds,
        amount: parseFloat(paymentAmount),
        offline_upi_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        service_amount_desc:
          remark !== "" ? remark : "UPI PAYMENT FROM QUICK BOOKING MODAL",
        logged_user_email: loggedUserEmail,
      })
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleOtherModePayment = (bookingIds) => {
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotelId,
          bookingID: bookingIds,
          amount: parseFloat(paymentAmount),
          other_payment_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          service_amount_desc:
            remark !== "" ? remark : "OTHER PAYMENT FROM QUICK BOOKING MODAL",
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleBankTransferPayment = (bookingIds) => {
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordBankTransferPayment/`,
        {
          hotelId: hotelId,
          bookingID: bookingIds,
          reservation_id: "",
          amount: parseFloat(paymentAmount),
          bank_transfer_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          service_amount_desc:
            remark !== ""
              ? remark
              : "BANK TRANSFER PAYMENT FROM QUICK BOOKING MODAL",
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => console.log())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    setpaymentAmount(0);
    let amount = 0;
    if (addonsProductArray?.length > 0) {
      for (let i = 0; i < addonsProductArray?.length; i++) {
        amount = amount + addonsProductArray[i].priceWithTax;
      }
    }
    if (code !== "discount") {
      setpaymentAmount(Number(formik.values.priceAfterTax) + Number(amount));
    } else {
      if (dicountType === "percent") {
        if (hotel.hotelCurrency === "INR") {
          let value =
            Number(formik.values.priceBeforeTax) -
            Number(formik.values.priceBeforeTax) *
              (Number(discountAmount) / 100) +
            Number(amount);
          let newValue;
          if (value >= 0 && value <= 7500) {
            newValue = value + value * 0.12;
          } else {
            newValue = value + value * 0.18;
          }
          setpaymentAmount(newValue);
        } else {
          setpaymentAmount(
            Number(formik.values.priceBeforeTax) -
              Number(formik.values.priceBeforeTax) *
                (Number(discountAmount) / 100) +
              Number(amount)
          );
        }
      } else if (dicountType === "flat") {
        if (hotel.hotelCurrency === "INR") {
          let value =
            Number(formik.values.priceBeforeTax) - Number(discountAmount);

          let newValue;
          if (value >= 0 && value <= 7500) {
            newValue = value + value * 0.12;
          } else {
            newValue = value + value * 0.18;
          }
          setpaymentAmount(newValue);
        } else {
          setpaymentAmount(
            Number(formik.values.priceBeforeTax) -
              Number(formik.values.priceBeforeTax) -
              Number(discountAmount) +
              Number(amount)
          );
        }
      }
    }
  }, [formik.values.priceAfterTax, addonsProductArray, discountAmount, code]);

  //Add attachments
  const [document1, setDocument1] = useState("");
  const [document2, setDocument2] = useState("");
  const handleSetDocumentState = (name, docType, base64) => {
    switch (name) {
      case "document1":
        setDocument1({ data: base64, type: docType });
        break;
      case "document2":
        setDocument2({ data: base64, type: docType });
        break;
      default:
      // code block
    }
  };

  //Converting documents to base64
  const convertToBase64 = (eve) => {
    const file = eve.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
      .then((data) => {
        let type = data.split("/")[1].split(";")[0];
        if (type === "pdf") {
          type = "application/pdf";
        } else {
          type = "image/" + type;
        }
        const base64 = data.split(",")[1];
        handleSetDocumentState(eve.target.name, type, base64);
      })
      .catch((err) => console.log("error caught : ", err));
  };
  useEffect(() => {
    setcheckinEpoch(moment(formik.values.booking_start).unix());
    setcheckoutEpoch(moment(formik.values.booking_end).unix());
  }, [formik.values.booking_start, formik.values.booking_end]);

  const applyDiscount = () => {
    if (
      (dicountType === "percent" && Number(discountAmount) > 100) ||
      (dicountType === "percent" && Number(discountAmount) < 0)
    ) {
      toast.error(`${t("Please enter value in between 0-100")}`);
    } else if (
      (dicountType === "flat" &&
        Number(discountAmount) >
          parseFloat(formik.values.priceBeforeTax).toFixed(2)) ||
      (dicountType === "flat" && Number(discountAmount) < 0)
    ) {
      toast.error(`${t("Please enter correct discount value")}`);
    } else {
      if (dicountType === "percent") {
        setdiscountvalue(
          parseFloat(formik.values.priceBeforeTax) -
            (formik.values.priceBeforeTax -
              formik.values.priceBeforeTax * (discountAmount / 100))
        );
      } else {
        setdiscountvalue(
          parseFloat(formik.values.priceBeforeTax) -
            (parseFloat(formik.values.priceBeforeTax) -
              parseFloat(discountAmount))
        );
      }
      setshowDiscountButton(false);
      setdiscountApplied("true");
    }
  };

  const removeDiscount = () => {
    // formik.setFieldValue("priceAfterTax", Number(priceAfterTaxes).toFixed(2));
    // setTimeout(() => {
    //   if (priceAfterTaxes !== "" && priceAfterTaxes !== null) {
    //     pricingRequestModel.newRate = priceAfterTaxes;
    //     pricingRequestModel.newRateType = "TOTAL_WITHTAX";
    //     fetchUpdatedBookingPricing("priceAfterTax");
    //   }
    // }, 2000);
    setdiscountAmount("");
    setshowDiscountButton(true);
    setdiscountApplied("");
  };

  const getCompanyDetails = () => {
    handleSpringDataRequest(`core/api/v1/guest-company-details/get-all`)
      .then((res) => {
        setallCompanyDetails(res);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const hideShowCompanyModal = () => {
    setshowCompanyModal(false);
  };
  const [sameDay, setsameDay] = useState(false);
  useEffect(() => {
    const checkin = moment(formik.values.booking_start).format("YYYY-MM-DD");
    const today = moment(new Date()).format("YYYY-MM-DD");

    if (checkin === today) {
      setsameDay(true);
    } else {
      setsameDay(false);
    }
  }, [formik.values.booking_start]);

  const [showCreateBooking, setshowCreateBooking] = useState(false);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/get-night-audit-flag?currentDate=${moment(
        formik.values.booking_start
      )
        .subtract(1, "days")
        .format("DD-MM-YYYY")}&opType=WALK_IN`
    )
      .then((res) => {
        if (
          hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
          hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN"
        ) {
          setshowCreateBooking(true);
        } else {
          setshowCreateBooking(res);
        }
      })
      .catch((err) => console.log(err));
  }, [formik.values.booking_start]);
  const [showEnquirySection, setshowEnquirySection] = useState(false);
  const [showAddons, setshowAddons] = useState(false);
  useEffect(() => {
    setshowEnquirySection(
      !(
        moment(formik.values.booking_start).format("YYYY-MM-DD") <
        moment().format("YYYY-MM-DD")
      )
    );
    setshowExpiresin(false);
  }, [formik.values.booking_start]);

  ///Midninght of the day----------------
  const todayIs = new Date();
  todayIs.setHours(0, 0, 0, 0);
  const rowStyle = {
    fontSize: "14px", // Adjust the font size of rows as needed
  };

  const headerStyle = {
    fontSize: "16px", // Adjust the font size of header columns as needed
  };

  const handleDobKeyDown = (event) => {
    if (event.key !== "Tab") {
      event.preventDefault();
    }
  };

  const [noOfNights, setnoOfNights] = useState(0);
  useEffect(() => {
    const date1 = moment(formik.values.booking_start).startOf("day");
    const date2 = moment(formik.values.booking_end).startOf("day");

    // Calculate the difference in days
    const differenceInDays = date2.diff(date1, "days");

    setnoOfNights(differenceInDays);
  }, [formik.values.booking_start, formik.values.booking_end]);

  const [showButtonOfPricing, setshowButtonOfPricing] = useState("");

  useEffect(() => {
    if (cameraUpload) {
      setimageUpload("Camera");
    } else {
      setimageUpload("Device");
    }
  }, [cameraUpload]);
  const scanDocumtApi = () => {
    if (
      formik.values.govtIdType === "" ||
      formik.values.govtIdType === null ||
      formik.values.govtIdType === undefined
    ) {
      return toast.error("Please select the document type");
    }

    if (formik.values.govtIdType !== "Drivers License") {
      return toast.error(
        "Please select the document type as Drivers License for scanning"
      );
    }

    if (formik.values.govtIdType)
      if (imageUpload === "Device") {
        if (images.length <= 0) {
          return toast.error("Please upload the front image");
        }
        setScannerloader(true);
        handleSpringDataMutationRequest(
          "POST",
          `core/api/v1/document-ai/fetch-id-info-base64`,
          {
            imageType: "image/png",
            docType: formik.values.govtIdType,
            base64Image: images[0]?.data_url.split(",")[1],
          }
        )
          .then((res) => {
            if (res?.isValid === false || res?.isValid === "false") {
              toast.error("Not a valid id");
              setScannerloader(false);
            } else {
              updateScanInfo(res);
              setScannerloader(false);
            }
          })
          .catch((err) => {
            toast.error("Error in scanning the document");
            setScannerloader(false);
          });
      } else {
        if (image === undefined || image === null || image === "") {
          return toast.error("Please upload the front image");
        }
        setScannerloader(true);
        handleSpringDataMutationRequest(
          "POST",
          `core/api/v1/document-ai/fetch-id-info-base64`,
          {
            imageType: "image/png",
            docType: formik.values.govtIdType,
            base64Image: image.split(",")[1],
          }
        )
          .then((res) => {
            if (res?.isValid === false || res?.isValid === "false") {
              toast.error("Not a valid id");
              setScannerloader(false);
            } else {
              updateScanInfo(res);
              setScannerloader(false);
            }
          })
          .catch((err) => {
            toast.error("Error in scanning the document");
            setScannerloader(false);
          });
      }
  };

  const updateScanInfo = (res) => {
    formik.setFieldValue(
      "customer_name",
      (res?.firstName + " " + res?.middleName + " " + res?.lastName).trim()
    );
    formik.setFieldValue("state", res?.addressDetail?.state);
    formik.setFieldValue("zipcode", res?.addressDetail?.zipcode);
    formik.setFieldValue("city", res?.addressDetail?.city);

    formik.setFieldValue("address1", res?.addressDetail?.addressLine1);
    formik.setFieldValue("address2", res?.addressDetail?.addressLine2);
    formik.setFieldValue("govtIdValue", res?.documentId);
    formik.setFieldValue("country", res?.addressDetail?.country);
  };

  return (
    <div className={classes.root}>
      <ModalCommonSlider open={open} onClose={handleAddEventSidebar}>
        <Grid container sx={{ width: "100%" }}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              padding: "15px",
            }}
          >
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <Typography variant="h5" style={{ marginLeft: "0.5rem" }}>
                  {`You are booking `}
                </Typography>

                <FormControl
                  fullWidth
                  sx={{
                    width: "10rem",
                    marginLeft: "1rem",
                    marginRight: "0.7rem",
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRoomTypeId}
                    size="small"
                    variant="standard"
                    onChange={(e) => getPricesForRoomType(e.target.value)}
                    notched={false}
                    // label={t("Room type")}
                  >
                    {roomTypesMap.length > 0 &&
                      roomTypesMap?.map((name, index) => {
                        return <MenuItem value={name[0]}>{name[1]}</MenuItem>;
                      })}
                    {roomTypesMap.length === 0 && (
                      <MenuItem
                        value={eventData.resource.extendedProps.roomTypeId}
                      >
                        {eventData.resource.extendedProps.roomType}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>

                <Typography variant="h5" style={{ marginLeft: "0.5rem" }}>
                  {`room no. ${eventData.resource.id} for ${noOfNights} ${
                    noOfNights < 2 ? "night" : "nights"
                  }`}
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {(hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
                (hotelemail !== "reservation@maharashtratourism.gov.in" &&
                  JSON.parse(
                    hotel?.accessControl?.user_feature_map?.HideBlockRooms
                  ).read !== "YES")) && (
                <>
                  <p
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#1BC5BD",
                      fontSize: "15px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                      marginRight: "2rem",
                      float: "right",
                    }}
                    onClick={() => {
                      if (
                        eventData.start < todayIs ||
                        eventData.end < todayIs
                      ) {
                        toast.error(
                          `${t("Rooms cannot be blocked for past dates.")}`
                        );
                        return;
                      }
                      setOpenBlock(true);
                    }}
                  >
                    {t("Are you looking for block room?")}
                  </p>
                </>
              )}
              <IconButton
                sx={{ float: "right" }}
                onClick={handleAddEventSidebar}
              >
                <Close />
              </IconButton>
            </div>
          </div>

          {loadingData && (
            <Grid
              sx={{ width: "100%", height: "max-content" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <DimmingDotsLoader />
            </Grid>
          )}
          <Divider />
          {!loadingData && !showError && (
            <form
              noValidate
              style={{ height: "auto" }}
              onSubmit={formik.handleSubmit}
            >
              {" "}
              <div className="d-flex w-100">
                <div className="d-flex flex-column w-100">
                  <Accordion
                    expanded={true}
                    sx={{
                      "&.Mui-expanded": {
                        margin: "0px",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1c-content1"
                      id="panel1c-header1"
                    >
                      <div className={classes.column}>
                        <Typography className={classes.heading}>
                          {formik.errors.booking_end ||
                          formik.errors.booking_start ? (
                            <Error
                              className={classes.summaryInner}
                              color="error"
                            />
                          ) : (
                            <CheckCircle
                              className={classes.summaryInner}
                              color="primary"
                            />
                          )}
                          {t("Check-in")}

                          <HorizontalRule
                            sx={{ ml: 1, mr: 1 }}
                            htmlColor="silver"
                          />
                          {t("Check-out")}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container spacing={2}>
                          <Grid item xs={8} sm={8} md={6} lg={4} xl={4}>
                            <div>
                              <DatePicker
                                inputFormat={dateFnsFormat}
                                fullWidth
                                disableMaskedInput
                                mask="___ ___, ____"
                                id="booking_start"
                                name="booking_start"
                                className={classes.textField}
                                size="small"
                                value={formik.values.booking_start}
                                label={t("Check-in")}
                                onChange={checkDateSelectionCheckin}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => {
                                  params.error = Boolean(
                                    formik.errors.booking_start
                                  );
                                  return (
                                    <TextField
                                      required
                                      size="small"
                                      helperText={formik.errors.booking_start}
                                      fullWidth
                                      {...params}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={6} lg={2} xl={2}>
                            <TextField
                              select
                              size="small"
                              value={selectedCheckinSlot}
                              onChange={(e) => {
                                const temp = formik.values.booking_start;
                                temp.setHours(
                                  e.target.value === 24 ? 0 : e.target.value
                                );
                                setSelectedCheckinSlot(e.target.value);
                                checkDateSelectionCheckin(
                                  temp,
                                  true,
                                  "setdate"
                                );
                              }}
                              label={t("Check-in time")}
                              fullWidth
                              disabled={checkinSlots.length === 1}
                            >
                              {checkinSlots.map((slot) => (
                                <MenuItem
                                  key={slot}
                                  value={slot}
                                  disabled={slot === 24}
                                >
                                  {`${slot > 12 ? slot - 12 : slot} ${
                                    (slot - 12) / 12 >= 0 &&
                                    (slot - 12) / 12 !== 1
                                      ? "PM"
                                      : "AM"
                                  }`}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={8} sm={8} md={6} lg={4} xl={4}>
                            <div>
                              <DatePicker
                                fullWidth
                                id="booking_end"
                                mask="____ ___, ____"
                                disableMaskedInput
                                name="booking_end"
                                className={classes.textField}
                                size="small"
                                minDate={formik.values.booking_start}
                                placeholder="Booking End Date"
                                value={formik.values.booking_end}
                                label={t("Checkout")}
                                variant="inline"
                                autoOk={true}
                                inputFormat={dateFnsFormat}
                                onChange={checkDateSelectionCheckout}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => {
                                  params.error = Boolean(
                                    formik.errors.booking_end
                                  );
                                  return (
                                    <TextField
                                      required
                                      size="small"
                                      helperText={formik.errors.booking_end}
                                      fullWidth
                                      {...params}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={6} lg={2} xl={2}>
                            <TextField
                              select
                              size="small"
                              value={selectedCheckoutSlot}
                              onChange={(e) => {
                                const temp = formik.values.booking_end;
                                temp.setHours(
                                  e.target.value === 24 ? 0 : e.target.value
                                );
                                setSelectedCheckoutSlot(e.target.value);
                                checkDateSelectionCheckout(temp, true);
                              }}
                              label={t("Check-out time")}
                              fullWidth
                              disabled={checkoutSlots.length === 1}
                            >
                              {checkoutSlots.map((slot) => (
                                <MenuItem
                                  key={slot}
                                  value={slot}
                                  disabled={slot === 24}
                                >
                                  {`${slot > 12 ? slot - 12 : slot} ${
                                    (slot - 12) / 12 >= 0 &&
                                    (slot - 12) / 12 !== 1
                                      ? "PM"
                                      : "AM"
                                  }`}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                      <Divider style={{ marginTop: "1rem" }} />

                      <div
                        className={classes.column}
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      >
                        <Typography className={classes.heading}>
                          <CheckCircle
                            className={classes.summaryInner}
                            color="primary"
                          />

                          {t(`Guest count`)}
                        </Typography>
                      </div>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          justifyContent={"space-between"}
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                        >
                          <Grid item style={{ paddingLeft: "8px" }}>
                            <ListItemText
                              primary={t("Adults")}
                              secondary={`${t("Max occupancy : ")} ${
                                roomInformation?.maxPossibleOccupancy
                              }`}
                            ></ListItemText>
                          </Grid>
                          <Grid item style={{ height: "fit-content" }}>
                            <ButtonGroup variant="text">
                              <Button
                                sx={{ border: "none !important" }}
                                onClick={() => {
                                  let count = formik.values.guests.Adults;
                                  count =
                                    // count <= roomInformation.defaultOccupancy
                                    //   ? count - 1
                                    //   : count;
                                    count <= 1 ? 1 : count - 1;
                                  pricingRequestModel.numAdults = count;

                                  if (formik.values.guests.Adults !== count) {
                                    pricingRequestModel.resetParams();
                                    fetchUpdatedBookingPricing();
                                  }
                                  formik.setFieldValue("guests.Adults", count);
                                  if (count == 1) {
                                    //that means single adult so clear all other guest details
                                    setOtherGuestDetails([]);
                                  } else if (
                                    otherGuestDetails?.length == count
                                  ) {
                                    //that means the last added is an extra guest hence delete that guest detail
                                    const otherGuestTemp = [
                                      ...otherGuestDetails,
                                    ];
                                    otherGuestTemp.pop();
                                    setOtherGuestDetails(otherGuestTemp);
                                  }
                                  if (count == 1) {
                                    //that means single adult so clear all other guest details
                                    setOtherGuestIdProofs([]);
                                  } else if (
                                    otherGuestIdProofs?.length == count
                                  ) {
                                    //that means the last added is an extra guest hence delete that guest detail
                                    const otherGuestTemp = [
                                      ...otherGuestIdProofs,
                                    ];
                                    otherGuestTemp.pop();
                                    setOtherGuestIdProofs(otherGuestTemp);
                                  }
                                  if (count == 1) {
                                    //that means single adult so clear all other guest details
                                    setOtherGuestDocuments([]);
                                  } else if (
                                    otherGuestDocuments?.length == count
                                  ) {
                                    //that means the last added is an extra guest hence delete that guest detail
                                    const otherGuestTemp = [
                                      ...otherGuestDocuments,
                                    ];
                                    otherGuestTemp.pop();
                                    setOtherGuestDocuments(otherGuestTemp);
                                  }
                                }}
                                disabled={apiLoader}
                                color="primary"
                              >
                                <Remove />
                              </Button>
                              <Button
                                sx={{ border: "none !important" }}
                                disabled
                              >
                                {formik.values.guests.Adults}
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  let count = formik.values.guests.Adults;
                                  let countChild =
                                    formik.values.guests.Children;
                                  count =
                                    count + countChild >=
                                      roomInformation.maxPossibleOccupancy ||
                                    count >= roomInformation.maxOccupancy
                                      ? count
                                      : count + 1;
                                  pricingRequestModel.numAdults = count;
                                  if (formik.values.guests.Adults !== count) {
                                    pricingRequestModel.resetParams();
                                    fetchUpdatedBookingPricing();
                                  }
                                  formik.setFieldValue("guests.Adults", count);
                                  let tempArr = [...addGuestState];
                                  tempArr.push({ right: false });
                                  setAddGuestState(tempArr);
                                }}
                                disabled={apiLoader}
                              >
                                <Add />
                              </Button>
                            </ButtonGroup>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent={"space-between"}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                        >
                          <Grid item>
                            <ListItemText
                              primary={t("Children")}
                              secondary={`${t("Max children : ")} ${
                                roomInformation.maxChildren
                              }`}
                            ></ListItemText>
                          </Grid>
                          <Grid item style={{ height: "fit-content" }}>
                            <ButtonGroup variant="text">
                              <Button
                                sx={{ border: "none !important" }}
                                onClick={() => {
                                  let count = formik.values.guests.Children;
                                  count = count <= 0 ? 0 : count - 1;
                                  pricingRequestModel.numChildren = count;
                                  if (formik.values.guests.Children !== count) {
                                    pricingRequestModel.resetParams();
                                    fetchUpdatedBookingPricing();
                                  }
                                  formik.setFieldValue(
                                    "guests.Children",
                                    count
                                  );
                                  if (count === 0) {
                                    setChildDataDetails([]);
                                  } else if (
                                    childDataDetails?.length == count
                                  ) {
                                    //that means the last added is an extra guest hence delete that guest detail
                                    const otherGuestTemp = [
                                      ...childDataDetails,
                                    ];
                                    childDataDetails.pop();
                                    setChildDataDetails(otherGuestTemp);
                                  }
                                }}
                                disabled={apiLoader}
                                color="primary"
                              >
                                <Remove />
                              </Button>
                              <Button
                                sx={{ border: "none !important" }}
                                disabled
                              >
                                {formik.values.guests.Children}
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  let count = formik.values.guests.Children;
                                  let countAdults = formik.values.guests.Adults;
                                  count =
                                    count + countAdults >=
                                      roomInformation.maxPossibleOccupancy ||
                                    count >= roomInformation.maxChildren
                                      ? count
                                      : count + 1;
                                  pricingRequestModel.numChildren = count;
                                  if (formik.values.guests.Children !== count) {
                                    pricingRequestModel.resetParams();
                                    fetchUpdatedBookingPricing();
                                  }
                                  formik.setFieldValue(
                                    "guests.Children",
                                    count
                                  );
                                  let tempArr = [...addChildState];
                                  tempArr.push({ right: false });
                                  setAddChildState(tempArr);
                                }}
                                disabled={apiLoader}
                              >
                                <Add />
                              </Button>
                            </ButtonGroup>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <>
                            {" "}
                            <div
                              className={classes.column}
                              style={{
                                marginBottom: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <Typography className={classes.heading}>
                                {formik.values.customer_name === "" ||
                                // formik.values.customer_phone === "" ||
                                // formik.errors.customer_email ||
                                // formik.errors.customer_phone ||
                                formik.errors.customer_name ? (
                                  <Error
                                    className={classes.summaryInner}
                                    color="error"
                                  />
                                ) : (
                                  <CheckCircle
                                    className={classes.summaryInner}
                                    color="primary"
                                  />
                                )}
                                {t(`Guest details`)}
                                {hotel?.hotelCurrency === "USD" && (
                                  <>
                                    {hotel?.accessControl?.hotel
                                      ?.enable_id_scanner === "AMBIRSCAN" && (
                                      <Button
                                        variant="custom-button"
                                        style={{
                                          // marginTop: "5.3%",
                                          marginLeft: "2rem",
                                          marginRight: "1rem",
                                        }}
                                        disabled={Scannerloader}
                                        onClick={ScannedData}
                                      >
                                        {Scannerloader ? (
                                          <CircularProgress
                                            size={15}
                                            color="inherit"
                                            sx={{ ml: 1.5 }}
                                          />
                                        ) : (
                                          `${t("Scan id")}`
                                        )}
                                      </Button>
                                    )}

                                    {hotel?.accessControl?.hotel
                                      ?.enable_id_scanner === "IDGURU" && (
                                      <Button
                                        variant="custom-button"
                                        onClick={handleOpenIdGuruModal}
                                        style={{
                                          marginLeft: "2rem",
                                          marginRight: "1rem",
                                        }}
                                      >
                                        ID Guru scanner
                                      </Button>
                                    )}
                                    {hotel?.accessControl?.hotel
                                      ?.enable_id_scanner === "DOCUMENTAI" && (
                                      <Button
                                        variant="custom-button"
                                        onClick={scanDocumtApi}
                                        style={{
                                          marginLeft: "2rem",
                                          marginRight: "1rem",
                                        }}
                                        disabled={Scannerloader}
                                      >
                                        {Scannerloader ? (
                                          <CircularProgress
                                            size={15}
                                            color="inherit"
                                            sx={{ ml: 1.5 }}
                                          />
                                        ) : (
                                          `${t("Scan id")}`
                                        )}
                                      </Button>
                                    )}
                                  </>
                                )}
                              </Typography>
                            </div>
                          </>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Stack position="relative">
                            <div>
                              <TextField
                                className="position-relative"
                                size="small"
                                name="customer_name"
                                id="customer_name"
                                fullWidth
                                autoComplete="new-password"
                                placeholder={t("Full name")}
                                value={formik.values.customer_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.customer_name &&
                                  Boolean(formik.errors.customer_name)
                                }
                                helperText={
                                  formik.touched.customer_name &&
                                  formik.errors.customer_name
                                }
                                label={t("Customer name")}
                                variant="outlined"
                                required
                                InputLabelProps={{ shrink: true }}
                              />
                            </div>
                            {customerNameData?.length > 0 && showData && (
                              <div
                                className="position-absolute"
                                style={{
                                  top: "4.5rem",
                                  overflowY: "scroll",
                                  height: "20rem",
                                }}
                              >
                                <Card
                                  style={{
                                    width: "36rem",
                                    height: "17%",
                                    overflowY: "yes",
                                    zIndex: "1000",
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "1rem",
                                    }}
                                    color="primary"
                                  >
                                    <CancelIcon onClick={() => emptyValues()} />
                                  </IconButton>
                                </Card>

                                {customerNameData?.length > 0 &&
                                  showData &&
                                  customerNameData?.map((item, index) => (
                                    <Card
                                      style={{
                                        width: "36rem",
                                        height: "32%",
                                        overflowY: "yes",
                                        zIndex: "1000",
                                      }}
                                      onClick={() =>
                                        populateCustomerFields(item)
                                      }
                                    >
                                      <Card.Body style={{ padding: "3%" }}>
                                        <div className="d-flex flex-column">
                                          {/* <BsSearch className="mb-0 mr-2" /> */}
                                          <div className="d-flex ">
                                            <h4 className="mb-0 mr-4">
                                              {item.name}
                                            </h4>
                                            {item.donotRent === "YES" && (
                                              <>
                                                <p className="badge badge-danger">
                                                  {t("Do not rent")}
                                                </p>
                                                {item?.severity === "LOW" && (
                                                  <p className="badge badge-info ml-2">
                                                    {t("LOW")}
                                                  </p>
                                                )}
                                                {item?.severity ===
                                                  "MEDIUM" && (
                                                  <p className="badge badge-warning ml-2">
                                                    {t("MEDIUM")}
                                                  </p>
                                                )}
                                                {item?.severity === "HIGH" && (
                                                  <p className="badge badge-dark ml-2">
                                                    {t("HIGH")}
                                                  </p>
                                                )}
                                                {item?.severity ===
                                                  "BLACKLIST" && (
                                                  <p className="badge badge-danger ml-2">
                                                    {t("BLACKLIST")}
                                                  </p>
                                                )}
                                              </>
                                            )}
                                          </div>
                                          <div className="d-flex mb-0">
                                            <p
                                              className="mr-3"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {item.email}
                                            </p>
                                            <p style={{ fontSize: "13px" }}>
                                              {item.contact}
                                            </p>
                                          </div>
                                          <p className="mb-0">{item.notes}</p>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  ))}
                              </div>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Stack position="relative">
                            <TextField
                              name="customer_email"
                              id="customer_email"
                              size="small"
                              fullWidth
                              autoComplete="new-password"
                              type="text"
                              placeholder={t("Email address")}
                              value={formik.values.customer_email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              label={t("Customer email")}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              error={
                                formik.values.customer_email?.length > 0 &&
                                !validateEmail(formik.values.customer_email)
                              }
                              helperText={
                                formik.values.customer_email?.length > 0 &&
                                !validateEmail(formik.values.customer_email)
                                  ? "Please enter valid email"
                                  : ""
                              }
                            />
                            {customerEmailData?.length > 0 && showData && (
                              <div
                                className="position-absolute"
                                style={{
                                  top: "4.1rem",
                                  overflowY: "scroll",
                                  height: "20rem",
                                }}
                              >
                                <Card
                                  style={{
                                    width: "36rem",
                                    height: "17%",
                                    overflowY: "yes",
                                    zIndex: "1000",
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "1rem",
                                    }}
                                    color="primary"
                                  >
                                    <CancelIcon onClick={() => emptyValues()} />
                                  </IconButton>
                                </Card>

                                {customerEmailData?.length > 0 &&
                                  showData &&
                                  customerEmailData?.map((item, index) => (
                                    <Card
                                      style={{
                                        width: "36rem",
                                        height: "32%",
                                        overflowY: "yes",
                                        zIndex: "1000",
                                      }}
                                      onClick={() =>
                                        populateCustomerFields(item)
                                      }
                                    >
                                      <Card.Body style={{ padding: "3%" }}>
                                        <div className="d-flex flex-column">
                                          {/* <BsSearch className="mb-0 mr-2" /> */}
                                          <div className="d-flex ">
                                            <h4 className="mb-0 mr-4">
                                              {item.name}
                                            </h4>
                                            {item.donotRent === "YES" && (
                                              <>
                                                <p className="badge badge-danger">
                                                  {t("Do not rent")}
                                                </p>
                                                {item?.severity === "LOW" && (
                                                  <p className="badge badge-info ml-2">
                                                    {t("LOW")}
                                                  </p>
                                                )}
                                                {item?.severity ===
                                                  "MEDIUM" && (
                                                  <p className="badge badge-warning ml-2">
                                                    {t("MEDIUM")}
                                                  </p>
                                                )}
                                                {item?.severity === "HIGH" && (
                                                  <p className="badge badge-dark ml-2">
                                                    {t("HIGH")}
                                                  </p>
                                                )}
                                                {item?.severity ===
                                                  "BLACKLIST" && (
                                                  <p className="badge badge-danger ml-2">
                                                    {t("BLACKLIST")}
                                                  </p>
                                                )}
                                              </>
                                            )}
                                          </div>
                                          <div className="d-flex mb-0">
                                            <p
                                              className="mr-3"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {item.email}
                                            </p>
                                            <p style={{ fontSize: "13px" }}>
                                              {item.contact}
                                            </p>
                                          </div>
                                          <p className="mb-0">{item.notes}</p>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  ))}
                              </div>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Stack position="relative">
                            <TextField
                              name="customer_phone"
                              id="customer_phone"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              autoComplete="new-password"
                              type={"text"}
                              placeholder={t("Phone number")}
                              onBlur={formik.handleBlur}
                              value={formik.values.customer_phone}
                              onChange={formik.handleChange}
                              label={t("Customer phone")}
                              variant="outlined"
                              error={
                                formik.values.customer_phone?.length > 0 &&
                                !validatePhoneNumber(
                                  formik.values.customer_phone
                                )
                              }
                              helperText={
                                formik.values.customer_phone?.length > 0 &&
                                !validatePhoneNumber(
                                  formik.values.customer_phone
                                )
                                  ? "Please enter valid contact"
                                  : ""
                              }
                            />

                            {customerPhoneData?.length > 0 && showData && (
                              <div
                                className="position-absolute"
                                style={{
                                  top: "4.5rem",

                                  overflowY: "scroll",
                                  height: "20rem",
                                }}
                              >
                                <Card
                                  style={{
                                    width: "36rem",
                                    height: "17%",
                                    overflowY: "yes",
                                    zIndex: "1000",
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "1rem",
                                    }}
                                    color="primary"
                                  >
                                    <CancelIcon onClick={() => emptyValues()} />
                                  </IconButton>
                                </Card>

                                {customerPhoneData?.length > 0 &&
                                  showData &&
                                  customerPhoneData?.map((item, index) => (
                                    <Card
                                      style={{
                                        width: "36rem",
                                        height: "32%",
                                        overflowY: "yes",
                                        zIndex: "1000",
                                      }}
                                      onClick={() =>
                                        populateCustomerFields(item)
                                      }
                                    >
                                      <Card.Body style={{ padding: "3%" }}>
                                        <div className="d-flex flex-column">
                                          {/* <BsSearch className="mb-0 mr-2" /> */}
                                          <div className="d-flex ">
                                            <h4 className="mb-0 mr-4">
                                              {item.name}
                                            </h4>
                                            {item.donotRent === "YES" && (
                                              <>
                                                <p className="badge badge-danger">
                                                  {t("Do not rent")}
                                                </p>
                                                {item?.severity === "LOW" && (
                                                  <p className="badge badge-info ml-2">
                                                    {t("LOW")}
                                                  </p>
                                                )}
                                                {item?.severity ===
                                                  "MEDIUM" && (
                                                  <p className="badge badge-warning ml-2">
                                                    {t("MEDIUM")}
                                                  </p>
                                                )}
                                                {item?.severity === "HIGH" && (
                                                  <p className="badge badge-dark ml-2">
                                                    {t("HIGH")}
                                                  </p>
                                                )}
                                                {item?.severity ===
                                                  "BLACKLIST" && (
                                                  <p className="badge badge-danger ml-2">
                                                    {t("BLACKLIST")}
                                                  </p>
                                                )}
                                              </>
                                            )}
                                          </div>
                                          <div className="d-flex mb-0">
                                            <p
                                              className="mr-3"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {item.email}
                                            </p>
                                            <p style={{ fontSize: "13px" }}>
                                              {item.contact}
                                            </p>
                                          </div>
                                          <p className="mb-0">{item.notes}</p>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  ))}
                              </div>
                            )}
                          </Stack>
                        </Grid>
                        {/* address details grid items */}
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <TextField
                            name="address1"
                            id="address1"
                            size="small"
                            fullWidth
                            type="text"
                            placeholder={t("Address line 1")}
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t("Address")}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <TextField
                            name="zipcode"
                            id="zipcode"
                            size="small"
                            fullWidth
                            type="text"
                            placeholder={t("Zip Code")}
                            value={formik.values.zipcode}
                            onChange={(e) => {
                              const re = /^[0-9A-Za-z\b]+$/;
                              if (
                                e.target.value == "" ||
                                re.test(e.target.value)
                              ) {
                                formik.handleChange(e);
                                if (
                                  e.target.value.length >= 5 &&
                                  e.target.value.length <= 10
                                ) {
                                  //call the api function and get the city state and country vals
                                  getGeoCodeValues(e.target.value);
                                }
                              }
                            }}
                            onBlur={formik.handleBlur}
                            label={t("Zip Code")}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Autocomplete
                            fullWidth
                            onChange={(eve, val) => {
                              if (val !== formik.values.country)
                                formik.setFieldValue("state", null);
                              formik.setFieldValue("country", val);
                            }}
                            options={Object.keys(countries)}
                            value={formik.values.country}
                            size="small"
                            renderInput={(params) => {
                              params.inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  size="small"
                                  {...params}
                                  label={t("Country")}
                                />
                              );
                            }}
                            filterOptions={(options, { inputValue }) => {
                              if (!inputValue) return options;
                              const input = inputValue.toLowerCase();
                              return options
                                .filter((option) =>
                                  option.toLowerCase().includes(input)
                                )
                                .sort((a, b) => {
                                  const aStartsWith = a
                                    .toLowerCase()
                                    .startsWith(input);
                                  const bStartsWith = b
                                    .toLowerCase()
                                    .startsWith(input);
                                  if (aStartsWith && !bStartsWith) return -1;
                                  if (!aStartsWith && bStartsWith) return 1;
                                  return a.localeCompare(b);
                                });
                            }}
                          ></Autocomplete>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            renderInput={(params) => {
                              params.inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  size="small"
                                  {...params}
                                  label={t("State")}
                                />
                              );
                            }}
                            onChange={(eve, val) => {
                              formik.setFieldValue("state", val);
                            }}
                            options={
                              isNull(formik.values.country) ||
                              isUndefined(countries[formik.values.country])
                                ? []
                                : countries[formik.values.country]
                            }
                            value={formik.values.state}
                            filterOptions={(options, { inputValue }) => {
                              if (!inputValue) return options;
                              const input = inputValue.toLowerCase();
                              return options
                                .filter((option) =>
                                  option.toLowerCase().includes(input)
                                )
                                .sort((a, b) => {
                                  const aStartsWith = a
                                    .toLowerCase()
                                    .startsWith(input);
                                  const bStartsWith = b
                                    .toLowerCase()
                                    .startsWith(input);
                                  if (aStartsWith && !bStartsWith) return -1;
                                  if (!aStartsWith && bStartsWith) return 1;
                                  return a.localeCompare(b);
                                });
                            }}
                          ></Autocomplete>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <TextField
                            name="city"
                            id="city"
                            fullWidth
                            size="small"
                            type="text"
                            placeholder={t("City")}
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label={t("City")}
                            variant="outlined"
                          />
                        </Grid>

                        {/* Verification details grid items */}
                        <Grid item xs={4}>
                          <TextField
                            select
                            variant="outlined"
                            size="small"
                            label={t("Government ID type")}
                            value={formik.values.govtIdType}
                            fullWidth
                            placeholder={t("Select an ID type")}
                            id="govtIdType"
                            name="govtIdType"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "govtIdType",
                                e.target.value
                              );
                            }}
                          >
                            {hotel.hotelCurrency === "INR"
                              ? govIdIndia.map((item, index) => (
                                  <MenuItem key={index + item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))
                              : govIdUS.map((item, index) => (
                                  <MenuItem key={index + item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="d-flex align-items-center mb-3">
                            <h4 className="mr-3 "> {t("Camera upload ?")}</h4>
                            <Switch
                              checked={cameraUpload}
                              onChange={() => setcameraUpload(!cameraUpload)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          {imageUpload === "Camera" && (
                            <div className="d-flex">
                              <div className="d-flex flex-column mr-3">
                                {image !== undefined && (
                                  <img
                                    src={`${image}`}
                                    style={{
                                      width: "24rem",
                                      heigth: "20rem",
                                      borderRadius: "1rem",
                                    }}
                                  />
                                )}
                                <Button
                                  variant="custom-button"
                                  className="mr-4 mt-3"
                                  onClick={() => handleOpenFrontIdModal()}
                                >
                                  {t("Upload front id")}
                                </Button>
                              </div>
                              <div className="d-flex flex-column">
                                {backImage !== undefined && (
                                  <img
                                    src={`${backImage}`}
                                    style={{
                                      width: "24rem",
                                      heigth: "20rem",
                                      borderRadius: "1rem",
                                    }}
                                  />
                                )}
                                <Button
                                  variant="custom-button"
                                  className="mt-3"
                                  onClick={() => handleOpenBackIdModal()}
                                >
                                  {t("Upload Back id")}
                                </Button>
                              </div>
                            </div>
                          )}
                        </Grid>
                        {imageUpload === "Device" && (
                          <Grid item xs={12}>
                            <div className="d-flex">
                              <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                                <h5
                                  className="mb-3"
                                  style={{ marginRight: "13px" }}
                                >
                                  {t("ID front")}
                                </h5>
                                <ReactImageUploading
                                  value={images}
                                  onChange={(image) => {
                                    setImages(image);
                                  }}
                                  dataURLKey="data_url"
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    // write your building UI
                                    <Stack
                                      // p="2"
                                      // bgColor="gray.50"
                                      // borderRadius="lg"
                                      className="upload__image-wrapper"
                                      sx={{
                                        borderRadius: "10px",
                                        height: "150px",
                                        backgroundColor: "#d3d3d336",
                                        padding: "1rem",
                                      }}
                                    >
                                      {imageList.length === 0 && (
                                        <IconButton
                                          children={
                                            <UploadIcon
                                              icon="bi:upload"
                                              htmlColor="black"
                                            />
                                          }
                                          variant="outline"
                                          // height="100%"
                                          sx={{
                                            borderRadius: "20px",
                                            backgroundColor: "#d3d3d3",
                                            height: "100%",
                                          }}
                                          onClick={onImageUpload}
                                          {...dragProps}
                                        ></IconButton>
                                      )}
                                      <Stack spacing="2">
                                        {imageList.map((image, index) => (
                                          <Stack
                                            sx={{
                                              position: "relative",
                                              padding: "0.5rem",
                                            }}
                                            key={index}
                                          >
                                            <img
                                              src={
                                                image["data_url"].includes(
                                                  "base64"
                                                )
                                                  ? image["data_url"]
                                                  : image["data_url"] +
                                                    "?lastmod=" +
                                                    format(
                                                      new Date(),
                                                      "ddMMyyyyhhmmsstt"
                                                    )
                                              }
                                              style={{
                                                height: "125px",
                                                width: "100%",
                                                borderRadius: "1rem",
                                                boxShadow: "md",
                                                border: "1px solid",
                                                borderColor: "gray.200",
                                              }}
                                              onClick={() =>
                                                handleFrontImagePopupShow(
                                                  "front"
                                                )
                                              }
                                              alt=""
                                            />

                                            <IconButton
                                              children={
                                                <CloseIcon
                                                  fontSize="xs"
                                                  htmlColor="black"
                                                />
                                              }
                                              size="xs"
                                              sx={{
                                                position: "absolute",
                                                borderRadius: "100%",
                                                backgroundColor: "white",
                                                top: "0",
                                                // colorScheme="teal"
                                                marginTop: "0px !important",
                                                left: "0",
                                                "&:hover": {
                                                  color: "black",
                                                  backgroundColor: "#ffffffcc",
                                                },
                                              }}
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            />
                                            <IconButton
                                              children={
                                                <ChangeCircleIcon
                                                  fontSize="xs"
                                                  htmlColor="black"
                                                />
                                              }
                                              size="xs"
                                              sx={{
                                                position: "absolute",
                                                borderRadius: "100%",
                                                top: "0",
                                                backgroundColor: "white",
                                                // colorScheme="teal"
                                                marginTop: "0px !important",
                                                right: "0",
                                                "&:hover": {
                                                  color: "black",
                                                  backgroundColor: "#ffffffcc",
                                                },
                                              }}
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                            />
                                          </Stack>
                                        ))}
                                      </Stack>
                                    </Stack>
                                  )}
                                </ReactImageUploading>
                              </div>
                              <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                                <h5 className="mb-3"> {t("ID back")}</h5>
                                <ReactImageUploading
                                  value={faviconImages}
                                  onChange={(image) => {
                                    setFaviconImages(image);
                                  }}
                                  dataURLKey="data_url"
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    // write your building UI
                                    <Stack
                                      // p="2"
                                      // bgColor="gray.50"
                                      // borderRadius="lg"
                                      className="upload__image-wrapper"
                                      sx={{
                                        borderRadius: "10px",
                                        height: "150px",
                                        backgroundColor: "#d3d3d336",
                                        padding: "1rem",
                                      }}
                                    >
                                      {imageList.length === 0 && (
                                        <IconButton
                                          children={
                                            <UploadIcon
                                              icon="bi:upload"
                                              htmlColor="black"
                                            />
                                          }
                                          variant="outline"
                                          sx={{
                                            borderRadius: "20px",
                                            backgroundColor: "#d3d3d3",
                                            height: "100%",
                                          }}
                                          onClick={onImageUpload}
                                          {...dragProps}
                                        ></IconButton>
                                      )}
                                      <Stack spacing="2">
                                        {imageList.map((image, index) => (
                                          <Stack
                                            sx={{
                                              position: "relative",
                                              padding: "0.5rem",
                                            }}
                                            key={index}
                                          >
                                            <img
                                              src={
                                                image["data_url"].includes(
                                                  "base64"
                                                )
                                                  ? image["data_url"]
                                                  : image["data_url"] +
                                                    "?lastmod=" +
                                                    format(
                                                      new Date(),
                                                      "ddMMyyyyhhmmsstt"
                                                    )
                                              }
                                              style={{
                                                height: "125px",
                                                width: "100%",
                                                borderRadius: "1rem",
                                                boxShadow: "md",
                                                border: "1px solid",
                                                borderColor: "gray.200",
                                              }}
                                              onClick={() =>
                                                handleFrontImagePopupShow(
                                                  "back"
                                                )
                                              }
                                              alt=""
                                            />

                                            <IconButton
                                              children={
                                                <CloseIcon
                                                  fontSize="xs"
                                                  htmlColor="black"
                                                />
                                              }
                                              size="xs"
                                              sx={{
                                                position: "absolute",
                                                borderRadius: "100%",
                                                backgroundColor: "white",
                                                top: "0",
                                                // colorScheme="teal"
                                                marginTop: "0px !important",
                                                left: "0",
                                                "&:hover": {
                                                  color: "black",
                                                  backgroundColor: "#ffffffcc",
                                                },
                                              }}
                                              onClick={() =>
                                                onImageRemove(index)
                                              }
                                            />
                                            <IconButton
                                              children={
                                                <ChangeCircleIcon
                                                  fontSize="xs"
                                                  htmlColor="black"
                                                />
                                              }
                                              size="xs"
                                              sx={{
                                                position: "absolute",
                                                borderRadius: "100%",
                                                top: "0",
                                                backgroundColor: "white",
                                                // colorScheme="teal"
                                                marginTop: "0px !important",
                                                right: "0",
                                                "&:hover": {
                                                  color: "black",
                                                  backgroundColor: "#ffffffcc",
                                                },
                                              }}
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                            />
                                          </Stack>
                                        ))}
                                      </Stack>
                                    </Stack>
                                  )}
                                </ReactImageUploading>
                              </div>
                            </div>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="d-flex align-item-center">
                            <p
                              className="mb-0 mr-3"
                              style={{
                                fontWeight: "bold",
                                marginTop: "10px",
                                fontSize: "13px",
                              }}
                            >
                              {t("Show more fields")}
                            </p>
                            <Switch
                              checked={showOptionalFields}
                              onChange={() =>
                                setshowOptionalFields(!showOptionalFields)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      {showOptionalFields && (
                        <Grid container spacing={2}>
                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <TextField
                              name="address2"
                              id="address2"
                              size="small"
                              fullWidth
                              type="text"
                              placeholder={t("Address Line 2")}
                              value={formik.values.address2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              label={t("Address Line 2")}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <TextField
                              select
                              size="small"
                              className="position-relative"
                              name="gender"
                              id="gender"
                              fullWidth
                              placeholder={t("Gender")}
                              value={formik.values.gender}
                              onChange={(e) => {
                                formik.setFieldValue("gender", e.target.value);
                              }}
                              label={t("Gender")}
                              error={
                                checkIshaUSAccess(hotel?.emailId) &&
                                formik.values.gender == "-"
                              }
                              helperText={
                                checkIshaUSAccess(hotel?.emailId) &&
                                formik.values.gender == "-"
                                  ? "Please select gender"
                                  : ""
                              }
                              variant="outlined"
                            >
                              {checkIshaAccess(hotel?.emailId) ||
                              checkIshaUSAccess(hotel?.emailId)
                                ? ishaGenderList?.length > 0 &&
                                  ishaGenderList.map((genVal, index) => {
                                    return (
                                      <MenuItem key={genVal} value={genVal}>
                                        {genVal}
                                      </MenuItem>
                                    );
                                  })
                                : genderList?.length > 0 &&
                                  genderList.map((genVal, index) => {
                                    return (
                                      <MenuItem key={genVal} value={genVal}>
                                        {genVal}
                                      </MenuItem>
                                    );
                                  })}
                            </TextField>
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat={dateFnsFormat}
                                fullWidth
                                disableMaskedInput
                                disableCloseOnSelect={false}
                                disableFuture
                                mask="___ ___, ____"
                                id="date_of_birth"
                                name="date_of_birth"
                                className={classes.textField}
                                size="small"
                                value={formik.values.date_of_birth}
                                label={t("Date of Birth")}
                                onChange={setDateOfBirthSelection}
                                onYearChange={(value) => {
                                  formik.setFieldValue("date_of_birth", value);
                                  setDateOfBirthSelection(value, null);
                                }}
                                InputProps={{ onKeyDown: handleDobKeyDown }}
                                onAccept={(value) => {
                                  const newYear = new Date(
                                    formik.values.date_of_birth
                                  ).getFullYear();
                                  const year = new Date(value).getFullYear();
                                  const sameYear = year == newYear;
                                  setDateOfBirthSelection(
                                    sameYear
                                      ? value
                                      : formik.values.date_of_birth || value
                                  );
                                }}
                                autoOk={true}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => {
                                  params.error = Boolean(
                                    formik.errors.date_of_birth
                                  );
                                  return (
                                    <TextField
                                      helperText={formik.errors.date_of_birth}
                                      size="small"
                                      fullWidth
                                      {...params}
                                    />
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <TextField
                              id="govtIdValue"
                              style={{ width: "100%" }}
                              label={t("Govt ID Number")}
                              size="small"
                              name="govtIdValue"
                              variant="outlined"
                              placeholder={t("Govt ID Number")}
                              value={formik.values.govtIdValue}
                              onChange={formik.handleChange}
                            />
                          </Grid>

                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Autocomplete
                              fullWidth
                              size="small"
                              renderInput={(params) => {
                                params.inputProps.autoComplete = "new-password";
                                return (
                                  <TextField
                                    size="small"
                                    {...params}
                                    label={t("Nationality")}
                                  />
                                );
                              }}
                              onChange={(eve, val) => {
                                formik.setFieldValue("nationality", val);
                              }}
                              options={nationalities}
                              // options={
                              //   isNull(formik.values.nationality) ||
                              //   isUndefined(countries[formik.values.country])
                              //     ? []
                              //     : countries[formik.values.country]
                              // }
                              value={formik.values.nationality}
                            ></Autocomplete>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextField
                              name="occupation"
                              id="occupation"
                              type="text"
                              size="small"
                              fullWidth
                              placeholder={t("Occupation")}
                              value={formik.values.occupation}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              label={t("Occupation")}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextField
                              className="position-relative"
                              name="special_request"
                              id="special_request"
                              fullWidth
                              size="small"
                              placeholder={t("Special Requests")}
                              value={formik.values.special_request}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              label={t("Special Requests")}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {hotel?.hotelCurrency === "INR" && showOptionalFields && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="d-flex align-item-center mt-3">
                            <Button
                              sx={{ width: 180 }}
                              variant="custom-button"
                              className="mr-4 mt-3"
                              onClick={() => setshowCompanyModal(true)}
                            >
                              {searchableValue
                                ? `${t("Update")}`
                                : `${t("Add")}`}
                              {t(" company details")}
                            </Button>
                          </div>
                        </Grid>

                        // <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        //   <div className="d-flex align-item-center mt-3">
                        //     <p
                        //       className="mb-0 mr-3"
                        //       style={{
                        //         fontWeight: "bold",
                        //         marginTop: "10px",
                        //       }}
                        //     >
                        //       Enable the toggle to add company details
                        //     </p>
                        //     <Switch
                        //       checked={showCompanyModal}
                        //       onChange={() => setshowCompanyModal(true)}
                        //       inputProps={{ "aria-label": "controlled" }}
                        //     />
                        //   </div>
                        // </Grid>
                      )}
                      {showOptionalFields && (
                        <Grid container style={{ marginTop: "15px" }}>
                          {addGuestState?.length > 0 &&
                            formik.values.guests.Adults > 1 &&
                            Array.from(
                              { length: formik.values.guests.Adults - 1 },
                              (_, index) => {
                                return (
                                  <>
                                    <Grid item xs={6}>
                                      <Button
                                        sx={{ width: 180 }}
                                        variant="custom-button"
                                        className="mr-4 mt-3"
                                        onClick={toggleAddGuestDrawer(
                                          "right",
                                          true,
                                          index
                                        )}
                                      >
                                        {`${
                                          otherGuestDetails?.length > index
                                            ? `${t("Edit guest")}`
                                            : `${t("Add guest")}`
                                        } ${index + 2} ${t("details")}`}
                                      </Button>
                                    </Grid>
                                    {/* add new guest modal */}
                                    <Drawer
                                      anchor={"right"}
                                      open={addGuestState[index]["right"]}
                                      onClose={toggleAddGuestDrawer(
                                        "right",
                                        false,
                                        index
                                      )}
                                      PaperProps={{
                                        sx: { width: 700 },
                                      }}
                                    >
                                      {addGuestDetail("right", index)}
                                    </Drawer>
                                  </>
                                );
                              }
                            )}
                        </Grid>
                      )}

                      <Drawer
                        anchor={"right"}
                        open={dayLevelPricingState["right"]}
                        onClose={toggleDayLevelPricingDrawer("right", false)}
                        PaperProps={{
                          sx: { width: 650 },
                        }}
                      >
                        {dayLevelPricindData &&
                          dayLevelPricingComponent("right")}
                      </Drawer>
                      <Grid container style={{ marginTop: "15px" }}>
                        {addChildState?.length > 0 &&
                          formik.values.guests.Children > 0 &&
                          Array.from(
                            { length: formik.values.guests.Children },
                            (_, index) => {
                              return (
                                <>
                                  <Grid item xs={6}>
                                    <Button
                                      sx={{ width: 180 }}
                                      variant="custom-button"
                                      className="mr-4 mt-3"
                                      onClick={toggleAddChildDrawer(
                                        "right",
                                        true,
                                        index
                                      )}
                                    >
                                      {`${
                                        childDataDetails?.length > index
                                          ? `${t("Edit child")}`
                                          : `${t("Add child")}`
                                      } ${index + 1} ${t("details")}`}
                                    </Button>
                                  </Grid>
                                  <Drawer
                                    anchor={"right"}
                                    open={addChildState[index]["right"]}
                                    onClose={toggleAddChildDrawer(
                                      "right",
                                      false,
                                      index
                                    )}
                                    PaperProps={{
                                      sx: { width: 700 },
                                    }}
                                  >
                                    {addChildDetail("right", index)}
                                  </Drawer>
                                </>
                              );
                            }
                          )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div
                  className="flex-column w-100"
                  style={{ marginLeft: "1rem" }}
                >
                  <Accordion expanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1c-content1"
                      id="panel1c-header1"
                      style={{
                        minHeight: "40px",
                      }}
                    >
                      <div className={classes.column}>
                        <Typography className={classes.heading}>
                          <CheckCircle
                            className={classes.summaryInner}
                            color="primary"
                          />

                          {t(`Rate plan / Segment`)}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <TextField
                            select
                            SelectProps={{ value: formik.values.rate_plan }}
                            variant="outlined"
                            fullWidth
                            size="small"
                            id="rate_plan"
                            name="rate_plan"
                            label={t("Rate plan")}
                            onChange={(e) => {
                              formik.setFieldValue("rate_plan", e.target.value);
                              pricingRequestModel.ratePlanId = e.target.value;
                              pricingRequestModel.resetParams();
                              fetchUpdatedBookingPricing();
                            }}
                          >
                            {roomInformation?.ratePlanList.map(
                              (item, index) => (
                                <MenuItem
                                  key={index + item.ratePlanName}
                                  value={item.ratePlanId}
                                >
                                  {item.ratePlanName}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          {segment && (
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-helper-label">
                                {t("Segment")}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                size="small"
                                value={segment}
                                label={t("Segment")}
                                onChange={handleSegmentChange}
                              >
                                {mostMajorSource &&
                                  mostMajorSource.map((res) => {
                                    if (mostSecondarySource[res]?.length > 0) {
                                      return (
                                        <MenuItem value={res}>{res}</MenuItem>
                                      );
                                    }
                                  })}
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                          {subSegmentArr?.length > 0 && (
                            <Autocomplete
                              size="small"
                              fullWidth
                              value={subSegment}
                              onChange={(event, newValue) =>
                                setSubSegment(newValue)
                              }
                              options={subSegmentArr?.map((res) =>
                                typeof res === "object" ? res.cta_name : res
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("Sub-segment")}
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              filterOptions={(options, { inputValue }) => {
                                if (!inputValue) return options;
                                const input = inputValue.toLowerCase();
                                return options
                                  .filter((option) =>
                                    option.toLowerCase().includes(input)
                                  )
                                  .sort((a, b) => {
                                    const aStartsWith = a
                                      .toLowerCase()
                                      .startsWith(input);
                                    const bStartsWith = b
                                      .toLowerCase()
                                      .startsWith(input);
                                    if (aStartsWith && !bStartsWith) return -1;
                                    if (!aStartsWith && bStartsWith) return 1;
                                    return a.localeCompare(b);
                                  });
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Divider style={{ marginTop: "1rem" }} />

                      <div
                        className={classes.column}
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          display: "flex",
                        }}
                      >
                        <Typography
                          className={classes.heading}
                          style={{ marginRight: "1rem" }}
                        >
                          <CheckCircle
                            className={classes.summaryInner}
                            color="primary"
                          />

                          {t("Tax exempt")}
                        </Typography>
                        <Switch
                          checked={showTaxExempt}
                          onChange={() => setshowTaxExempt(!showTaxExempt)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                      {showTaxExempt && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                              label={t("Tax exempt ID")}
                              size="small"
                              value={formik.values.taxId}
                              onChange={taxIdChanged}
                              fullWidth
                            ></TextField>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Autocomplete
                              fullWidth
                              size="small"
                              disabled={
                                (!formik.values.taxId.trim() &&
                                  formik.values.taxId !== 0) ||
                                formik.values.taxId === "" ||
                                formik.values.taxId === null
                              }
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    size="small"
                                    {...params}
                                    label={t("Taxes To Exempt")}
                                  />
                                );
                              }}
                              disableClearable
                              onChange={(e, v) => {
                                if (v.length > 0)
                                  formik.setFieldValue("selectedTaxes", v);
                              }}
                              multiple
                              options={isNull(taxNames) ? [] : taxNames}
                              value={
                                formik.values.taxId === "" ||
                                (!formik.values.taxId.trim() &&
                                  formik.values.taxId !== 0)
                                  ? []
                                  : isNull(taxNames)
                                  ? []
                                  : formik.values.selectedTaxes
                              }
                            ></Autocomplete>
                          </Grid>
                        </Grid>
                      )}
                      <Divider style={{ marginTop: "1rem" }} />

                      {JSON.parse(
                        hotel?.accessControl?.user_feature_map?.ApplyCouponCode
                      ).read === "YES" && (
                        <>
                          <div
                            className={classes.column}
                            style={{
                              marginTop: "1rem",
                              marginBottom: "1rem",
                              display: "flex",
                            }}
                          >
                            <Typography
                              className={classes.heading}
                              style={{ marginRight: "1rem" }}
                            >
                              <CheckCircle
                                className={classes.summaryInner}
                                color="primary"
                              />

                              {t("Coupons / Offers / Discount")}
                            </Typography>

                            <Switch
                              checked={showCoupons}
                              onChange={() => setshowCoupons(!showCoupons)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>

                          {showCoupons && (
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <div className="d-flex align-items-center">
                                  <FormControl
                                    size="small"
                                    sx={{ width: "250px", marginRight: "1rem" }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      {t("Coupon code / offer")}
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label={t("Coupon code / offer")}
                                      size="small"
                                      value={code}
                                      onChange={(e) => setcode(e.target.value)}
                                      required="true"
                                    >
                                      {((subUser.toLowerCase() === "subuser" &&
                                        hotelemail !==
                                          "reservation@maharashtratourism.gov.in") ||
                                        subUser.toLowerCase() === "admin") && (
                                        <MenuItem value={"secretcode"}>
                                          {t("Coupon code")}
                                        </MenuItem>
                                      )}
                                      <MenuItem value={"promocode"}>
                                        {t("Offer")}
                                      </MenuItem>
                                      {((subUser.toLowerCase() === "subuser" &&
                                        hotelemail !==
                                          "reservation@maharashtratourism.gov.in") ||
                                        subUser.toLowerCase() === "admin") && (
                                        <MenuItem value={"discount"}>
                                          {t("Discount")}
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                  {code === "secretcode" && (
                                    <div className="d-flex align-items-center">
                                      <TextField
                                        size="small"
                                        label={t("Enter coupon code")}
                                        value={promoValue}
                                        onChange={(e) =>
                                          setpromoValue(e.target.value)
                                        }
                                        fullWidth
                                      ></TextField>
                                    </div>
                                  )}

                                  {code === "promocode" && (
                                    <>
                                      {PromocodeData &&
                                      PromocodeData?.length > 0 ? (
                                        <FormControl
                                          size="small"
                                          sx={{ m: 1, width: "150px" }}
                                        >
                                          <InputLabel id="demo-simple-select-label">
                                            {t("Select offer")}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t("Select offer")}
                                            value={promoValue}
                                            size="small"
                                            onChange={(e) => {
                                              setpromoValue(e.target.value);
                                            }}
                                            required="true"
                                            // MenuProps={MenuProps}
                                            placeholder="Select rate plan"
                                          >
                                            {PromocodeData.map((e) => (
                                              <MenuItem value={e.promoCode}>
                                                {e.promoCode}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      ) : (
                                        <h4>
                                          {t(
                                            "No offers created, create from Revenue Management tab to apply"
                                          )}
                                        </h4>
                                      )}
                                    </>
                                  )}
                                  {code === "discount" && (
                                    <div className="d-flex">
                                      <FormControl
                                        size="small"
                                        style={{ width: "8rem" }}
                                      >
                                        <InputLabel id="demo-simple-select-label">
                                          {t("Select discount type")}
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          size="small"
                                          label={t("Select discount type")}
                                          value={dicountType}
                                          onChange={(e) => {
                                            setdiscountAmount(0);
                                            removeDiscount();
                                            setdicountType(e.target.value);
                                          }}
                                          required="true"
                                          // MenuProps={MenuProps}
                                          placeholder={t(
                                            "Select discount type"
                                          )}
                                        >
                                          <MenuItem value={"percent"}>
                                            {t("Percent")}
                                          </MenuItem>
                                          <MenuItem value={"flat"}>
                                            {" "}
                                            {t("Flat")}
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                      <TextField
                                        size="small"
                                        label={t("Enter discount value")}
                                        value={discountAmount}
                                        style={{
                                          width: "6rem",
                                          marginLeft: "1rem",
                                        }}
                                        onChange={(e) => {
                                          // const amount = Number(e.target.value);
                                          // if (dicountType === "percent") {
                                          //   if (amount >= 0 && amount <= 100)
                                          setdiscountAmount(e.target.value);
                                          // } else {
                                          //   if (
                                          //     amount >= 0 &&
                                          //     amount <= formik.values.priceBeforeTax
                                          //   )
                                          //     setdiscountAmount(e.target.value);
                                          // }
                                        }}
                                      ></TextField>
                                    </div>
                                  )}
                                  {showPromoButton &&
                                    code !== "discount" &&
                                    copyPromocodeData?.length > 0 &&
                                    promoValue.length > 0 && (
                                      <Button
                                        style={{
                                          marginLeft: "0.5rem",
                                          height: "3rem",
                                        }}
                                        variant="outlined"
                                        onClick={() => applyingPromo()}
                                      >
                                        {t("Apply")}
                                      </Button>
                                    )}
                                  {showDiscountButton &&
                                    code === "discount" && (
                                      <Button
                                        style={{
                                          marginLeft: "0.5rem",
                                          height: "3rem",
                                        }}
                                        variant="outlined"
                                        onClick={() => applyDiscount()}
                                      >
                                        {t("Apply")}
                                      </Button>
                                    )}
                                  {code !== "discount" &&
                                    copyPromocodeData?.length > 0 &&
                                    promoValue.length > 0 && (
                                      <Button
                                        style={{
                                          marginLeft: "0.5rem",
                                          height: "3rem",
                                        }}
                                        variant="outlined"
                                        onClick={() => removePromo()}
                                      >
                                        {t("Remove")}
                                      </Button>
                                    )}
                                  {code === "discount" && (
                                    <Button
                                      style={{
                                        marginLeft: "0.5rem",
                                        height: "3rem",
                                      }}
                                      variant="outlined"
                                      onClick={() => {
                                        setpaymentAmount(
                                          Number(formik.values.priceAfterTax)
                                        );
                                        removeDiscount();
                                      }}
                                    >
                                      {t("Remove")}
                                    </Button>
                                  )}
                                </div>
                                {PromoValidation?.validPromo === false && (
                                  <p
                                    className="text-danger"
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    {t("Enter a valid offer")}
                                  </p>
                                )}{" "}
                                {PromoValidation?.validPromo === true && (
                                  <p
                                    className="text-success"
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    {t("Successfully applied offer")}
                                  </p>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}

                      <Divider style={{ marginTop: "1rem" }} />

                      <div
                        className={classes.column}
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      >
                        <div
                          className="d-flex"
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography className={classes.heading}>
                            {formik.errors.perNightPrice ||
                            formik.errors.priceAfterTax ||
                            formik.errors.priceBeforeTax ? (
                              <Error
                                className={classes.summaryInner}
                                color="error"
                              />
                            ) : (
                              <CheckCircle
                                className={classes.summaryInner}
                                color="primary"
                              />
                            )}
                            {t("Prices")}
                          </Typography>
                          {JSON.parse(
                            hotel?.accessControl?.user_feature_map
                              ?.EditBookingPrice
                          ).read === "YES" &&
                            hotelCountry !== "United States" && (
                              <div className="d-flex align-items-center mb-3">
                                <h5 className="mr-3 ">
                                  {" "}
                                  {t("Disabled prices")}
                                </h5>
                                <Switch
                                  checked={disablePrices}
                                  onChange={() =>
                                    setdisablePrices(!disablePrices)
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              </div>
                            )}
                        </div>
                      </div>

                      <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Autocomplete
                        fullWidth
                        options={ctas}
                        getOptionLabel={(option) => {
                          return option.cta_name;
                        }}
                        disableClearable
                        isOptionEqualToValue={(opt, val) =>
                          opt.cta_email === val.cta_email
                        }
                        onChange={(e, val) => {
                          console.log("val is : ", val);
                          setSelectedCta(val);
                          pricingRequestModel.ctaId = val.cta_id;
                          pricingRequestModel.resetParams();
                          fetchUpdatedBookingPricing();
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Source" />
                        )}
                        value={selectedCta}
                      ></Autocomplete>
                    </Grid> */}

                        {!(
                          hotel.slotType === "PURE_HOURLY" &&
                          sessionStorage.getItem("types") === "day"
                        ) && (
                          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                            <div className="d-flex">
                              <TextField
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="perNightPrice"
                                id="perNightPrice"
                                fullWidth
                                required
                                error={
                                  formik.touched.perNightPrice &&
                                  Boolean(formik.errors.perNightPrice)
                                }
                                helperText={
                                  formik.touched.perNightPrice &&
                                  formik.errors.perNightPrice
                                }
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       {currency}
                                //     </InputAdornment>
                                //   ),
                                // }}
                                disabled={disablePrices || apiLoader}
                                type="number"
                                placeholder={t("Per night excluding taxes")}
                                value={formik.values.perNightPrice}
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  setshowButtonOfPricing("perNightPrice");
                                  formik.setFieldValue(
                                    "perNightPrice",
                                    e.target.value
                                  );

                                  if (
                                    e.target.value !== "" &&
                                    e.target.value !== null
                                  ) {
                                    pricingRequestModel.newRate =
                                      e.target.value;
                                    pricingRequestModel.newRateType =
                                      "PER_NIGHT_PRETAX";
                                  }
                                }}
                                label={t("Per night excluding taxes")}
                                variant="outlined"
                              />

                              {showButtonOfPricing === "perNightPrice" && (
                                <Button
                                  size="small"
                                  style={{ marginLeft: "10px" }}
                                  variant="custom-button"
                                  onClick={() => {
                                    setapiLoader(true);
                                    fetchUpdatedBookingPricing("perNightPrice");
                                  }}
                                  disabled={apiLoader}
                                >
                                  {apiLoader ? (
                                    <CircularProgress
                                      size={15}
                                      color="inherit"
                                      sx={{ ml: 1.5 }}
                                    />
                                  ) : (
                                    `${t("Apply")}`
                                  )}
                                </Button>
                              )}
                            </div>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                          <div className="d-flex">
                            {" "}
                            <TextField
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              required
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       {currency}
                              //     </InputAdornment>
                              //   ),
                              // }}
                              name="priceBeforeTax"
                              id="priceBeforeTax"
                              fullWidth
                              placeholder={t("Total excluding tax")}
                              type="number"
                              value={formik.values.priceBeforeTax}
                              onBlur={formik.handleBlur}
                              disabled={disablePrices || apiLoader}
                              error={Boolean(formik.errors.priceBeforeTax)}
                              helperText={formik.errors.priceBeforeTax}
                              onChange={(e) => {
                                setshowButtonOfPricing("priceBeforeTax");
                                formik.setFieldValue(
                                  "priceBeforeTax",
                                  e.target.value
                                );

                                if (
                                  e.target.value !== "" &&
                                  e.target.value !== null
                                ) {
                                  pricingRequestModel.newRate = e.target.value;
                                  pricingRequestModel.newRateType =
                                    "TOTAL_PRETAX";
                                }
                              }}
                              label={t("Total excluding tax")}
                              variant="outlined"
                            />
                            {showButtonOfPricing === "priceBeforeTax" && (
                              <Button
                                size="small"
                                style={{ marginLeft: "10px" }}
                                variant="custom-button"
                                onClick={() => {
                                  setapiLoader(true);
                                  fetchUpdatedBookingPricing("priceBeforeTax");
                                }}
                                disabled={apiLoader}
                              >
                                {apiLoader ? (
                                  <CircularProgress
                                    size={15}
                                    color="inherit"
                                    sx={{ ml: 1.5 }}
                                  />
                                ) : (
                                  `${t("Apply")}`
                                )}
                              </Button>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                          <TextField
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            required
                            // InputProps={{
                            //   startAdornment: (
                            //     <InputAdornment position="start">
                            //       {currency}
                            //     </InputAdornment>
                            //   ),
                            // }}
                            name="taxValue"
                            id="taxValue"
                            fullWidth
                            placeholder={t("Total tax")}
                            disabled
                            type="number"
                            value={formik.values.taxValue}
                            label={t("Total tax")}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                          <div className="d-flex">
                            <TextField
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              required
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       {currency}
                              //     </InputAdornment>
                              //   ),
                              // }}
                              name="priceAfterTax"
                              id="priceAfterTax"
                              placeholder={t("Total with tax")}
                              type="number"
                              disabled={disablePrices || apiLoader}
                              error={Boolean(formik.errors.priceAfterTax)}
                              helperText={formik.errors.priceAfterTax}
                              value={formik.values.priceAfterTax}
                              onChange={(e) => {
                                setshowButtonOfPricing("priceAfterTax");
                                formik.setFieldValue(
                                  "priceAfterTax",
                                  e.target.value
                                );

                                if (
                                  e.target.value !== "" &&
                                  e.target.value !== null
                                ) {
                                  pricingRequestModel.newRate = e.target.value;
                                  pricingRequestModel.newRateType =
                                    "TOTAL_WITHTAX";
                                }
                              }}
                              onBlur={formik.handleBlur}
                              label={t("Total with tax")}
                              variant="outlined"
                            />
                            {showButtonOfPricing === "priceAfterTax" && (
                              <Button
                                style={{ marginLeft: "10px" }}
                                size="small"
                                variant="custom-button"
                                onClick={() => {
                                  setapiLoader(true);
                                  fetchUpdatedBookingPricing("priceAfterTax");
                                }}
                                disabled={apiLoader}
                              >
                                {apiLoader ? (
                                  <CircularProgress
                                    size={15}
                                    color="inherit"
                                    sx={{ ml: 1.5 }}
                                  />
                                ) : (
                                  `${t("Apply")}`
                                )}
                              </Button>
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          {code === "discount" && (
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <TextField
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                required
                                style={{ marginBottom: "1rem" }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currency}
                                    </InputAdornment>
                                  ),
                                }}
                                name="priceAfterTax"
                                id="priceAfterTax"
                                fullWidth
                                placeholder={t(
                                  "Discounted Amount ( Pre-Tax amount )"
                                )}
                                type="number"
                                value={
                                  dicountType === "percent"
                                    ? formik.values.priceBeforeTax *
                                      (discountAmount / 100)
                                    : discountAmount
                                }
                                label={t(
                                  "Discounted Amount ( Pre-Tax amount )"
                                )}
                                variant="outlined"
                              />
                            </Grid>
                          )}
                          {(code === "promocode" || code === "secretcode") &&
                            PromoValidation?.validPromo === true && (
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  required
                                  style={{ marginBottom: "1rem" }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {currency}
                                      </InputAdornment>
                                    ),
                                  }}
                                  name="priceAfterTax"
                                  id="priceAfterTax"
                                  fullWidth
                                  placeholder={t(
                                    "Discounted value ( Pre-Tax amount )"
                                  )}
                                  type="number"
                                  value={parseFloat(
                                    PromoValidation?.discountValue
                                  )}
                                  label={t(
                                    "Discounted value ( Pre-Tax amount )"
                                  )}
                                  variant="outlined"
                                />
                              </Grid>
                            )}

                          <p
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "#1BC5BD",
                              fontSize: "15px",
                              marginBottom: "0px",
                              fontWeight: "bold",
                            }}
                            onClick={() => dayLevelDataa()}
                          >
                            {t("View day wise split of room prices?")}
                          </p>
                          <Divider style={{ marginTop: "1rem" }} />
                        </Grid>
                      </Grid>
                      {showEnquirySection && (
                        <>
                          <div
                            className={classes.column}
                            style={{
                              marginTop: "1rem",
                              marginBottom: "1rem",
                              display: "flex",
                            }}
                          >
                            <Typography
                              className={classes.heading}
                              style={{ marginRight: "1rem" }}
                            >
                              <CheckCircle
                                className={classes.summaryInner}
                                color="primary"
                              />

                              {t("Enquiry")}
                            </Typography>
                            <Switch
                              checked={showExpiresin}
                              onChange={() => setshowExpiresin(!showExpiresin)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                          {showExpiresin && (
                            <div className="d-flex mt-4 align-items-center">
                              <h6 className="mb-0 mr-2 ml-3">
                                {t("Enquiry expires in")}
                              </h6>

                              <TextField
                                style={{
                                  width: "5rem",
                                  height: "2.5rem",
                                  marginRight: "1rem",
                                }}
                                size="small"
                                label={t("Expiry time")}
                                onChange={(e) =>
                                  setEnquiryExpiryTime(e.target.value)
                                }
                              />
                              <FormControl
                                style={{
                                  width: "10rem",
                                  height: "2.5rem",
                                  marginRight: "1rem",
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  {t("Expiration time")}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={(e) =>
                                    setexpirationTime(e.target.value)
                                  }
                                  className="mr-4"
                                  label={t("Expiration time")}
                                  value={expirationTime}
                                  style={{ width: "10rem", height: "2.5rem" }}
                                >
                                  <MenuItem value={"MINUTES"}>
                                    {t("Minutes")}
                                  </MenuItem>
                                  <MenuItem value={"HOURS"}>
                                    {" "}
                                    {t("Hours")}
                                  </MenuItem>
                                  <MenuItem value={"DAYS"}>
                                    {t("Days")}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl
                                style={{ width: "10rem", height: "2.5rem" }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  {t("Source")}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={(e) =>
                                    setExpirationSource(e.target.value)
                                  }
                                  label={t("Source")}
                                  value={ExpirationSource}
                                  style={{ width: "10rem", height: "2.5rem" }}
                                >
                                  <MenuItem value={"BEFORE"}>
                                    {t("Before checkin")}
                                  </MenuItem>
                                  <MenuItem value={"AFTER"}>
                                    {" "}
                                    {t("After booking")}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </>
                      )}

                      <Divider style={{ marginTop: "1rem" }} />

                      {hotelAddons?.length > 0 &&
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map?.AddHotelAddons
                        ).read === "YES" && (
                          <>
                            <div
                              className={classes.column}
                              style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                display: "flex",
                              }}
                            >
                              <Typography
                                className={classes.heading}
                                style={{ marginRight: "1rem" }}
                              >
                                <CheckCircle
                                  className={classes.summaryInner}
                                  color="primary"
                                />

                                {t("Addons")}
                              </Typography>
                              <Switch
                                checked={showAddons}
                                onChange={() => setshowAddons(!showAddons)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </div>
                            {showAddons && (
                              <>
                                <div className="d-flex mt-4 align-items-center">
                                  {hotelAddons?.length === 0 && (
                                    <h4>{t("No hotel addons")}</h4>
                                  )}
                                  {hotelAddons?.length > 0 && (
                                    <FormControl
                                      sx={{
                                        m: 1,
                                        width: "33rem",
                                        marginBottom: "2rem",
                                      }}
                                    >
                                      <InputLabel id="demo-simple-select-label">
                                        {t("Select addons")}
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={t("Select addons")}
                                        value={selectedAddons}
                                        onChange={handleChange}
                                        required="true"
                                        multiple
                                        style={{ width: "33rem" }}
                                        // MenuProps={MenuProps}
                                        placeholder={t("Select addons")}
                                      >
                                        {hotelAddons.map((e) => (
                                          <MenuItem value={e.id}>
                                            {e.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                </div>
                                {selectedAddons?.length > 0 && (
                                  <div className="mt-3">
                                    <BootstrapTable
                                      wrapperClasses="header-table"
                                      keyField="name"
                                      data={addonsProductArray}
                                      columns={columns}
                                      rowStyle={rowStyle}
                                      headerStyle={headerStyle}
                                      // defaultSorted={defaultSorted}
                                      cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                        afterSaveCell: (
                                          oldValue,
                                          newValue,
                                          row,
                                          column
                                        ) => {
                                          row.priceWithTax =
                                            parseInt(row.quantity) *
                                            (parseFloat(row.priceWithoutTax) +
                                              parseFloat(row.priceWithoutTax) *
                                                (parseFloat(row.taxPercentage) /
                                                  100));

                                          setpaymentAmount(
                                            paymentAmount +
                                              (row.priceWithTax -
                                                row.priceWithTax /
                                                  parseInt(row.quantity))
                                          );
                                        },
                                      })}
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            <Divider style={{ marginTop: "1rem" }} />
                          </>
                        )}

                      <div
                        className={classes.column}
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          display: "flex",
                        }}
                      >
                        <Typography
                          className={classes.heading}
                          style={{ marginRight: "1rem" }}
                        >
                          <CheckCircle
                            className={classes.summaryInner}
                            color="primary"
                          />

                          {t("Collect payment")}
                        </Typography>
                        <Switch
                          checked={showCollectpayment}
                          onChange={() =>
                            setshowCollectpayment(!showCollectpayment)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>

                      {showCollectpayment && (
                        <>
                          <div className="d-flex mt-4 align-items-center">
                            <FormControl
                              size="small"
                              sx={{ m: 1, width: "250px" }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                {t("Select payment method")}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("Select payment method")}
                                value={paymentMode}
                                size="small"
                                onChange={(e) => setpaymentMode(e.target.value)}
                                required="true"
                                // MenuProps={MenuProps}
                                placeholder="Select payment method"
                              >
                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.OtherPaymentModes
                                ).read === "YES" &&
                                  enableAddCard && (
                                    <MenuItem value={"SWIPE CARD"}>
                                      {t("CARD")}
                                    </MenuItem>
                                  )}
                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.CashPayment
                                ).read === "YES" && (
                                  <MenuItem value={"CASH"}>
                                    {" "}
                                    {t("CASH")}
                                  </MenuItem>
                                )}
                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.OfflineCardPayment
                                ).read === "YES" && (
                                  <MenuItem value={"CARD"}>
                                    {t("OFFLINE CARD PAYMENT")}
                                  </MenuItem>
                                )}
                                {hotel.hotelCurrency === "INR" && (
                                  <MenuItem value={"UPI"}> {t("UPI")}</MenuItem>
                                )}
                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.OtherPaymentModes
                                ).read === "YES" && (
                                  <MenuItem value={"OTHER"}>
                                    {t("OTHER")}
                                  </MenuItem>
                                )}

                                {JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.OtherPaymentModes
                                ).read === "YES" && (
                                  <MenuItem value={"BANK TRANSFER"}>
                                    {t("BANK TRANSFER")}
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>

                            {paymentMode !== "SWIPE CARD" && (
                              <div className="d-flex align-items-center">
                                <TextField
                                  size="small"
                                  label={t("Amount")}
                                  value={paymentAmount}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (!isNaN(value) && Number(value) >= 0) {
                                      setpaymentAmount(value);
                                    }
                                  }}
                                  fullWidth
                                  placeholder={t("Enter payment amount")}
                                  name="schedule_name"
                                  id="schedule_name"
                                  type="number"
                                />

                                {paymentAmount > 0 && (
                                  <TextField
                                    size="small"
                                    label={t("Remark")}
                                    value={remark}
                                    onChange={(e) => setremark(e.target.value)}
                                    fullWidth
                                    placeholder={t("Enter remark")}
                                    name="schedule_name"
                                    className="ml-2"
                                    id="schedule_name"
                                  ></TextField>
                                )}
                              </div>
                            )}
                          </div>
                          {props.hotelType !== "ISHA" &&
                            enableAddCard &&
                            paymentMode === "SWIPE CARD" && (
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <div style={{ paddingLeft: "7px" }}>
                                    <div className="amountWrapper">
                                      <div className="fieldWrapper d-flex justify-content-between">
                                        <TextField
                                          size="small"
                                          required
                                          id="outlined-name"
                                          type={"text"}
                                          label={t("First name")}
                                          name="firstName"
                                          value={addCardFirstName}
                                          onChange={(e) =>
                                            /^[a-zA-Z ]*$/.test(
                                              e.target.value
                                            ) &&
                                            setAddCardFirstName(e.target.value)
                                          }
                                          error={error.firstName}
                                          helperText={
                                            error.firstName
                                              ? addCardFirstName?.length === 0
                                                ? `${t("Enter first name")}`
                                                : `${t("Enter correct name")}`
                                              : ""
                                          }
                                          sx={{ width: "48%" }}
                                        />
                                        <TextField
                                          size="small"
                                          required
                                          id="outlined-name"
                                          label={t("Last name")}
                                          name="lastName"
                                          type={"text"}
                                          value={addCardLastName}
                                          onChange={(e) =>
                                            /^[a-zA-Z ]*$/.test(e.target.value)
                                              ? setAddCardLastName(
                                                  e.target.value
                                                )
                                              : setAddCardLastName(
                                                  addCardLastName
                                                )
                                          }
                                          error={error.lastName}
                                          helperText={
                                            error.lastName
                                              ? addCardLastName?.length === 0
                                                ? `${t("Enter last name")}`
                                                : `${t("Enter correct name")}`
                                              : ""
                                          }
                                          sx={{ width: "48%" }}
                                        />
                                      </div>
                                      <div className="fieldWrapper d-flex mt-4">
                                        <TextField
                                          required
                                          size="small"
                                          id="outlined-name"
                                          label={t("Card Number")}
                                          name="cardNumber"
                                          type={"number"}
                                          value={addCardNumber}
                                          onChange={(e) =>
                                            e.target.value.length <= 16 &&
                                            setAddCardNumber(e.target.value)
                                          }
                                          error={
                                            (!checkLuhn() &&
                                              addCardNumber?.length > 0) ||
                                            error.number
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            (!checkLuhn() &&
                                              addCardNumber?.length > 0) ||
                                            error.number
                                              ? `${t("invalid card number")}`
                                              : ""
                                          }
                                          sx={{ width: "100%" }}
                                        />
                                        {getCardType(
                                          addCardNumber?.slice(0, 16)
                                        ) && (
                                          <img
                                            style={{
                                              width: "60px",
                                              height: "40px",
                                              marginTop: "5px",
                                              marginLeft: "-80px",
                                              objectFit: "contain",
                                            }}
                                            src={
                                              getCardType(
                                                addCardNumber?.slice(0, 16)
                                              )?.imageUrl
                                            }
                                            alt=" "
                                          />
                                        )}
                                      </div>
                                      <div className="fieldWrapper d-flex justify-content-between mt-4">
                                        <TextField
                                          size="small"
                                          // required
                                          id="outlined-name"
                                          label={t("Card CVV")}
                                          name="cardCVV"
                                          type={"number"}
                                          value={cardNumberCVVFormat(
                                            addCardCVV
                                          )}
                                          onChange={(e) =>
                                            setAddCardCVV(e.target.value)
                                          }
                                          sx={{ width: "48%" }}
                                        />
                                        <TextField
                                          size="small"
                                          required
                                          id="outlined-name"
                                          label={t("Card Expiry (MMYY)")}
                                          name="cardExpiry"
                                          type={"number"}
                                          value={addCardExpiry}
                                          onChange={(e) =>
                                            e.target.value?.length < 5 &&
                                            setAddCardExpiry(e.target.value)
                                          }
                                          error={
                                            (!checkExpiry() &&
                                              addCardExpiry?.length > 0) ||
                                            error.expiry
                                          }
                                          helperText={
                                            (!checkExpiry() &&
                                              addCardExpiry?.length > 0) ||
                                            error.expiry
                                              ? `${t("invalid expiry")}`
                                              : ""
                                          }
                                          sx={{ width: "48%" }}
                                        />
                                      </div>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={checkedChargeCard}
                                            onChange={(event) =>
                                              setcheckedChargeCard(
                                                event.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label="Charge card"
                                      />
                                      {checkedChargeCard && (
                                        <Alert severity="warning">
                                          Checking this box will save and{" "}
                                          <span style={{ fontWeight: "bold" }}>
                                            charge
                                          </span>{" "}
                                          the card.
                                        </Alert>
                                      )}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <div>
                    {apiLoader === true ||
                    !formik.values.customer_name.trim() ||
                    showButtonOfPricing !== ""
                      ? showCreateBooking && (
                          <Fab
                            color="primary"
                            variant="extended"
                            className={classes.footer}
                            type="submit"
                            disabled={true}
                            style={{
                              marginBottom: "0px",
                              marginRight: "1rem",
                              marginTop: "1rem",
                            }}
                          >
                            {`${t("Create reservation")}`}
                          </Fab>
                        )
                      : showCreateBooking && (
                          <Fab
                            color="primary"
                            variant="extended"
                            className={classes.footer}
                            type="submit"
                            disabled={load || loadingData}
                            style={{
                              marginBottom: "0px",
                              marginRight: "1rem",
                              marginTop: "1rem",
                            }}
                          >
                            {`${t("Create reservation")}`}
                            {load && (
                              <CircularProgress
                                size={15}
                                color="inherit"
                                sx={{ ml: 1.5 }}
                              />
                            )}
                          </Fab>
                        )}
                    <div
                      className="d-flex"
                      style={{ float: "right", marginTop: "1.4rem" }}
                    >
                      {moment(formik.values.booking_start).isSame(
                        moment(),
                        "day"
                      ) &&
                        showExpiresin === false &&
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map?.CheckIn
                        ).read === "YES" &&
                        showCreateBooking && (
                          <div className="d-flex align-items-center mb-0">
                            <Checkbox
                              {...label}
                              onChange={(e) => toggleSelfCheckin(e)}
                            />
                            <h4 className="mr-3 mb-0">{t("Checkin")}</h4>
                          </div>
                        )}

                      {/* {unassignedReservations.length > 0 ? (
                  <Fab
                    color="primary"
                    variant="extended"
                    className={classes.footer}
                    disabled={load || loadingData}
                    onClick={() => handleShow()}
                  >
                    Create booking
                    {load && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    )}
                  </Fab>
                ) : (
                  <Fab
                    color="primary"
                    variant="extended"
                    className={classes.footer}
                    type={"submit"}
                    disabled={load || loadingData}
                  >
                    Create booking
                    {load && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    )}
                  </Fab>
                )} */}
                    </div>
                  </div>
                  {/* <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                      style={{ minHeight: "40px", marginBottom: "0px" }}
                    >
                      <div className={classes.column}>
                        <Typography className={classes.heading}>
                          <CheckCircle
                            className={classes.summaryInner}
                            color="primary"
                          />
                          Enquiry
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="d-flex align-items-center ">
                        <Checkbox
                          {...label}
                          onChange={(e) => toggleShowExpiresin(e)}
                        />
                        <h4 className="mr-3 mb-0">Enquiry</h4>
                      </div>
                      {showExpiresin && (
                        <div className="d-flex mt-4 align-items-center">
                          <h6 className="mb-0 mr-2 ml-3">Enquiry expires in</h6>
                          <OutlinedInput
                            style={{
                              width: "5rem",
                              height: "2.5rem",
                              marginRight: "1rem",
                            }}
                            label=""
                            notched={false}
                            onChange={(e) =>
                              setEnquiryExpiryTime(e.target.value)
                            }
                          />
                          <FormControl
                            style={{
                              width: "10rem",
                              height: "2.5rem",
                              marginRight: "1rem",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) =>
                                setexpirationTime(e.target.value)
                              }
                              className="mr-4"
                              label=""
                              value={expirationTime}
                              notched={false}
                              style={{ width: "10rem", height: "2.5rem" }}
                            >
                              <MenuItem value={"MINUTES"}>Minutes</MenuItem>
                              <MenuItem value={"HOURS"}>Hours</MenuItem>
                              <MenuItem value={"DAYS"}>Days</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl
                            style={{ width: "10rem", height: "2.5rem" }}
                          >
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) =>
                                setExpirationSource(e.target.value)
                              }
                              label=""
                              value={ExpirationSource}
                              notched={false}
                              style={{ width: "10rem", height: "2.5rem" }}
                            >
                              <MenuItem value={"BEFORE"}>
                                Before checkin
                              </MenuItem>
                              <MenuItem value={"AFTER"}>After booking</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion> */}
                  {/* {props.hotelType !== "ISHA" && (
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        style={{ minHeight: "40px", marginBottom: "0px" }}
                      >
                        <div className={classes.column}>
                          <Typography className={classes.heading}>
                            <CheckCircle
                              className={classes.summaryInner}
                              color="primary"
                            />
                            Collect payment
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="d-flex align-items-center ">
                          <Checkbox
                            {...label}
                            defaultChecked={
                              hotelCountry === "United States" ? true : false
                            }
                            onChange={(e) => toggleCollectpayment(e)}
                          />
                          <h4 className="mr-3 mb-0">Collect payment</h4>
                        </div>
                        {showCollectpayment && (
                          <>
                            <div className="d-flex mt-4 align-items-center">
                              <FormControl sx={{ m: 1, width: "250px" }}>
                                <InputLabel id="demo-simple-select-label">
                                  Select payment method
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Select payment method"
                                  value={paymentMode}
                                  onChange={(e) =>
                                    setpaymentMode(e.target.value)
                                  }
                                  required="true"
                                  // MenuProps={MenuProps}
                                  placeholder="Select payment method"
                                >
                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.OtherPaymentModes
                                  ).read === "YES" &&
                                    hotelCountry === "United States" && (
                                      <MenuItem value={"SWIPE CARD"}>
                                        SWIPE CARD
                                      </MenuItem>
                                    )}
                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.CashPayment
                                  ).read === "YES" && (
                                    <MenuItem value={"CASH"}>CASH</MenuItem>
                                  )}
                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.OfflineCardPayment
                                  ).read === "YES" && (
                                    <MenuItem value={"CARD"}>
                                      OFFLINE CARD PAYMENT
                                    </MenuItem>
                                  )}
                                  {hotel.hotelCurrency === "INR" && (
                                    <MenuItem value={"UPI"}>UPI</MenuItem>
                                  )}
                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.OtherPaymentModes
                                  ).read === "YES" && (
                                    <MenuItem value={"OTHER"}>OTHER</MenuItem>
                                  )}

                                  {JSON.parse(
                                    hotel?.accessControl?.user_feature_map
                                      ?.OtherPaymentModes
                                  ).read === "YES" && (
                                    <MenuItem value={"BANK TRANSFER"}>
                                      BANK TRANSFER
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>

                              {paymentMode !== "SWIPE CARD" && (
                                <div className="d-flex align-items-center">
                                  <TextField
                                    size="small"
                                    label="Amount"
                                    value={paymentAmount}
                                    onChange={(e) =>
                                      setpaymentAmount(e.target.value)
                                    }
                                    fullWidth
                                    placeholder="Enter payment amount"
                                    name="schedule_name"
                                    id="schedule_name"
                                  ></TextField>
                                  {paymentAmount > 0 && (
                                    <TextField
                                      size="small"
                                      label="Remark"
                                      value={remark}
                                      onChange={(e) =>
                                        setremark(e.target.value)
                                      }
                                      fullWidth
                                      placeholder="Enter remark"
                                      name="schedule_name"
                                      className="ml-2"
                                      id="schedule_name"
                                    ></TextField>
                                  )}
                                </div>
                              )}
                            </div>
                            {hotelCountry === "United States" &&
                              paymentMode === "SWIPE CARD" && (
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <div style={{ paddingLeft: "7px" }}>
                                      <div className="amountWrapper">
                                        <div className="fieldWrapper d-flex justify-content-between">
                                          <TextField
                                            size="small"
                                            required
                                            id="outlined-name"
                                            type={"text"}
                                            label="First name"
                                            name="firstName"
                                            value={addCardFirstName}
                                            onChange={(e) =>
                                              /^[a-zA-Z ]*$/.test(
                                                e.target.value
                                              ) &&
                                              setAddCardFirstName(
                                                e.target.value
                                              )
                                            }
                                            error={error.firstName}
                                            helperText={
                                              error.firstName
                                                ? addCardFirstName?.length === 0
                                                  ? "Enter first name"
                                                  : "Enter correct name"
                                                : ""
                                            }
                                            sx={{ width: "48%" }}
                                          />
                                          <TextField
                                            size="small"
                                            required
                                            id="outlined-name"
                                            label="Last name"
                                            name="lastName"
                                            type={"text"}
                                            value={addCardLastName}
                                            onChange={(e) =>
                                              /^[a-zA-Z ]*$/.test(
                                                e.target.value
                                              )
                                                ? setAddCardLastName(
                                                    e.target.value
                                                  )
                                                : setAddCardLastName(
                                                    addCardLastName
                                                  )
                                            }
                                            error={error.lastName}
                                            helperText={
                                              error.lastName
                                                ? addCardLastName?.length === 0
                                                  ? "Enter last name"
                                                  : "Enter correct name"
                                                : ""
                                            }
                                            sx={{ width: "48%" }}
                                          />
                                        </div>
                                        <div className="fieldWrapper d-flex mt-4">
                                          <TextField
                                            required
                                            size="small"
                                            id="outlined-name"
                                            label="Card Number"
                                            name="cardNumber"
                                            type={"number"}
                                            value={addCardNumber}
                                            onChange={(e) =>
                                              e.target.value.length <= 16 &&
                                              setAddCardNumber(e.target.value)
                                            }
                                            error={
                                              (!checkLuhn() &&
                                                addCardNumber?.length > 0) ||
                                              error.number
                                                ? true
                                                : false
                                            }
                                            helperText={
                                              (!checkLuhn() &&
                                                addCardNumber?.length > 0) ||
                                              error.number
                                                ? "invalid card number"
                                                : ""
                                            }
                                            sx={{ width: "100%" }}
                                          />
                                          {getCardType(
                                            addCardNumber?.slice(0, 16)
                                          ) && (
                                            <img
                                              style={{
                                                width: "60px",
                                                height: "40px",
                                                marginTop: "5px",
                                                marginLeft: "-80px",
                                                objectFit: "contain",
                                              }}
                                              src={
                                                getCardType(
                                                  addCardNumber?.slice(0, 16)
                                                )?.imageUrl
                                              }
                                              alt=" "
                                            />
                                          )}
                                        </div>
                                        <div className="fieldWrapper d-flex justify-content-between mt-4">
                                          <TextField
                                            size="small"
                                            // required
                                            id="outlined-name"
                                            label="Card CVV"
                                            name="cardCVV"
                                            type={"number"}
                                            value={cardNumberCVVFormat(
                                              addCardCVV
                                            )}
                                            onChange={(e) =>
                                              setAddCardCVV(e.target.value)
                                            }
                                            sx={{ width: "48%" }}
                                          />
                                          <TextField
                                            size="small"
                                            required
                                            id="outlined-name"
                                            label="Card Expiry (MMYY)"
                                            name="cardExpiry"
                                            type={"number"}
                                            value={addCardExpiry}
                                            onChange={(e) =>
                                              e.target.value?.length < 5 &&
                                              setAddCardExpiry(e.target.value)
                                            }
                                            error={
                                              (!checkExpiry() &&
                                                addCardExpiry?.length > 0) ||
                                              error.expiry
                                            }
                                            helperText={
                                              (!checkExpiry() &&
                                                addCardExpiry?.length > 0) ||
                                              error.expiry
                                                ? "invalid expiry"
                                                : ""
                                            }
                                            sx={{ width: "48%" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              )}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )} */}
                </div>
              </div>
              {/* {hotelCountry === "United States" && (
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                  >
                    <div className={classes.column}>
                      <Typography className={classes.heading}>
                        <CheckCircle
                          className={classes.summaryInner}
                          color="primary"
                        />
                        Add card / Swipe card
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>
                          <div className="amountWrapper">
                            <div className="fieldWrapper d-flex justify-content-between">
                              <TextField
                                required
                                id="outlined-name"
                                type={"text"}
                                label="First name"
                                name="firstName"
                                value={addCardFirstName}
                                onChange={(e) =>
                                  /^[a-zA-Z ]*$/.test(e.target.value) &&
                                  setAddCardFirstName(e.target.value)
                                }
                                error={error.firstName}
                                helperText={
                                  error.firstName
                                    ? addCardFirstName?.length === 0
                                      ? "Enter first name"
                                      : "Enter correct name"
                                    : ""
                                }
                                sx={{ width: "48%" }}
                              />
                              <TextField
                                required
                                id="outlined-name"
                                label="Last name"
                                name="lastName"
                                type={"text"}
                                value={addCardLastName}
                                onChange={(e) =>
                                  /^[a-zA-Z ]*$/.test(e.target.value)
                                    ? setAddCardLastName(e.target.value)
                                    : setAddCardLastName(addCardLastName)
                                }
                                error={error.lastName}
                                helperText={
                                  error.lastName
                                    ? addCardLastName?.length === 0
                                      ? "Enter last name"
                                      : "Enter correct name"
                                    : ""
                                }
                                sx={{ width: "48%" }}
                              />
                            </div>
                            <div className="fieldWrapper d-flex mt-4">
                              <TextField
                                required
                                id="outlined-name"
                                label="Card Number"
                                name="cardNumber"
                                type={"number"}
                                value={addCardNumber}
                                onChange={(e) =>
                                  e.target.value.length <= 16 &&
                                  setAddCardNumber(e.target.value)
                                }
                                error={
                                  (!checkLuhn() && addCardNumber?.length > 0) ||
                                  error.number
                                    ? true
                                    : false
                                }
                                helperText={
                                  (!checkLuhn() && addCardNumber?.length > 0) ||
                                  error.number
                                    ? "invalid card number"
                                    : ""
                                }
                                sx={{ width: "100%" }}
                              />
                              {getCardType(addCardNumber?.slice(0, 16)) && (
                                <img
                                  style={{
                                    width: "60px",
                                    height: "40px",
                                    marginTop: "5px",
                                    marginLeft: "-80px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    getCardType(addCardNumber?.slice(0, 16))
                                      ?.imageUrl
                                  }
                                  alt=" "
                                />
                              )}
                            </div>
                            <div className="fieldWrapper d-flex justify-content-between mt-4">
                              <TextField
                                // required
                                id="outlined-name"
                                label="Card CVV"
                                name="cardCVV"
                                type={"number"}
                                value={cardNumberCVVFormat(addCardCVV)}
                                onChange={(e) => setAddCardCVV(e.target.value)}
                                sx={{ width: "48%" }}
                              />
                              <TextField
                                required
                                id="outlined-name"
                                label="Card Expiry (MMYY)"
                                name="cardExpiry"
                                type={"number"}
                                value={addCardExpiry}
                                onChange={(e) =>
                                  e.target.value?.length < 5 &&
                                  setAddCardExpiry(e.target.value)
                                }
                                error={
                                  (!checkExpiry() &&
                                    addCardExpiry?.length > 0) ||
                                  error.expiry
                                }
                                helperText={
                                  (!checkExpiry() &&
                                    addCardExpiry?.length > 0) ||
                                  error.expiry
                                    ? "invalid expiry"
                                    : ""
                                }
                                sx={{ width: "48%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )} */}
              {/* <Accordion defaultExpanded> */}
              {/* <AccordionSummary expandIcon={<ExpandMore />}>
                  <div className={classes.column}>
                    <Typography className={classes.heading}>
                      <CheckCircle
                        className={classes.summaryInner}
                        color="primary"
                      />
                      Guests
                    </Typography>
                  </div>
                </AccordionSummary> */}
              {/* <AccordionDetails> */}
              {/* <Grid container spacing={2}>
                    <Grid
                      item
                      justifyContent={"space-between"}
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                    >
                      <Grid item>
                        <ListItemText
                          primary={"Adults"}
                          secondary={`Max occupancy : ${roomInformation.maxOccupancy}`}
                        ></ListItemText>
                      </Grid>
                      <Grid item style={{ height: "fit-content" }}>
                        <ButtonGroup variant="text">
                          <Button
                            sx={{ border: "none !important" }}
                            onClick={() => {
                              let count = formik.values.guests.Adults;
                              count =
                                // count <= roomInformation.defaultOccupancy
                                //   ? count - 1
                                //   : count;
                                count <= 1 ? 1 : count - 1;
                              pricingRequestModel.numAdults = count;

                              if (formik.values.guests.Adults !== count) {
                                pricingRequestModel.resetParams();
                                fetchUpdatedBookingPricing();
                              }
                              formik.setFieldValue("guests.Adults", count);
                              if (count == 1) {
                                //that means single adult so clear all other guest details
                                setOtherGuestDetails([]);
                              } else if (otherGuestDetails?.length == count) {
                                //that means the last added is an extra guest hence delete that guest detail
                                const otherGuestTemp = [...otherGuestDetails];
                                otherGuestTemp.pop();
                                setOtherGuestDetails(otherGuestTemp);
                              }
                              if (count == 1) {
                                //that means single adult so clear all other guest details
                                setOtherGuestIdProofs([]);
                              } else if (otherGuestIdProofs?.length == count) {
                                //that means the last added is an extra guest hence delete that guest detail
                                const otherGuestTemp = [...otherGuestIdProofs];
                                otherGuestTemp.pop();
                                setOtherGuestIdProofs(otherGuestTemp);
                    }
                              if (count == 1) {
                                //that means single adult so clear all other guest details
                                setOtherGuestDocuments([]);
                              } else if (otherGuestDocuments?.length == count) {
                                //that means the last added is an extra guest hence delete that guest detail
                                const otherGuestTemp = [...otherGuestDocuments];
                                otherGuestTemp.pop();
                                setOtherGuestDocuments(otherGuestTemp);
                              }
                            }}
                            color="primary"
                          >
                            <Remove />
                          </Button>
                          <Button sx={{ border: "none !important" }} disabled>
                            {formik.values.guests.Adults}
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => {
                              let count = formik.values.guests.Adults;
                              let countChild = formik.values.guests.Children;
                              count =
                                count + countChild >=
                                roomInformation.maxOccupancy
                                  ? count
                                  : count + 1;
                              pricingRequestModel.numAdults = count;
                              if (formik.values.guests.Adults !== count) {
                                pricingRequestModel.resetParams();
                                fetchUpdatedBookingPricing();
                              }
                              formik.setFieldValue("guests.Adults", count);
                              let tempArr = [...addGuestState];
                              tempArr.push({ right: false });
                              setAddGuestState(tempArr);
                            }}
                          >
                            <Add />
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent={"space-between"}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                    >
                      <Grid item>
                        <ListItemText
                          primary={"Children"}
                          secondary={`Max children : ${roomInformation.maxChildren}`}
                        ></ListItemText>
                      </Grid>
                      <Grid item style={{ height: "fit-content" }}>
                        <ButtonGroup variant="text">
                          <Button
                            sx={{ border: "none !important" }}
                            onClick={() => {
                              let count = formik.values.guests.Children;
                              count = count <= 0 ? 0 : count - 1;
                              pricingRequestModel.numChildren = count;
                              if (formik.values.guests.Children !== count) {
                                pricingRequestModel.resetParams();
                                fetchUpdatedBookingPricing();
                              }
                              formik.setFieldValue("guests.Children", count);
                              if (childDataDetails?.length == count) {
                                //that means the last added is an extra guest hence delete that guest detail
                                const otherGuestTemp = [...childDataDetails];
                                childDataDetails.pop();
                                setChildDataDetails(otherGuestTemp);
                              }
                            }}
                            color="primary"
                          >
                            <Remove />
                          </Button>
                          <Button sx={{ border: "none !important" }} disabled>
                            {formik.values.guests.Children}
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => {
                              let count = formik.values.guests.Children;
                              let countAdults = formik.values.guests.Adults;
                              count =
                                count + countAdults >=
                                  roomInformation.maxOccupancy ||
                                count >= roomInformation.maxChildren
                                  ? count
                                  : count + 1;
                              pricingRequestModel.numChildren = count;
                              if (formik.values.guests.Children !== count) {
                                pricingRequestModel.resetParams();
                                fetchUpdatedBookingPricing();
                              }
                              formik.setFieldValue("guests.Children", count);
                              let tempArr = [...addChildState];
                              tempArr.push({ right: false });
                              setAddChildState(tempArr);
                            }}
                          >
                            <Add />
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </Grid> */}
              {/* <Grid container style={{ marginTop: "15px" }}>
                    {addGuestState?.length > 0 &&
                      formik.values.guests.Adults > 1 &&
                      Array.from(
                        { length: formik.values.guests.Adults - 1 },
                        (_, index) => {
                          return (
                            <> */}
              {/* <Grid item xs={6}>
                                <Button
                                  variant="custom-button"
                                  className="mr-4 mt-3"
                                  onClick={toggleAddGuestDrawer(
                                    "right",
                                    true,
                                    index
                                  )}
                                >
                                  Add Guest {index + 2} Details
                                </Button>
                              </Grid> */}
              {/* add new guest modal */}
              {/* <Drawer
                                anchor={"right"}
                                open={addGuestState[index]["right"]}
                                onClose={toggleAddGuestDrawer(
                                  "right",
                                  false,
                                  index
                                )}
                                PaperProps={{
                                  sx: { width: 700 },
                                }}
                              >
                                {addGuestDetail("right", index)}
                              </Drawer> */}
              {/* </> */}
              {/* ); */}
              {/* }
                      )} */}
              {/* </Grid> */}
              {/* <Grid container style={{ marginTop: "15px" }}>
                    {addChildState?.length > 0 &&
                      formik.values.guests.Children > 0 &&
                      Array.from(
                        { length: formik.values.guests.Children },
                        (_, index) => {
                          return (
                            <>
                              <Grid item xs={6}>
                                <Button
                                  variant="custom-button"
                                  className="mr-4 mt-3"
                                  onClick={toggleAddChildDrawer(
                                    "right",
                                    true,
                                    index
                                  )}
                                >
                                  Add Child {index + 1} Details
                                </Button>
                              </Grid>
                              <Drawer
                                anchor={"right"}
                                open={addChildState[index]["right"]}
                                onClose={toggleAddChildDrawer(
                                  "right",
                                  false,
                                  index
                                )}
                                PaperProps={{
                                  sx: { width: 700 },
                                }}
                              >
                                {addChildDetail("right", index)}
                              </Drawer>
                            </>
                          );
                        }
                      )}
                  </Grid> */}
              {/* </AccordionDetails>
              </Accordion> */}
              {/* <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                >
                  <div className={classes.column}>
                    <Typography className={classes.heading}>
                      <CheckCircle
                        className={classes.summaryInner}
                        color="primary"
                      />
                      Special code / discount (Applicable on total excluding
                      taxes)
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="d-flex mt-4 align-items-center">
                    <FormControl sx={{ m: 1, width: "250px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Special code / discount
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Special code / discount"
                        value={code}
                        onChange={(e) => setcode(e.target.value)}
                        required="true"
                        // MenuProps={MenuProps}
                        placeholder="Select rate plan"
                      >
                        <MenuItem value={"secretcode"}>
                          Secret coupon code
                        </MenuItem>
                        <MenuItem value={"promocode"}>Promocode</MenuItem>
                      </Select>
                    </FormControl>
                    {code === "secretcode" && (
                      <div className="d-flex align-items-center">
                        <TextField
                          label="Enter secret coupon code"
                          value={promoValue}
                          onChange={(e) => setpromoValue(e.target.value)}
                          fullWidth
                        ></TextField>
                      </div>
                    )}

                    {code === "promocode" && (
                      <>
                        {PromocodeData && PromocodeData?.length > 0 ? (
                          <FormControl sx={{ m: 1, width: "150px" }}>
                            <InputLabel id="demo-simple-select-label">
                              Select code
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Select rates"
                              value={promoValue}
                              onChange={(e) => {
                                setpromoValue(e.target.value);
                              }}
                              required="true"
                              // MenuProps={MenuProps}
                              placeholder="Select rate plan"
                            >
                              {PromocodeData.map((e) => (
                                <MenuItem value={e.promoCode}>
                                  {e.promoCode}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <h4>No promocodes</h4>
                        )}
                      </>
                    )}
                    {code === "discount" && (
                      <div className="d-flex">
                        <FormControl style={{ width: "8rem" }}>
                          <InputLabel id="demo-simple-select-label">
                            Select discount type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select discount type"
                            value={dicountType}
                            onChange={(e) => setdicountType(e.target.value)}
                            required="true"
                            // MenuProps={MenuProps}
                            placeholder="Select discount type"
                          >
                            <MenuItem value={"percent"}>Percent</MenuItem>
                            <MenuItem value={"flat"}>Flat</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          label="Enter discount value"
                          value={discountAmount}
                          style={{ width: "6rem", marginLeft: "1rem" }}
                          onChange={(e) => {
                            if (dicountType === "percent") {
                              if (e.target.value >= 0 && e.target.value <= 100)
                                setdiscountAmount(e.target.value);
                            } else {
                              if (e.target.value >= 0)
                                setdiscountAmount(e.target.value);
                            }
                          }}
                        ></TextField>
                      </div>
                    )}
                    {showPromoButton &&
                      code !== "discount" &&
                      PromocodeData?.length > 0 && (
                        <Button
                          style={{ marginLeft: "0.5rem", height: "3rem" }}
                          variant="custom-button"
                          onClick={() => applyingPromo()}
                        >
                          Apply
                        </Button>
                      )}
                    {showDiscountButton && code === "discount" && (
                      <Button
                        style={{ marginLeft: "0.5rem", height: "3rem" }}
                        variant="custom-button"
                        onClick={() => applyDiscount()}
                      >
                        Apply
                      </Button>
                    )}
                    {code !== "discount" && PromocodeData?.length > 0 && (
                      <Button
                        style={{ marginLeft: "0.5rem", height: "3rem" }}
                        variant="custom-button"
                        onClick={() => removePromo()}
                      >
                        Remove
                      </Button>
                    )}
                    {code === "discount" && (
                      <Button
                        style={{ marginLeft: "0.5rem", height: "3rem" }}
                        variant="custom-button"
                        onClick={() => removeDiscount()}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                  {PromoValidation?.validPromo === false && (
                    <p className="text-danger" style={{ marginLeft: "1rem" }}>
                      Enter a valid promocode
                    </p>
                  )}{" "}
                  {PromoValidation?.validPromo === true && (
                    <p className="text-success" style={{ marginLeft: "1rem" }}>
                      Successfully applied promocode
                    </p>
                  )}
                </AccordionDetails>
              </Accordion> */}
            </form>
          )}
        </Grid>
      </ModalCommonSlider>
      {openBlock && (
        <BlockView
          eventData={eventData}
          closeFunc={handleAddEventSidebar}
          reload={reload}
          reloadagain={reloadagain}
          open={openBlock}
          modalClose={() => {
            setOpenBlock(false);
          }}
          viewType={viewType}
          showBlockRoomError={showBlockRoomError}
          selectedLanguage={props.selectedLanguage}
          selectedCheckinSlot={selectedCheckinSlot}
          selectedCheckoutSlot={selectedCheckoutSlot}
          isSingleSlot={checkinSlots.length === 1}
        ></BlockView>
      )}

      {/* <Modal
        show={showCompanyModal}
        onHide={() => setshowCompanyModal(false)}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ width: 500, height: 300 }}>
          
        </Modal.Body>
      </Modal> */}

      <Modal
        show={showFrontImagePopup}
        onHide={handleshowFrontImagePopupClose}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ width: 500, height: 300 }}>
          {showPopUpImages === "front" ? (
            images[0]?.data_url !== "" ? (
              <img
                src={
                  images[0]?.data_url?.includes("base64")
                    ? images[0]?.data_url
                    : images[0]?.data_url +
                      "?lastmod=" +
                      format(new Date(), "ddMMyyyyhhmmsstt")
                }
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "cover",
                }}
              />
            ) : (
              <></>
            )
          ) : faviconImages[0]?.data_url !== "" ? (
            <img
              src={
                faviconImages[0]?.data_url?.includes("base64")
                  ? faviconImages[0]?.data_url
                  : faviconImages[0]?.data_url +
                    "?lastmod=" +
                    format(new Date(), "ddMMyyyyhhmmsstt")
              }
              alt=""
              style={{
                width: "100%",
                height: "100%",
                resizeMode: "cover",
              }}
            />
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showFrontIdModal}
        onHide={handleCloseFrontIdModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton> {t("Front id")}</Modal.Header>
        <Modal.Body>
          {!image ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={image} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() => (!image ? capture() : setImage(undefined))}
          >
            {!image ? `${t("Capture photo")}` : `${t("take photo")}`}
          </Button>
        </Modal.Body>
      </Modal>
      <SearchModal
        show={showCompanyModal}
        hide={hideShowCompanyModal}
        allCompanyDetails={allCompanyDetails}
        searchableValue={searchableValue}
        setsearchableValue={setsearchableValue}
        getCompanyDetails={getCompanyDetails}
        selectedLanguage={props.selectedLanguage}
      ></SearchModal>
      <Modal
        show={showBackIdModal}
        onHide={handleCloseBackIdModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>{t("Back id")}</Modal.Header>
        <Modal.Body>
          {!backImage ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={backImage} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() =>
              !backImage ? backcapture() : setBackImage(undefined)
            }
          >
            {!backImage ? `${t("Capture photo")}` : `${t("take photo")}`}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showPassportModal}
        onHide={handleClosePassportModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>{t("Passport Size Photo")} </Modal.Header>
        <Modal.Body>
          {!passportImage ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={passportImage} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() =>
              !passportImage ? passportCapture() : setPassportImage(undefined)
            }
          >
            {!passportImage ? `${t("Capture photo")}` : `${t("take photo")}`}
          </Button>
        </Modal.Body>
      </Modal>

      {idGuruModal && (
        <IDguru
          show={idGuruModal}
          hide={handleCloseIdGuruModal}
          setselectedUserIdGuruData={setselectedUserIdGuruData}
        />
      )}
      {/* <Modal
        show={show}
        onHide={handleClose}
        style={{ zIndex: "3000", width: "100%", height: "100%" }}
        dialogClassName={"myModalBodyConfirmBooking"}
        // contentClassName={"myModalContentCTAImg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want create a booking there are some unassigned
            bookings present ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <form
            noValidate
            style={{ height: "auto" }}
            onSubmit={formik.handleSubmit}
            disabled={load}
          >
            <Button className="mx-2" variant="custom-button" type="submit">
              Create booking{" "}
              {load && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </Button>
          </form>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    hotelType: state.auth.hotelType,
  };
}
export default connect(mapStateToProps)(AddEventSidebar);
