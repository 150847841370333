import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
  Button,
  Stack,
  Checkbox,
} from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const PaymentModal = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const {
    hotelType,
    open,
    closeFunc,
    modalClose,
    bookingData,
    selectedLanguage,
    paymentType,
    cashPayLoading,
    offlineCashDate,
    serviceAmountDesc,
    handleCashPayment,
    reservationData,
    handleCloseCashPayment,
    setServiceAmountDesc,
    amount,
    setOfflineCashDate,
    setAmount,
    handleCloseOfflineCardPay,
    setOfflineCardDate,
    offlineCardDate,
    offlineCardAmt,
    setOfflineCardDesc,
    setOfflineCardAmt,
    offlineCardPayLoading,
    handleOfflineCardPayment,
    handleOfflineCheckPayment,
    setChequeDesc,
    handleCloseOfflineCheckPay,
    offlineCheckPayLoading,
    setOfflineCheckAmt,
    offlineCheckAmt,
    setOfflineCheckDate,
    offlineCheckDate,
    chequeDesc,
    upiAmt,
    setUpiAmt,
    upiDate,
    setUpiDate,
    upiDesc,
    setUpiDesc,
    handleUpiPayment,
    upiPayLoading,
    handleCloseOtherModePay,
    handleOtherModePayment,
    otherModeAmt,
    setOtherModeAmt,
    otherModeDesc,
    otherModePayLoading,
    otherModeDate,
    setOtherModeDate,
    setOtherModeDesc,
    setBankTransferModeAmt,
    bankTransferModeAmt,
    bankTransferModePayLoading,
    setBankTransferModeDesc,
    bankTransferModeDesc,
    bankTransferModeDate,
    setBankTransferModeDate,
    handleBankTransferPayment,
    setDepositAmt,
    depositAmt,
    depositName,
    handleCloseCashDeposit,
    handleDeposit,
    depositLoading,
    depositDate,
    setDepositDate,
    setDepositName,
    paymentLinkEmail,
    setPaymentLinkEmail,
    paymentLinkMobile,
    setPaymentLinkMobile,
    paymentLinkAmt,
    setPaymentLinkAmt,
    paymentLinkLoading,
    handlePaymentLink,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CommonSlider
        onBackdropClick={closeFunc}
        onClose={modalClose}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">{paymentType}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <div className="folioConfirmationWrapper">
              <div
                className="folioContent"
                style={{
                  borderBottom: "1px solid #DDDDDD",
                  paddingBottom: "15px",
                  marginBottom: "15px",
                }}
              >
                {paymentType === "Cash payment" && (
                  <div className="settleDuesWrapper">
                    <div className="settleDuesHeader">
                      {/* <div className="settleDuesHeading">Enter cash amount</div>
                       <div className="close" onClick={() => handleCloseCashPayment()}>
                         X
                       </div> */}
                    </div>
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Cash amount")}
                          name="amount"
                          value={amount}
                          className="mb-4"
                          onChange={(e) =>
                            /^[0-9.]*$/.test(e.target.value)
                              ? setAmount(e.target.value)
                              : setAmount(amount)
                          }
                          disabled={
                            JSON.parse(
                              hotel?.accessControl?.user_feature_map
                                ?.EditBookingPrice
                            ).read !== "YES"
                          }
                          sx={{ width: "100%" }}
                          type={"text"}
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Return to customer calculator")}
                          name="amount"
                          className="mb-4"
                          value={
                            Number(amount) >=
                            Number(reservationData.balance_due).toFixed(2)
                              ? (
                                  Number(amount) -
                                  Number(reservationData.balance_due).toFixed(2)
                                ).toFixed(2)
                              : "Less amount"
                          }
                          sx={{ width: "100%" }}
                          type={"text"}
                          InputProps={{ inputProps: { min: 0 } }}
                          disabled
                        />
                      </div>
                      <div className="fieldWrapper">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label={t("Date")}
                            value={offlineCashDate}
                            onChange={(newValue) => {
                              setOfflineCashDate(newValue);
                            }}
                            name="offline_card_date"
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Amount description")}
                          className="mb-4 mt-3"
                          name="service_amount_desc"
                          value={serviceAmountDesc}
                          onChange={(e) => setServiceAmountDesc(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      className="settleDuesSubmitBtns"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="cancelBtn"
                        onClick={() => handleCloseCashPayment()}
                      >
                        {t("Cancel")}
                      </button>
                      {cashPayLoading === false ? (
                        <Button
                          className="submit"
                          onClick={() => handleCashPayment()}
                          variant="custom-button"
                          disabled={
                            serviceAmountDesc !== ""
                              ? amount > 0
                                ? false
                                : true
                              : true
                          }
                        >
                          {t("Submit")}
                        </Button>
                      ) : (
                        <Button className="submit">
                          <CircularProgress size="15px" />
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {paymentType === "Offline card payment" && (
                  <div className="settleDuesWrapper">
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Card amount")}
                          name="amount"
                          className="mb-3"
                          value={offlineCardAmt}
                          onChange={(e) =>
                            /^[0-9.]*$/.test(e.target.value)
                              ? setOfflineCardAmt(e.target.value)
                              : setOfflineCardAmt(offlineCardAmt)
                          }
                          sx={{ width: "100%" }}
                          type={"text"}
                          disabled={
                            JSON.parse(
                              hotel?.accessControl?.user_feature_map
                                ?.EditBookingPrice
                            ).read !== "YES"
                          }
                        />
                        {/* <TextField
                                      error
                                      id="outlined-name"
                                      label="Card amount"
                                      name="amount"
                                      value={offlineCardAmt}
                                      onChange={(e) => (/^[0-9.]*$/.test(e.target.value) ? setOfflineCardAmt(e.target.value) : setOfflineCardAmt(offlineCardAmt))}
                                      sx={{ width: "100%" }}
                                      type={"text"}
                                      helperText={"Please enter the positive value"}
                                    /> */}
                      </div>
                      <div className="fieldWrapper">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label={t("Date")}
                            value={offlineCardDate}
                            onChange={(newValue) => {
                              setOfflineCardDate(newValue);
                            }}
                            name="offline_card_date"
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Amount description")}
                          className="mb-3 mt-3"
                          name="service_amount_desc"
                          value={serviceAmountDesc}
                          onChange={(e) => setOfflineCardDesc(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <div
                      className="settleDuesSubmitBtns"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="cancelBtn"
                        onClick={() => handleCloseOfflineCardPay()}
                      >
                        {t("Cancel")}
                      </button>
                      {offlineCardPayLoading === false ? (
                        <>
                          {JSON.parse(
                            hotel?.accessControl?.user_feature_map
                              ?.EnquiryDashboard
                          ).read === "YES" ? (
                            <Button
                              className="submit"
                              variant="custom-button"
                              onClick={() => handleOfflineCardPayment()}
                              disabled={offlineCardAmt > 0 ? false : true}
                            >
                              {t("Submit")}
                            </Button>
                          ) : (
                            <Button
                              className="submit"
                              onClick={() => handleOfflineCardPayment()}
                              variant="custom-button"
                              disabled={offlineCardAmt > 0 ? false : true}
                            >
                              {t("Submit")}
                            </Button>
                          )}
                        </>
                      ) : (
                        <button className="submit">
                          <CircularProgress size="15px" />
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {paymentType === "Offline cheque payment" && (
                  <div className="settleDuesWrapper">
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Cheque amount")}
                          name="amount"
                          className="mb-3"
                          value={offlineCheckAmt}
                          onChange={(e) =>
                            /^[0-9.]*$/.test(e.target.value)
                              ? setOfflineCheckAmt(e.target.value)
                              : setOfflineCheckAmt(offlineCheckAmt)
                          }
                          sx={{ width: "100%" }}
                          type={"text"}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label={t("Date")}
                            value={offlineCheckDate}
                            onChange={(newValue) => {
                              setOfflineCheckDate(newValue);
                            }}
                            name="offline_check_date"
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Description")}
                          className="mb-3 mt-3"
                          name="description"
                          value={chequeDesc}
                          onChange={(e) => setChequeDesc(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      className="settleDuesSubmitBtns"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        className="cancelBtn"
                        onClick={() => handleCloseOfflineCheckPay()}
                      >
                        {t("Cancel")}
                      </Button>
                      {offlineCheckPayLoading === false ? (
                        <Button
                          className="submit"
                          variant="custom-button"
                          onClick={() => handleOfflineCheckPayment()}
                          disabled={offlineCheckAmt > 0 ? false : true}
                        >
                          {t("Submit")}
                        </Button>
                      ) : (
                        <Button className="submit">
                          <CircularProgress size="15px" />
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {paymentType === "UPI Payment" && (
                  <div className="settleDuesWrapper">
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("UPI amount")}
                          name="amount"
                          className="mb-3"
                          value={upiAmt}
                          onChange={(e) =>
                            /^[0-9.]*$/.test(e.target.value)
                              ? setUpiAmt(e.target.value)
                              : setUpiAmt(upiAmt)
                          }
                          sx={{ width: "100%" }}
                          type={"text"}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label={t("Date")}
                            value={upiDate}
                            onChange={(newValue) => {
                              setUpiDate(newValue);
                            }}
                            name="upi_date"
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Description")}
                          name="description"
                          className="mb-3 mt-3"
                          value={upiDesc}
                          onChange={(e) => setUpiDesc(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      className="settleDuesSubmitBtns"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        className="cancelBtn"
                        onClick={() => handleCloseOfflineCheckPay()}
                      >
                        {t("Cancel")}
                      </Button>
                      {upiPayLoading === false ? (
                        <Button
                          className="submit"
                          variant="custom-button"
                          onClick={() => handleUpiPayment()}
                          disabled={upiAmt > 0 ? false : true}
                        >
                          {t("Submit")}
                        </Button>
                      ) : (
                        <Button className="submit">
                          <CircularProgress size="15px" />
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {paymentType === "Other payment modes" && (
                  <div className="settleDuesWrapper">
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          required
                          id="outlined-name"
                          label={t("Deposit amount")}
                          name="deposit_amount"
                          className="mb-3"
                          value={otherModeAmt}
                          onChange={(e) =>
                            /^[0-9.]*$/.test(e.target.value)
                              ? setOtherModeAmt(e.target.value)
                              : setOtherModeAmt(otherModeAmt)
                          }
                          sx={{ width: "100%" }}
                          type={"text"}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label={t("Service date")}
                            value={otherModeDate}
                            onChange={(newValue) => {
                              setOtherModeDate(newValue);
                            }}
                            name="deposit_date"
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Description")}
                          name="other_mode_desc"
                          className="mt-3 mb-3"
                          value={otherModeDesc}
                          onChange={(e) => setOtherModeDesc(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                      <div
                        className="settleDuesSubmitBtns"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          className="cancelBtn"
                          onClick={() => handleCloseOtherModePay()}
                        >
                          {t("Cancel")}
                        </Button>
                        {otherModePayLoading === false ? (
                          <Button
                            className="submit"
                            variant="custom-button"
                            onClick={() => handleOtherModePayment()}
                            disabled={otherModeAmt > 0 ? false : true}
                          >
                            {t("Submit")}
                          </Button>
                        ) : (
                          <Button className="submit">
                            <CircularProgress size="15px" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {paymentType === "Bank transfer" && (
                  <div className="settleDuesWrapper">
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Amount")}
                          name="amount"
                          value={bankTransferModeAmt}
                          onChange={(e) =>
                            /^[0-9.]*$/.test(e.target.value)
                              ? setBankTransferModeAmt(e.target.value)
                              : setBankTransferModeAmt(bankTransferModeAmt)
                          }
                          sx={{ width: "100%", marginBottom: "1rem" }}
                          type={"text"}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label={t("Date")}
                            value={bankTransferModeDate}
                            onChange={(newValue) => {
                              setBankTransferModeDate(newValue);
                            }}
                            name="upi_date"
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Remarks")}
                          name="description"
                          value={bankTransferModeDesc}
                          onChange={(e) =>
                            setBankTransferModeDesc(e.target.value)
                          }
                          sx={{
                            width: "100%",
                            marginBottom: "1rem",
                            marginTop: "1rem",
                          }}
                        />
                      </div>
                    </div>
                    <div className="settleDuesSubmitBtns">
                      {bankTransferModePayLoading === false ? (
                        <Button
                          className="submit"
                          variant="custom-button"
                          onClick={() => handleBankTransferPayment()}
                          disabled={bankTransferModeAmt > 0 ? false : true}
                        >
                          {t("Submit")}
                        </Button>
                      ) : (
                        <Button className="submit">
                          <CircularProgress size="15px" />
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {paymentType === "Cash deposit" && (
                  <div className="settleDuesWrapper">
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          required
                          id="outlined-name"
                          label={t("Cash deposit amount*")}
                          name="deposit_amount"
                          className="mb-3"
                          value={depositAmt}
                          onChange={(e) =>
                            /^[0-9.]*$/.test(e.target.value)
                              ? setDepositAmt(e.target.value)
                              : setDepositAmt(depositAmt)
                          }
                          sx={{ width: "100%" }}
                          type={"text"}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label={t("Service date")}
                            value={depositDate}
                            onChange={(newValue) => {
                              setDepositDate(newValue);
                            }}
                            name="deposit_date"
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Description*")}
                          className="mb-3 mt-3"
                          name="folio_item_name"
                          value={depositName}
                          onChange={(e) => setDepositName(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                      <div
                        className="settleDuesSubmitBtns"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          className="cancelBtn"
                          onClick={() => handleCloseCashDeposit()}
                        >
                          {t("Cancel")}
                        </Button>
                        {depositLoading === false ? (
                          <Button
                            className="submit"
                            variant="custom-button"
                            onClick={() => handleDeposit()}
                            disabled={depositAmt > 0 ? false : true}
                          >
                            {t("Submit")}
                          </Button>
                        ) : (
                          <Button className="submit">
                            <CircularProgress size="15px" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {paymentType === "Send payment link" && (
                  <div className="settleDuesWrapper">
                    <div className="amountWrapper">
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Email")}
                          name="email"
                          className="mb-3"
                          helperText={t(
                            "By default customers email has been populated. Override if necessary."
                          )}
                          value={paymentLinkEmail}
                          onChange={(e) => setPaymentLinkEmail(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Mobile number")}
                          name="mobile"
                          className="mb-3"
                          helperText={t(
                            "By default customers mobile has been populated. Override if necessary."
                          )}
                          value={paymentLinkMobile}
                          onChange={(e) => setPaymentLinkMobile(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          id="outlined-name"
                          label={t("Amount")}
                          className="mb-3"
                          helperText={t(
                            "By default total due amount has been populated. Override if necessary."
                          )}
                          name="service_amount_desc"
                          value={paymentLinkAmt}
                          onChange={(e) => setPaymentLinkAmt(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      className="settleDuesSubmitBtns"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button className="cancelBtn">{t("Cancel")}</Button>
                      {paymentLinkLoading === false && hotelType !== "ISHA" ? (
                        <Button
                          variant="custom-button"
                          className="submit w-auto"
                          onClick={() => handlePaymentLink()}
                        >
                          {t("Send Payment Link")}
                        </Button>
                      ) : (
                        <Button className="submit">
                          <CircularProgress size="15px" />
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default PaymentModal;
