import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

const FolioActionItem = ({ loading, action, text }) => {
  return loading ? (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography className="freq-op-name">{text}</Typography>
      <CircularProgress color="inherit" size={16} />
    </Stack>
  ) : (
    <Typography className="freq-op-name" onClick={action}>
      {text}
    </Typography>
  );
};

export default FolioActionItem;
